import { Injectable } from '@angular/core';
import { IPaperSize } from '../interfaces/IPaperSize';
import { IPlotSettingsService } from '../interfaces/IPlotSettingsService';
import data from '../../config/plot-settings.json';
import { IPlotSettingsConfigurationData } from '../interfaces/IPlotSettingsConfigurationData';

@Injectable({
  providedIn: 'root'
})

/** A service to handle distibution of settings related to plots.  e.g. paper sizes, print scales */
export class PlotSettingsService implements IPlotSettingsService {
  private landscapePaperSizes: IPaperSize[];
  private portraitPaperSizes: IPaperSize[];
  private paperSizes: string[] = [];
  private printScales: string[] = [];
  private printPreviewPaperSizes: string[] = [];

  constructor() {
    this.landscapePaperSizes = ((data as any) as IPlotSettingsConfigurationData).paperSizeConfigurations.find(c => c.orientation.toLowerCase() === 'landscape').sizes;
    this.portraitPaperSizes = ((data as any) as IPlotSettingsConfigurationData).paperSizeConfigurations.find(c => c.orientation.toLowerCase() === 'portrait').sizes;
    this.getDistinctSizes(this.landscapePaperSizes);
    this.getDistinctSizes(this.portraitPaperSizes);
    this.printScales = ((data as any) as IPlotSettingsConfigurationData).printScaleConfiguration;
    this.printPreviewPaperSizes = ((data as any) as IPlotSettingsConfigurationData).printPreviewPaperSizeConfiguration;
  }

  private getDistinctSizes(paperSizesAry: IPaperSize[]): void {
    paperSizesAry.forEach(s => {
      if (this.paperSizes.indexOf(s.id) === -1) {
        this.paperSizes.push(s.id);
      }
    });
  }

  private getPaperSizeBy(size: string, landscape: boolean): IPaperSize {
    try {
        let ps: IPaperSize;
        if (landscape) {
          ps = this.landscapePaperSizes.find(p => p.id.toLowerCase() === size.toLowerCase());
        } else {
          ps = this.portraitPaperSizes.find(p => p.id.toLowerCase() === size.toLowerCase());
        }
        if (ps) {
          return ps;
        }
    } catch {
      // doh
    }
    return null;
  }

  getScaleFromStringValue(scale?: string) {
    try {
      if (scale) {
        if (scale.indexOf(':') > -1) {
          scale = scale.substr(scale.indexOf(':') + 1);
          return parseInt(scale, 10);
        }
      }
    } catch {
      // doh
    }
    return 1;
  }

  /**
   * Get the IPaperSize (name, height width)
   * @param size paper size (e.g. A4)
   * @param landscape orientation
   * @param scale (e.g. 1:1250)
   */
  getPaperSize(size: string, landscape: boolean, scale: string): IPaperSize {
      const paperSize = this.getPaperSizeBy(size, landscape);
      if (paperSize) {
        const scaleValue = this.getScaleFromStringValue(scale);
        return { id: paperSize.id, width: ((scaleValue * paperSize.width) / 1000), height: ((paperSize.height * scaleValue) / 1000)};
      }
      return null;
  }

  /**
   * Get all available paper sizes
   */
  getPaperSizes(): string[] {
    return [...this.paperSizes];
  }

  /**
   * Get all available print scales
   */
  getPrintScales(): string[] {
    return [...this.printScales];
  }

  /**
   * Get all available print preview paper sizes
   */
  getPrintPreviewPaperSizes(): string[] {
    return [...this.printPreviewPaperSizes];
  }

}
