<app-modal #modal id="basket-invalid-modal">
    <div header>
        <div id="basket-invalid-title">
            Invalid Plots in Basket
        </div>
    </div>
    <div id="basket-invalid-modal-body" body>
        <p>
            Something has changed since you added your plots to the basket. This could be a change to the data or your statutory access.
        </p>
        
        <p>
            Please check your statutory access first on <a id="basket-invalid-account-link" href="#" (click)="navMyAccount($event);" aria-label="navigate to my account">digdat Utilities</a> 
            or select Delete Invalid Plots to remove all affected plots from your basket.
    </div>
    <div footer>
        <button id="basket-invalid-logout" class="btn btn-secondary" (click)="logout()">Log Out</button>
        <button id="basket-invalid-delete" class="btn btn-primary" (click)="deleteInvalidPlots()">Delete Invalid Plots</button>
    </div>
</app-modal>
