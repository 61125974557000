import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights, ICustomProperties, IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { ILoggerService } from '../interfaces/ILoggerService';
import { LoggingType } from '../models/logging-type';

@Injectable({
  providedIn: 'root'
})
export class LoggerService implements ILoggerService {
  private appInsights: ApplicationInsights;

  constructor(private router: Router) { 
    this.configInsightsErrorHandling();
  }

  private configInsightsErrorHandling() {
    // Configure App insights from connection string in config.
    // Known security risk as malicious types could log to our insights endpoint.  The swines.
    // Unhandled exceptions are logged via the provider in the app.module

    if (environment.insightsConnection && environment.insightsConnection !== '') {
      var angularPlugin = new AngularPlugin();

      this.appInsights = new ApplicationInsights({ config: {
            connectionString: environment.insightsConnection,
            extensions: [angularPlugin],
            extensionConfig: {
                [angularPlugin.identifier]: { router: this.router }
            },
            enableAutoRouteTracking: false
          } 
        }
      );
      this.appInsights.loadAppInsights();
    }
  }

  /**
   * Log a handled exception with logging provider (App Insights - query dashboard by Failures > Browser to see results)
   * @param exception
   * @param severityLevel 
   */
  log(exception: Error, severityLevel?: LoggingType.Critical | LoggingType.Error) {
      if (environment.dev === 'true') {
        console.log(exception);
      } else {
        if (this.appInsights) {
          this.appInsights.trackException( exception as IExceptionTelemetry, { severityLevel: severityLevel } as ICustomProperties );
        }
      }
  }
}
