
<div class="overlay" [hidden]="!show" [ngClass]="{'dev': dev}">
  <div class="spinner-wrapper">
    <div id="loader" [ngClass]="show? 'on' : 'off'" class="spinner-container">
      <div class="spinner">
        <div class="house-container">
            <svg version="1.1" id="Digdat_Icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px" viewBox="0 0 72.8 87.4" style="enable-background:new 0 0 72.8 87.4;" xml:space="preserve">
           <path id="Path_1188_4_" class="st0" d="M65.8,84.5L65.8,84.5l-11.7,0h-9.4c-4.2,0-9.7,0-15.1-0.1c-2.3-0.1-4.7-0.4-6.9-1.1
                c-2.2-0.6-4.3-1.6-6.3-2.7c-2-1.2-3.8-2.6-5.5-4.2c-1.7-1.7-3.1-3.5-4.3-5.6c-1.3-2.2-2.2-4.6-2.9-7.1c-0.6-2.5-0.9-5-0.8-7.5
                c0.1-2.5,0.5-5.1,1.2-7.5c0.7-2.5,1.8-4.8,3.2-7c1.1-1.8,2.4-3.4,3.9-4.8c1.5-1.4,3.2-2.7,4.9-3.8c2.1-1.2,4.4-2.2,6.7-2.8
                c2.3-0.6,4.6-0.9,7-0.9c4.8,0,9.4,1.2,13.6,3.6c2.1,1.2,4,2.7,5.7,4.4c1.8,1.8,3.3,3.8,4.5,5.9c0.4,0.6,0.7,1.2,1,1.8
                c3.6,7.8,7.2,16,10.1,22.6c1.2,2.7,4,9.1,4.8,10.9c0.9,2.1,0,4.6-2.1,5.5C66.9,84.4,66.4,84.5,65.8,84.5z M29.2,41.5
                c-8.3,0.2-14.9,7.1-14.8,15.4c-0.2,8.3,6.4,15.2,14.8,15.4c8.3-0.2,14.9-7.1,14.8-15.4C44.1,48.6,37.5,41.7,29.2,41.5z"/>
            <path class="st0" d="M58.6,42.5V2.8H46.8v26.1C51.9,32.2,56,36.9,58.6,42.5z"/>
            </svg>
          </div>
      </div>
  </div>

  <div class="loading-message-wrapper" *ngIf="message && message !== ''">
    <p id="loader-message" class="spinner-message">
      {{message}} <app-loading-dots></app-loading-dots>
    </p>
  </div>

  </div>
</div>