import { BasketItemLayerModel } from './basket-item-layer.model';

export class BasketItemModel {
    basketItemId = '';
    userId = '';
    modifiedDate = '';
    plotTitle = '';
    landscape = false;
    mono = false;
    paperSize = '';
    comments = '';
    x = 0;
    y = 0;
    plotAreaNumber = 0;
    scale = '';
    isCreated = false;
    orderEdit = false;
    basketItemLayers: BasketItemLayerModel[] = [];
}
