<nav id="header" [ngClass]="{'navigation' : navigating, 'menu-open' : showMobileMenu, 'resizing' : resizing}">
  <div id="brand-container">
      <a id="header-home" *ngIf='headerModel' class="brand" title="{{ headerModel.title }}" [routerLink]="['/']">Home</a>
      <span class="geocoder-divider hidden-xs">
        <span></span>
      </span>
  </div>

  <div id="geocoder-container" *ngIf="isMapPage">
    <app-geocoder></app-geocoder>
  </div>

  <div id="search-help-container" *ngIf="isMapPage">
    <app-search-help></app-search-help>
  </div>

  <!-- user not logged in menu -->
  <ul id="not-logged-in-menu" class="menu-system" *ngIf='!headerModel.isAuthenticated'>
    <li>
      <a id="header-menu-login" aria-label="navigate to login" [routerLink]="['/login']" class="menu-icon" path="account">
       <span class="account-icon">
          <i class="fa-solid fa-user"></i>
        </span>
      </a>
    </li>
  </ul>

  <!-- collapsible menu -->
  <ul id="logged-in-menu" class="menu-system" *ngIf='headerModel.isAuthenticated'>
    <li>
      <a appNavItem id="header-menu-home" [routerLink]="['/home']" path="home"><span>Home</span></a>
    </li>

    <li>
      <a appNavItem id="header-menu-mapping" [routerLink]="['/mapping']" path="mapping"><span>Map</span></a>
    </li>

    <li>
        <a appNavItem id="header-menu-orders" aria-label="toggle orders menu" href="#" aria-expanded="false" hasSubMenu="true" path="orders">
          <span>Orders <span class="caret"></span></span>
        </a>
       
        <ul>
            <li><a id="header-menu-order-history" [routerLink]="['/orders/order-history']"><span>Order History</span></a></li>
            <li><a id="header-menu-pending" [routerLink]="['/orders/pending']"><span>Pending Orders</span></a></li>
        </ul>
    </li>

    <li>
      <a appNavItem id="header-menu-help" aria-label="toggle help menu" href="#" aria-expanded="false" hasSubMenu="true" path="faqs|glossary">
          <span>Help <span class="caret"></span></span> 
      </a>

      <ul>
        <li><a id="header-menu-help-videos" [routerLink]="['/help-videos']"><span>Help Videos</span></a></li>
        <li><a id="header-menu-glossary" [routerLink]="['/glossary']"><span>Glossary</span></a></li>
        <li><a id="header-menu-faq" [routerLink]="['/faqs']"><span>FAQs</span></a></li>
        <li><a id="header-menu-contact" [routerLink]="['/contact-us']"><span>Contact Us</span></a></li>
        <li><a id="header-menu-privacy" (click)="navPrivacyPolicy($event);"><span>Privacy Policy <i class="fa-solid fa-up-right-from-square"></i></span></a></li>
        <li><a id="header-menu-terms" (click)="navTermsUse($event);"><span>Terms of Use <i class="fa-solid fa-up-right-from-square"></i></span></a></li>
      </ul>
    </li>

    <li id="basket-desktop-container">
      <ng-container [ngTemplateOutlet]="basketButton" [ngTemplateOutletContext]="{ id: 'header-basket-desktop' }"></ng-container>     
    </li>

    <li>
      <a appNavItem id="header-menu-account" aria-label="toggle account menu" href="#" hasSubMenu="true" class="menu-icon" path="account">
       <span class="account-icon">
          <span id="header-account-icon" *ngIf="userInitials$ | async as userInitials">{{userInitials}}</span>
        </span>

        <span id="header-account-text" class="account-text">Account <span class="caret"></span></span>
      </a>

      <ul>
        <li><a id="header-menu-account-details" [routerLink]="['/account/settings']" fragment="account-details"><span>Account Details</span></a></li>
        <li><a id="header-menu-account-preferences" [routerLink]="['/account/settings']" fragment="user-preferences"><span>User Preferences</span></a></li>
        <li><a id="header-menu-sign-out" href="#" role="button" (click)="logout($event)" aria-label="sign out"><span>Log Out</span></a></li>
      </ul>
    </li>
  </ul>

  <!-- mobile toggle collapsible menu -->
  <div *ngIf='headerModel.isAuthenticated' id="menu-toggle-container">
    <button id="menu-toggle" type="button" (click)="toggleMobileMenu($event)" aria-label="toggle navigation menu" class="btn btn-primary">
      <i class="fa-solid fa-bars"></i>
    </button>
  </div>
  
  <!-- mobile basket -->
  <ul *ngIf='headerModel.isAuthenticated' id="basket-mobile-container">
    <li>
      <ng-container [ngTemplateOutlet]="basketButton" [ngTemplateOutletContext]="{ id: 'header-basket-mobile' }"></ng-container>    
    </li> 
  </ul>

</nav>

<!-- templated basket -->
<ng-template #basketButton let-customId="id">
<a appNavItem id="{{customId}}" aria-label="toggle basket" class="menu-icon" href="#" hasSubMenu="true">
  <i aria-hidden="false" *ngIf='headerModel' class="fa-solid fa-basket-shopping">
    <span id="header-basket-counter" *ngIf='plots.length > 0' class="basket-counter counter" aria-label="number of plots in basket">{{plots.length}}</span>
  </i>
  <!--
  <span class="looky-tooltip-container">
    <span class="looky-tooltip">Basket</span>
  </span>
  -->
</a>

<ul class="basket-menu" aria-label="basket" tab-index="1" app-basket (edit)="editBasket($event)" (create)="addNewPlot($event)" (generate)="generatePlots($event)" [initialised]="basketLoaded" [plots]="plots">
</ul>
</ng-template>
<!-- templated basket end-->