import { Injectable } from '@angular/core';
import { IUrlHelperService } from '../interfaces/IUrlHelperService';

@Injectable({
  providedIn: 'root'
})
export class UrlHelperService implements IUrlHelperService {
  private currentPath = '';
  private queryStringRegex = new RegExp(/[^?#]*/);
  private homePath = 'home';
  constructor() { }

  private removePathFromBody() {
    if (this.currentPath !== '') {
      const body = document.getElementsByTagName('body')[0];
      body.classList.remove(this.currentPath);
    }
  }

  removeQueryString(segment: string): string {
    const matches = segment.match(this.queryStringRegex);
    if (matches.length > 0) {
      return matches[0];
    }
    return segment;
  }

  /**
   * Return url as string array of path values (ignore protocol portion of url)
   * @param url url to get segments from
   */
   getSegments(url: string): string[] {
    if (url) {
      url = url.toLowerCase();
      return url.replace('http:/', '').replace('https:/', '').split('/').filter(s => {
        return s != null && s !== '';
      });
    }
    return [];
  }

  /**
   * Get the "path" segement from a url and add it to the Html body element's css class list.
   * Remove the previous "path" from the class list.
   * @param url the url to derive the page segment from
   */
  addPathToBody(url: string): void {
    this.removePathFromBody();
    this.currentPath = this.getPathFromUrl(url);
    if (this.currentPath !== '') {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add(this.currentPath);
    }
  }

  getPathFromUrl(url: string): string {
    try {
      if (url) {
        const segments = this.getSegments(url);
        if (segments.length > 0) {
          const segment = segments[url.startsWith('http') ? 1 : 0];
          return this.removeQueryString(segment);
        }
      }
    } catch {
      // handled
    }
    return this.homePath;
  }

}
