import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { RouterModule } from '@angular/router';
import { BasketComponent } from './basket/basket.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GeocoderComponent } from './geocoder/geocoder.component';
import { SearchHelpComponent } from './search-help/search-help.component';
import { SharedModule } from '../shared/shared.module';
import { NotificationsComponent } from './notifications/notifications.component';

@NgModule({
  declarations: [HeaderComponent, BasketComponent, GeocoderComponent, SearchHelpComponent, NotificationsComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    BrowserAnimationsModule
  ],
  exports : [HeaderComponent]
})

export class HeaderModule { }
