
<div id="home-container" class="container-fluid">
    <div>
        <div class="col-lg-6">
            <h1>
                <div id="welcome-text">Welcome</div>
                <div id="welcome-user-text" *ngIf="userDetail$ | async let userDetail">{{userDetail.firstName}} {{userDetail.lastName}}</div>
            </h1>

            <div id="welcome-sub-heading" class="sub-heading">Welcome to the new Next Generation digdat Utilities!</div>

            <div class="home-body">
                <p>
                    We're excited to share the new Next Generation digdat Utilities platform with you. Here's some of the great new features available:
                </p>

                <ul>
                    <li>
                        Faster and easier plot creation process
                    </li>

                    <li>
                        Single search field used for all search criteria
                    </li>

                    <li>
                        Duplicate plot functionality
                    </li>

                    <li>
                        Set User Preferences to create standardised plots
                    </li>

                    <li>
                        Zoom in and out further
                    </li>

                    <li>
                        Plots are saved as you go
                    </li>

                    <li>
                        New help videos and Glossary
                    </li>

                    <li>
                        Improved FAQs
                    </li>
                </ul>

                <p>
                    Please note that Next Generation digdat Utilities is currently available for <b>statutory users only</b>, 
                    and therefore you will only be able to create and generate plots for datasets you have statutory access to. 
                    If you need to view an asset network on the map, purchase asset plans, or manage your statutory access/login details, please use the existing <a id="contact-nav-digdat-link" aria-label="Navigate to digat Utilities" href="{{digdatHome}}" target="_blank">digdat Utilities website</a>.
                </p>
            </div>

            <div class="stat-access-container" *ngIf="statutoryAccesses$ | async let statutoryAccesses">
                <ng-container *ngIf="statutoryAccesses.length > 0">
                    <div id="access-sub-heading" class="sub-heading">You have statutory access to:</div>
                    <div id="statutory-access-{{i}}" class="stat-access-item col-lg-4 col-md-6 col-sm-6 col-xs-6" *ngFor="let access of statutoryAccesses;let i = index;">
                        {{access.provider}}
                    </div>
                </ng-container>
            </div>
           

        </div>

        <div class="col-lg-6">
            <div>
                <div class="col-xs-6 col-md-4 col-lg-6 upcoming-container tile" aria-label="Upcoming Features">
                    <a id="home-coming-soon" href="#" aria-hidden="true" (click)="upcomingFeatures($event)">
                        <div class="carousel-title">Upcoming features</div>
                        <div class="carousel-container">
                            <app-carousel #carousel slideShow="true" buttonsLeft="true">
                                <ng-container *carouselItem>
                                    <div class="slide-content">
                                        <div class="slide">
                                            <div class="slide-img-container">
                                            </div>
            
                                            <div id="coming-soon-title" class="slide-header">Coming Soon</div>
                                        </div>
                                    </div>
                                </ng-container>

                                <!--
                                <ng-container *carouselItem>
                                    <div class="slide-content">
                                        <div class="slide">
                                            <div class="slide-img-container">
                                            </div>
            
                                            <div class="slide-header">First Slide</div>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container *carouselItem>
                                    <div class="slide-content">
                                        <div class="slide">
                                            <div class="slide-img-container">
                                            </div>
            
                                            <div class="slide-header">Second Slide</div>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container *carouselItem>
                                    <div class="slide-content">
                                        <div class="slide">
                                            <div class="slide-img-container">
                                            </div>
            
                                            <div class="slide-header">Third Slide</div>
                                        </div>
                                    </div>
                                </ng-container>
                            -->
                            </app-carousel>
                        </div>
                    </a>
                </div>

                <div class="col-xs-6 col-md-4 col-lg-6 help-container tile">
                    <a id="home-help" href="#" (click)="showHelpVideo($event)" aria-label="show help video">
                        <span class="play-icon"><i class="fa-solid fa-circle-play"></i></span>
                        <span class="tile-title">
                            Help Videos
                            <!--<span id="help-subtitle" class="tile-subtitle">Find out more</span>-->
                        </span>
                    </a>
                </div>

                <div class="col-xs-6 col-md-4 col-lg-6 glossary-container tile">
                    <a id="home-glassary-link" [routerLink]="['/glossary']"  aria-label="find out more Glossary">
                        <span class="tile-title">
                            Glossary
                            <span id="glossary-subtitle" class="tile-subtitle">Find out more</span>
                        </span>
                    </a>
                </div>

                <div class="col-xs-6 col-md-12 col-lg-6 faq-container tile">
                    <a id="home-faqs-link" [routerLink]="['/faqs']" aria-label="find out more FAQs">
                        <span class="tile-title">
                            FAQs
                            <span id="faqs-subtitle" class="tile-subtitle">Find out more</span>
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<app-modal #helpVideoModal [id]="helpVideoModalId">
    <div id="help-video-title" header>
        Help Videos
    </div>
    <div class="help-video-modal-body" body>
        <iframe id="help-video-container" title="Digdat Help Video" width="450" height="350" [attr.src]="helpVideoUrl | safe" frameborder="0" allow="autoplay" allowfullscreen></iframe>
    </div>
    <div footer>
    </div>
</app-modal>