
import { ElementRef, Directive, HostBinding, HostListener, EventEmitter, Input } from '@angular/core';

@Directive({
  selector: '[appNavItem]'
})
export class NavItemDirective {
  private openState = false;
  private _uniqueId: string;
  private openClass = 'open';
  private activeState = false;
  touchEnabled = false;
  id: string;
  elementRef: ElementRef;
  @Input() hasSubMenu = false;
  @Input() path = '';
  changed = new EventEmitter<void>();
  
  constructor(element: ElementRef) {
    this.id = element.nativeElement.id;
    this.elementRef = element;
    this._uniqueId = Math.random().toString(36).substring(2, 9);
  }

  @HostBinding('attr.aria-expanded') get expanded() { return this.openState; }
  @HostBinding('class.active') get active() { return this.activeState; }
  
  get uniqueId() {
    return this._uniqueId;
  }

  @HostListener('mouseleave', ['$event']) hoverOut(e: Event) {
    e.preventDefault();
    if (!this.touchEnabled) {
      this.close();
    }
  }

  @HostListener('click', ['$event']) clicked(e: Event) {
    this.toggle(e);
  }

  toggle(e?: Event) {
      if (this.hasSubMenu) {
        if (e) {
          e.preventDefault();
        }
        if(this.isOpen()) {
          this.close();
        } else {
          this.open();
        }
      } else {
        this.elementRef.nativeElement.blur();
      }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.toggle(e);
    }
  }

  private isOpen(): boolean {
    const elem = this.elementRef.nativeElement as HTMLElement;
    const parent = elem.parentNode as HTMLElement;
    return parent.classList.contains(this.openClass);
  }

  private open() {
    if (this.hasSubMenu) {
      const elem = this.elementRef.nativeElement as HTMLElement;
      const parent = elem.parentNode as HTMLElement;
      parent.classList.add(this.openClass);
      this.openState = true;
      this.changed.emit();
    }
  }

  close() {
    if (this.hasSubMenu) {
      const elem = this.elementRef.nativeElement as HTMLElement;
      const parent = elem.parentNode as HTMLElement;
      parent.classList.remove(this.openClass);
      this.openState = false;
      this.elementRef.nativeElement.blur();
      this.changed.emit();
    }
  }

  setActive(active: boolean) {
    this.activeState = active;
  }

}
