import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IOrderReportService } from '../interfaces/IOrderHistoryService';
import { DownloadFileModel } from '../models/order-detail-file-model';
import { OrderDetailModel } from '../models/order-detail.model';
import { OrderItemSummaryModel } from '../models/order-item-summary-model';
import { OrderStatusType } from '../models/order-status.type';
import { OrderSummaryFilterModel } from '../models/order-summary-filter.model';
import { OrderSummaryModel } from '../models/order-summary-model';
import { OrderSummaryReport } from '../models/order-summary-report.model';
import { StatusFilterModel } from '../models/status-filter-model';
import { ServiceErrorHandler } from '../shared/service-error-handler';

@Injectable({
  providedIn: 'root'
})
export class OrderReportService implements IOrderReportService {
  private orderHistoryApiUrl: string;
  private plotApiUrl: string;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };
  private statuses: StatusFilterModel[] = [
    { id: OrderStatusType.Processing, description: 'Processing', selected: false, displayOrder: 1 },
    { id: OrderStatusType.Completed, description: 'Completed', selected: false, displayOrder: 2 },
    { id: OrderStatusType.Expired, description: 'Expired', selected: false, displayOrder: 4 },
    { id: OrderStatusType.Failed, description: 'Failed', selected: false, displayOrder: 3 }
  ];
  private filter = new BehaviorSubject<OrderSummaryFilterModel>(null);
  private pendingFilter = new BehaviorSubject<OrderSummaryFilterModel>(null);
  private viewFileUrl = new Subject<string>();
  viewFileUrl$ = this.viewFileUrl.asObservable();
  previousFilter$ = this.filter.asObservable();
  previousPendingFilter$ = this.pendingFilter.asObservable();
  
  constructor(private http: HttpClient, private errorHandler: ServiceErrorHandler) {
    this.orderHistoryApiUrl = environment.orderHistoryApiUrl;
    this.plotApiUrl = environment.plotApiUrl;
  }

  getStatuses(): StatusFilterModel[] {
    return this.statuses.sort(this.sortStatus).map(s => {
       return Object.assign({}, s);
    });
  }

  private sortStatus(item1: StatusFilterModel, item2: StatusFilterModel) {
    if (item1.displayOrder > item2.displayOrder) {
      return 1;
    } else if (item1.displayOrder < item2.displayOrder) {
      return -1;
    }
    return 0;
  }

  private getPendingStatuses(): StatusFilterModel[] {
    return this.statuses.filter(s => s.id === 1 || s.id === 4).map(s => {
       return Object.assign({}, s);
    });
  }

  /**
   * Add 23 hours, 59 minutes, 59 seconds to the "end" date (before - why?) date value
   * @param filter to amend the "before" value on
   */
  private addTimeToFilterEndDate(filter: OrderSummaryFilterModel) {
    if (filter.before) {
      const timePortion = 'T23:59:59.999Z';
      if (filter.before.indexOf(timePortion) === -1) {
        filter.before = filter.before + 'T23:59:59.999Z';
      }
    }
  }

  private getOrders(filter: OrderSummaryFilterModel): Observable<OrderSummaryReport> {
    const apiAction = '/GetOrdersSummary/';
    this.addTimeToFilterEndDate(filter);
    filter = this.setFilterBySearchTextOrFilterValues(filter);

    return this.http.post<OrderSummaryReport>(this.orderHistoryApiUrl + apiAction, JSON.stringify(filter), this.httpOptions).pipe(
      map(data => {
        return this.mapOrderSummaryReport(data);
      }),
      catchError(this.errorHandler.handleError)
    );
  }

  private setFilterBySearchTextOrFilterValues(filter: OrderSummaryFilterModel): OrderSummaryFilterModel {
    if (filter) {
        if (filter.searchText) {
          filter = Object.assign(filter,
            { after: null, before: null, status: null } as OrderSummaryFilterModel);
        }
    }
    return filter;
  }

  /**
   * Get order summary model, if current page is not available, revert to 1st page
   * @param filter values to pass to back end query
   */
  private getOrdersWithPageAvailable(filter: OrderSummaryFilterModel): Observable<OrderSummaryReport> {
    return this.getOrders(filter).pipe(
      // Check page available for initial response, if not revert to page number 1
      switchMap(result => this.pageAvailable(result.totalAvailable, filter.resultsPerPage, filter.pageNumber) ?
      of(result) :
      this.getOrders(Object.assign(filter, { pageNumber: 1 } as OrderSummaryFilterModel)))
    );
  }

  /**
   * Is the current page still value to use (e.g. user returns to pending page. Orders processed. Previous page number invalid.)
   * @param totalItems total items available in query
   * @param itemsPerPage number of items per page
   * @param curPage current page
   */
  private pageAvailable(totalItems: number, itemsPerPage: number, curPage: number): boolean {
    const totalPages = Math.max(Math.ceil(totalItems / itemsPerPage), 1);
    return curPage <= totalPages;
  }

  getOrdersSummary(filter: OrderSummaryFilterModel): Observable<OrderSummaryReport> {
    this.filter.next(filter); // store previous filter
    return this.getOrdersWithPageAvailable(filter);
  }

  storeFilter(filter: OrderSummaryFilterModel): void {
    this.filter.next(filter); // store previous filter
  }

  getPendingOrders(resultsPerPage: number, pageNumber: number): Observable<OrderSummaryReport> {
    const filter = new OrderSummaryFilterModel();
    filter.pageNumber = pageNumber;
    filter.resultsPerPage = resultsPerPage;
    filter.status = this.getPendingStatuses().map(s => s.id);
    this.pendingFilter.next(filter);
    return this.getOrdersWithPageAvailable(filter);
  }

  private mapOrderSummaryReport(report: OrderSummaryReport) {
    if (report) {
      if (report.orderSummaries) {
        report.orderSummaries.forEach(s => {
          this.mapOrderSummaryModel(s);
        });
      } else {
        report.orderSummaries = [];
      }
    }
    return report;
  }

  private mapOrderSummaryModel(summary: OrderSummaryModel) {
    if (summary) {
      summary.statusName = this.statuses.find(x => x.id === summary.status).description;
    }
  }

  private sortItems(items: OrderItemSummaryModel[]): OrderItemSummaryModel[] {
    return items.sort((item1, item2) => {
      if (item1.plotAreaNumber > item2.plotAreaNumber) {
          return 1;
      }

      if (item1.plotAreaNumber < item2.plotAreaNumber) {
          return -1;
      }

      if (parseInt(item1.plotRef.replace('-', ''), 10) > parseInt(item2.plotRef.replace('-', ''), 10)) {
          return 1;
      }

      if (parseInt(item1.plotRef.replace('-', ''), 10) < parseInt(item2.plotRef.replace('-', ''), 10)) {
          return -1;
      }

      return 0;
   });
  }

  getOrderSummaryItems(id: string, validate?: boolean): Observable<OrderItemSummaryModel[]> {
    const apiAction = '/GetOrderItemsSummary';
    const params = new HttpParams().set('orderId', id).set('validate', validate? true: false);

    return this.http.get<OrderItemSummaryModel[]>(this.orderHistoryApiUrl + apiAction, { params }).pipe(
      tap(items => {
        if (items) {
          items.forEach(i => {
            i.plotRef = i.orderId + '-' + i.plotNumber;
            i.selected = false;
            i.plotPDFId = i.plotPDFId === '00000000-0000-0000-0000-000000000000' ? null : i.plotPDFId;
          });
          items.sort((item1, item2) => {
            if (item1.plotAreaNumber > item2.plotAreaNumber) {
                return 1;
            }

            if (item1.plotAreaNumber < item2.plotAreaNumber) {
                return -1;
            }

            return 0;
        });
      }
        return this.sortItems(items);
      })
      ,
      catchError(this.errorHandler.handleError)
    );
  }

  getOrderSummary(id: string): Observable<OrderSummaryModel> {
    const apiAction = '/GetOrderSummary';
    const params = new HttpParams().set('orderId', id);
    return this.http.get<OrderSummaryModel>(this.orderHistoryApiUrl + apiAction, { params }).pipe(
      map(data => {
        this.mapOrderSummaryModel(data);
        return data;
      }),
      catchError(err => {
        this.errorHandler.handleError(err);
        return of(null);
        }
      )
    );
  }

  getOrderDetail(id: string): Observable<OrderDetailModel> {
    return forkJoin({
      summary: this.getOrderSummary(id),
      items: this.getOrderSummaryItems(id)
    }).pipe(
      switchMap((result) => {
        let totalAvailable = 0;
        if (result.items) {
          totalAvailable = result.items.length;
        }
        return of({
          summary: result.summary,
          items: result.items,
          totalAvailable
          } as OrderDetailModel);
      }),
      catchError(() => of(null))
    );
  }

  viewFile(id: string): void {
    const apiAction = '/GetFile';
    const params = new HttpParams().set('fileId', id);
    this.http.get<DownloadFileModel>(this.plotApiUrl + apiAction, { params }).pipe(
      data => data,
      catchError(this.errorHandler.handleError)
    ).subscribe(data => {
      if (data) {
        if (data.url) {
          this.viewFileUrl.next(data.url);
          return;
        }
      }
      this.viewFileUrl.next(null);
    });
  }
}
