import { Directive, ElementRef, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appExpando]'
})
export class ExpandoDirective {
  private _uniqueId: string;
  elementRef: ElementRef;
  @HostBinding('attr.aria-expanded') get expanded() { return this.openState; }
  @HostBinding('class.open') private openState = false;
  changed = new EventEmitter<void>();
 
  constructor(element: ElementRef) {
    this.elementRef = element;
    this._uniqueId = Math.random().toString(36).substring(2, 9);
  }

  @HostListener('click', ['$event']) clicked(e: Event) {
    this.toggle(e);
  }

  get uniqueId() {
    return this._uniqueId;
  }

  toggle(e?: Event) {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.openState = !this.openState;
    this.changed.emit();
  }

  close() {
    this.openState = false;
    this.changed.emit();
  }
}
