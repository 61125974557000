<a id="search-help-button" href="#" class="btn btn-tertiary" (click)="showModal($event)" aria-label="show search help">
    ?
    <span class="looky-tooltip-container">
    <span class="looky-tooltip">What can I search for?</span>
    </span>
</a>

<app-modal [id]="searchHelpModalId">
    <div header>
        What can I search for?
    </div>
    <div body id="search-help-modal-body">
        <div class="row">
            <div class="col-xs-6">
                <p class="p2">NGR:</p>
            </div>

            <div class="col-xs-6">
                <p class="p1">NN166712</p>
                <p class="p1">TL2327173774</p>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-6">
                <p class="p2">Easting, Northing:</p>
            </div>

            <div class="col-xs-6">
                <p class="p1">523268,273776</p>
                <p class="p1">523268, 273776</p>
                <p class="p1">441200.12, 449300.00</p>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-6">
                <p class="p2">Latitude, Longitude:</p>
            </div>

            <div class="col-xs-6">
                <p class="p1">52.3481181,-0.1923531</p>
                <p class="p1">52.3481181, -0.1923531</p>
            </div>
        </div>
<!--
        <div class="row">
            <div class="col-xs-6">
                <p class="p2">UPRN:</p>
            </div>

            <div class="col-xs-6">
                <p class="p1">23089137</p>
                <p class="p1">100090056493</p>
            </div>
        </div>
-->
        <div class="row">
            <div class="col-xs-6">
                <p class="p2">Full UK postcode:</p>
            </div>

            <div class="col-xs-6">
                <p class="p1">PE29 6SZ</p>
                <p class="p1">PE296SZ</p>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-6">
                <p class="p2">Thoroughfare and town:</p>
            </div>

            <div class="col-xs-6">
                <p class="p1">Percy Road, Huntingdon</p>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-6">
                <p class="p2">Town only:</p>
            </div>

            <div class="col-xs-6">
                <p class="p1">Huntingdon</p>
            </div>
        </div>
    </div>
    <div footer>
    </div>
</app-modal>