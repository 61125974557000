import { MapLayerValidationType } from './map-layer-validation.type';

export class OrderItemSummaryModel {
    orderItemId: string;
    selected: boolean;
    orderId: string;
    plotTitle: string;
    plotAreaNumber: number;
    plotNumber: number;
    plotRef: string;
    assetLayer: string;
    assetOwner: string;
    theme: string;
    assets: boolean;
    pageSize: string;
    orientation: string;
    scale: string;
    plotPDFId: string;
    plotGeneratedOn: string;
    validationResult: MapLayerValidationType;
}
