import { AfterViewInit, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigateService } from 'src/app/services/navigate.service';
import { ExpandoDirective } from 'src/app/shared/expando.directive';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.less']
})
export class FaqsComponent implements OnInit, AfterViewInit {
  private faqItemIdPattern = 'faq-item-';
  private questionIdPattern = 'faq-question-';
  private answerIdPattern = 'faq-answer-';
  private subscriptions: Subscription[] = [];
  @ViewChildren(ExpandoDirective) faqItems: QueryList<ExpandoDirective>;

  constructor(private navService: NavigateService) { }

  private closeAllItems(except?: ExpandoDirective) {
    this.faqItems.forEach(m => {
      if (!except) {
        m.close();
      } else if (except.uniqueId !== m.uniqueId) {
        if (m.expanded) {
          m.close();
        }
      }
    });
  }

  ngAfterViewInit(): void {
    let idx = 0;
    this.faqItems.forEach(item => {
      this.setFaqIds(item, idx);

      this.subscriptions.push(item.changed.subscribe(() => {
        if (item.expanded) {
          this.closeAllItems(item);
        }
      }));
      idx++;
    });
  }

  private setFaqIds(item: ExpandoDirective, idx: number) {
    try {
      let elem = item.elementRef.nativeElement as HTMLElement;
      let questionElem = elem.firstElementChild as HTMLElement;
      let answerElem = elem.lastElementChild as HTMLElement;
  
      if (questionElem && answerElem && elem) {
        questionElem.setAttribute('id', this.questionIdPattern + idx.toString());
        answerElem.setAttribute('id', this.answerIdPattern + idx.toString());  
        elem.setAttribute('id', this.faqItemIdPattern + idx.toString());
      }
    } catch {
      // handled
    }
  }

  ngOnInit(): void {
  }

  navDigdatHome(e: Event) {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.navService.navigateToHome();
  }

}
