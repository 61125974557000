<app-modal #orderSubmittedModal [id]="modalId">
    <div header> 
        Order Processing
    </div>
    <div body>
        <div id="order-submitted-message">
            Order <span id="order-submitted-id" class="selectable">{{orderId}}</span> is being Processed.
        </div>
    </div>
    <div footer>
        <div [ngClass]="displayNavToDetail ? '' : 'modal-footer-center'">
            <button id="order-submitted-modal-close" [ngClass]="displayNavToDetail ? 'btn-secondary' : 'btn-primary'" class="btn" (click)="close()" type="button">Close</button>
            <button id="order-submitted-modal-nav-order-detail" *ngIf="displayNavToDetail" class="btn btn-primary" (click)="navOrderDetail()" type="button">Go To Order Details</button>
        </div>
    </div>
</app-modal>

<app-modal #orderSubmittedErrorModal [id]="errorModalId">
    <div header> 
        Problem with creating order 
    </div>
    <div body>
        There was an issue with placing your order.  Please try again or <a (click)="navSupport()">contact digdat support</a>.
    </div>
    <div footer>
        <div class="modal-footer-center">
            <button id="order-submitted-error-modal-close" class="btn btn-primary" (click)="closeError()" type="button">Close</button>
        </div>    
    </div>
</app-modal>