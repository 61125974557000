import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IUserService } from '../interfaces/IUserServices';
import { UserDetailModel } from '../models/user-detail.model';
import { ServiceErrorHandler } from '../shared/service-error-handler';

@Injectable({
  providedIn: 'root'
})

export class UserService implements IUserService {
  private apiUrl: string;
  private userDetail = new BehaviorSubject<UserDetailModel | null>(null);
  userDetail$ = this.userDetail.asObservable();

  constructor(private http: HttpClient, private errorHandler: ServiceErrorHandler) {
    this.apiUrl = environment.userApiUrl;
  }

  getUserDetail(): void {
    const apiAction = '/GetDetails/';
    this.http.get<UserDetailModel>(this.apiUrl + apiAction).pipe(
      tap(result => {
        this.userDetail.next(result);
      }),
      catchError(err => {
        this.errorHandler.handleError(err);
        return of(null);
      })
    ).subscribe();
  }

}
