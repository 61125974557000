export class CustomModalOptions {
    size: ModalSizeOptions;
    hideClose: boolean;
    customCssClass: string;
    subModal: boolean;
}

export enum ModalSizeOptions {
    Normal, FullScreen, Large
}
