import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAssetLayerModel } from '../interfaces/IAssetLayerModel';
import { ILayerModel } from '../interfaces/ILayerModel';
import { ILayersAreas } from '../interfaces/ILayersAreas';
import { IPermissionsToken } from '../interfaces/IPermissionsToken';
import { IPlotAssetLayerService } from '../interfaces/IPlotAssetLayerService';
import { IPlotModel } from '../interfaces/IPlotModel';
import { ServiceErrorHandler } from '../shared/service-error-handler';
import { AssetLayerService } from './asset-layer.service';
import { AssetPermissionService } from './asset-permission.service';
import { MapMetaService } from './map-meta.service';
import { PlotHelperService } from './plot-helper.service';

@Injectable({
  providedIn: 'root'
})

/**
 * Gets asset layers by permission for a plot
 */
export class PlotAssetLayerService extends AssetLayerService implements IPlotAssetLayerService {

  constructor(http: HttpClient, errorHandler: ServiceErrorHandler,
              mapMetaService: MapMetaService,
              permissionService: AssetPermissionService,
              private plotHelperService: PlotHelperService) {
      super(http, errorHandler, mapMetaService, permissionService);
  }

  private setSelectedCount(assetLayer: IAssetLayerModel) {
    assetLayer.selectedCount = 0;
    if (assetLayer.layers) {
      assetLayer.selectedCount = assetLayer.layers.filter(l => l.selected === true).length;
    }
  }

  /**
   * Keep continuity of user selected layer properties from previous asset layers map load (e.g. expanded, selected when use drags plot area)
   * @param newAssetLayers asset layers in current plot bounds
   * @param previousAssetLayers asset layers in the previous plot bounds or a duplicate plot's asset layer
   * @param orderEdit determines if a plot was a placed order and if so different layer selection logic applies
   */
  private maintainProperties(newAssetLayers: IAssetLayerModel[], previousAssetLayers: IAssetLayerModel[], orderEdit: boolean) {
    newAssetLayers.forEach(newAssetLayer => {
      if (orderEdit) {
        newAssetLayer.layers.forEach(ds => ds.selected = false); // if edited order - don't pre-select any asset layers
      }

      const prevAssetLayer = previousAssetLayers.find(a => a.classification === newAssetLayer.classification);
      if (prevAssetLayer) {          
          this.applySelectionLogic(newAssetLayer, prevAssetLayer);
          newAssetLayer.expanded = prevAssetLayer.expanded;
      }

      this.setSelectedCount(newAssetLayer);
    });
  }

  private applySelectionLogic(newAssetLayer: IAssetLayerModel, prevAssetLayer: IAssetLayerModel) {
    if (newAssetLayer && newAssetLayer.layers && prevAssetLayer && prevAssetLayer.layers) {
      newAssetLayer.layers.forEach(newLayer => {
        const prevMapLayer = prevAssetLayer.layers.find(o => o.mapLayer === newLayer.mapLayer);
  
        if (prevMapLayer) {
          // no polygon restrictions and user has permission to view
          if (!prevMapLayer.restricted && !newLayer.restricted && newLayer.viewable) {
            newLayer.selected = prevMapLayer.selected; // maintain selection
          }
        }
      });    
    }
  }

  toLayerModel(d: ILayerModel, permissionsToken: IPermissionsToken, layersAreas: ILayersAreas) {
      super.toLayerModel(d, permissionsToken, layersAreas);
      d.selected = d.viewable && !d.restricted;
  }

  toAssetLayerModels(layersAreas: ILayersAreas, permissionsToken: IPermissionsToken): IAssetLayerModel[] {
    try {
        const assetLayers = super.toAssetLayerModels(layersAreas, permissionsToken);
        assetLayers.forEach(ass => {
          this.setSelectedCount(ass);
        });
        return assetLayers;
    } catch {
      // handled
    }
    return [];
  }

  /**
   * Get asset layers for a plot
   * @param plot the instance of plot to get the asset layers for
   */
  private getAssetLayersForPlot(plot: IPlotModel): Observable<IAssetLayerModel[]> {
    // Calculate boundary based on paper size, scale and orientation
    const bounds = this.plotHelperService.getBoundsForPlot(plot);
    if (bounds != null) {
      const xL = bounds.getSouthWest().lng;
      const yL = bounds.getSouthWest().lat;
      const xU = bounds.getNorthEast().lng;
      const yU = bounds.getNorthEast().lat;

      return this.getAssetLayers(xL, yL, xU, yU).pipe(
        map(assetLayers => {
          this.maintainProperties(assetLayers, plot.assetLayers, plot.orderEdit);
          return assetLayers;
        })
      );
    }
    return of([]);
  }

  /**
   * Get asset layers for a new plot.  Pre-select asset layer layers that user has permission to.
   * @param plot the instance of plot to get the asset layers for
   * @param sourceAssetLayers if defined use sourceAssetLayers to determine selected value of corresponding layers in new plot
   */
   getAssetLayersForNewPlot(plot: IPlotModel, sourceAssetLayers?: IAssetLayerModel[]): Observable<IAssetLayerModel[]> {
    // Calculate boundary based on paper size, scale and orientation
    const bounds = this.plotHelperService.getBoundsForPlot(plot);
    if (bounds != null) {
      const xL = bounds.getSouthWest().lng;
      const yL = bounds.getSouthWest().lat;
      const xU = bounds.getNorthEast().lng;
      const yU = bounds.getNorthEast().lat;
  
      // call base class method
      return this.getAssetLayers(xL, yL, xU, yU).pipe(
        map(assetLayers => {
          if (sourceAssetLayers) {
            this.maintainProperties(assetLayers, sourceAssetLayers, plot.orderEdit);
          }
          assetLayers.forEach(ass => {
            ass.expanded = false;
          });
          return assetLayers;
        })
      );
    }
    return of([]);
  }

  refreshAssetLayers(plot: IPlotModel, changed: boolean): Observable<IAssetLayerModel[]> {
    if (changed || plot.orderEdit) {
      return this.getAssetLayersForPlot(plot).pipe();
    } else {
      plot.assetLayers.forEach(ass => {
        this.setSelectedCount(ass);
      });
      return of(plot.assetLayers);
    }
  }
}


