import { Injectable } from '@angular/core';
import { IPlotModel } from '../interfaces/IPlotModel';
import * as L from 'leaflet';
import { IPlotHelperService } from '../interfaces/IPlotHelperService';
import { PlotSettingsService } from './plot-settings.service';
import { OrderItemSummaryModel } from '../models/order-item-summary-model';
import { MapMetaService } from './map-meta.service';
import { BasketItemModel } from '../models/basket-item.model';
import { PlotModel } from '../models/plot.model';
import { PrintPreviewRequestModel } from '../models/print-preview-request.model';
import { AuthenticationService } from './authentication.service';
import { IAssetLayerModel } from '../interfaces/IAssetLayerModel';
import { ILayerModel } from '../interfaces/ILayerModel';
import { BasketItemLayerModel } from '../models/basket-item-layer.model';

@Injectable({
    providedIn: 'root'
})

/** Helper functions for plot handling */
export class PlotHelperService implements IPlotHelperService {

    constructor(private plotSettingsService: PlotSettingsService, private mapMetaService: MapMetaService, private authService: AuthenticationService) {
    }

    /**
     * Calculate bounds for a plot instance based on paper size, scale, orientation and position
     * @param plot instance to calculate bounds for
     */
    getBoundsForPlot(plot: IPlotModel): L.LatLngBounds {
        try {
            const paperSize = this.plotSettingsService.getPaperSize(plot.paperSize, plot.landscape, plot.printScale);
            if (paperSize) {
                return this.getBoundsByWidthHeight(this.getLatLngFromPlot(plot), paperSize.width, paperSize.height);
            }
        } catch {
            return null;
        }
    }

    /**
     * Calculate bounds based on paper size, scale, orientation and position
     * @param center lat lng coordinates
     * @param width in metres
     * @param height in metres
     */
    getBoundsByWidthHeight(center: L.LatLng, width: number, height: number): L.LatLngBounds {
        const w = center.toBounds(width);
        const h = center.toBounds(height);
        return new L.LatLngBounds(new L.LatLng(h.getSouth(), w.getWest()), new L.LatLng(h.getNorth(), w.getEast()));
    }

    /**
     * Get the css class to apply to a plot control/plot control element.
     * Put into this helper class so it can be called before the "super" parent class call otherwise I'm getting told off
     * by the IDE.
     * @param visible is plot visible
     * @param isCurrent is plot current
     * @param controlCssClass control css class
     * @param hiddenCssClass control hidden class
     * @param isCurrentCssClass ...wait for it...is current css class
     * @param plotAreaNumber the plot area number of the plot
     * @param invalidCssClass ...
     * @param valid property value
     */
    getCssClass(visible: boolean, isCurrent: boolean, controlCssClass: string, hiddenCssClass: string, isCurrentCssClass: string,
                plotAreaNumber: number, invalidCssClass: string, valid: boolean): string {
        // Add way of identifying plot border for Mary
        controlCssClass = ' plot-border-' + plotAreaNumber + ' ' + controlCssClass;
        
        if (visible) {
            if (isCurrent) {
                return controlCssClass + ' ' + isCurrentCssClass;
            }

            if (!valid) {
                controlCssClass += ' ' + invalidCssClass;
            }

            return controlCssClass;
        }
        return controlCssClass + hiddenCssClass;
    }

    /**
     * Get a LatLng instance from a plot's x/y values.
     * @param plot instance to get lat lng for
     */
    getLatLngFromPlot(plot: IPlotModel): L.LatLng {
        return new L.LatLng(plot.y, plot.x);
    }

    toOrderItemSummaries(plots: IPlotModel[]): OrderItemSummaryModel[] {
        try {
            if (plots) {
                const x = plots.map(p => {
                    return p.assetLayers.map(a => {
                        return a.layers.filter(l => l.selected).map(l => {
                            return  Object.assign(new OrderItemSummaryModel(), {
                                orderId: p.id,
                                assetLayer: a.classification,
                                assetOwner: this.mapMetaService.getAssetLayerProvider(l.providerId).name,
                                assets: true,
                                orientation: p.landscape ? 'Landscape' : 'Portrait',
                                pageSize: p.paperSize,
                                plotAreaNumber: p.plotAreaNumber,
                                plotTitle: p.plotTitle,
                                scale: p.printScale,
                                theme: p.mono ? 'Mono' : 'Colour',
                                plotPDFId: '',
                                plotGeneratedOn: ''
                            } as OrderItemSummaryModel);
                        });
                    });
                });
                return x.reduce((orderItems, y) => orderItems.concat(y)).reduce((orderItems, z) => orderItems.concat(z));
            }
        } catch {
            return [];
        }
    }

    /**
     * Map a basket item model to a new plot model instance
     * @param basketItem item to map to plot model instance
     */
    private toPlotModel(basketItem: BasketItemModel): PlotModel {
        return Object.assign(new PlotModel(), {
        id: basketItem.basketItemId,
        assetLayers: [],
        comments: basketItem.comments,
        isCreated: basketItem.isCreated,
        landscape: basketItem.landscape,
        mono: basketItem.mono,
        paperSize: basketItem.paperSize,
        plotAreaNumber: basketItem.plotAreaNumber,
        plotTitle: basketItem.plotTitle,
        printScale: basketItem.scale,
        x: basketItem.x,
        y: basketItem.y,
        orderEdit: basketItem.orderEdit
        } as Partial<IPlotModel>);
    }

    private toLayer(layer: ILayerModel, basketItemLayer: BasketItemLayerModel): ILayerModel {
        layer.viewable = true;
        layer.restricted = false;
        layer.selected = basketItemLayer.selected;
        layer.basketValidation = basketItemLayer.validationResult;
        return layer;
    }

    private toAssetLayers(basketItem: BasketItemModel): IAssetLayerModel[] {
        // get all asset layers (with properties and values as defined for rebuild- not legacy)
        const assetLayers = this.mapMetaService.getAssetLayers();

        return assetLayers.map(a => ({...a}))
            // from all asset layers filter those that have a corresponding layer/basketItemLayer based on 'mapLayer' property in the basketItem
            .filter(a => a.layers.some(l => basketItem.basketItemLayers.some(bl => bl.mapLayer === l.mapLayer))).map(a => {
                a.layers = a.layers.map(l => ({...l})).filter(l => basketItem.basketItemLayers.map(bil => bil.mapLayer).indexOf(l.mapLayer) !== -1);
                a.layers.forEach(layer => {
                    const basketItemLayer = basketItem.basketItemLayers.find(bl => bl.mapLayer === layer.mapLayer);
                    layer = this.toLayer(layer, basketItemLayer);
                });
            a.selectedCount = a.layers.filter(l => l.selected === true).length;
            return a;
        });
    }

    /**
     * Return an array of plot models based on an array of basket items
     * @param basketItems items that have been retrieved from the basket API (which don't map 1:1 with plots.
     */
    toPlotModels(basketItems: BasketItemModel[]): IPlotModel[] {
        return basketItems.map(bi => {
            // return a new instance of plot model based on basket item properties
            return Object.assign({}, this.toPlotModel(bi), {
                // get any asset layers (from a list of all available)
                assetLayers: this.toAssetLayers(bi),
                printPreviewPaperSizes: [...this.plotSettingsService.getPrintPreviewPaperSizes()],
                paperSizes: [...this.plotSettingsService.getPaperSizes()],
                printScales: [...this.plotSettingsService.getPrintScales()]
            });
        });
    }

    toPrintPreviewRequest(plot: IPlotModel): PrintPreviewRequestModel {
        return Object.assign(new PrintPreviewRequestModel(), {
            comments: plot.comments,
            id: plot.id,
            landscape: plot.landscape,
            mapLayers: plot.assetLayers.map(p => p.layers.filter(s => s.selected).map(l => l.mapLayer)).reduce((accumulator, value) => accumulator.concat(value), []),
            mono: plot.mono,
            paperSize: plot.paperSize,
            scale: parseInt(plot.printScale.replace('1:', ''), 10),
            title: plot.plotTitle,
            email: this.authService.currentUserValue.userName,
            x: plot.x,
            y: plot.y
        } as PrintPreviewRequestModel);
    }

    
    /**
     * Return a lat lng that is shifted east (currently)
     * Overlap "slightly"
     */
    getShiftedCenter(bounds: L.LatLngBounds): L.LatLng {
        const overlap = 0.00025;
        const lng = (bounds.getEast() - bounds.getCenter().lng) + (bounds.getEast() - overlap);
        const lat = bounds.getCenter().lat;
        return new L.LatLng(lat, lng);
    }

    /**
     * Return if source plot's bounds contains the center point of the target plot's bounds
     * @param sourcePlot source plot instance for which to get the containing bounds
     * @param targetPlot target plot instance for which to get the center point
     * @returns 
     */
    containsPlotCenter(sourcePlot: IPlotModel, targetPlot: IPlotModel): boolean {
        if (sourcePlot && targetPlot) {
            const sourceBounds = this.getBoundsForPlot(sourcePlot);
            const targetBounds = this.getBoundsForPlot(targetPlot); 

            if (sourceBounds && targetBounds) {
                return sourceBounds.contains(targetBounds.getCenter());
            }
        }
        return false;
    }

    /**
     * Offset a plot's y coordinate only to overlap the source plot's bounds
     * @param sourcePlot source plot instance from which the bounds will offset
     * @param targetPlot target plot instance which will have the offset position
     */
    shiftLongitudeOnly(sourcePlot: IPlotModel, targetPlot: IPlotModel) {
        if (sourcePlot && targetPlot) {
            const sourceBounds = this.getBoundsForPlot(sourcePlot);
            const targetBounds = this.getBoundsForPlot(targetPlot); 

            if (sourceBounds && targetBounds) {
                const bounds = this.getShiftedCenter(sourceBounds);
                bounds.lat = targetBounds.getCenter().lat;
                targetPlot.x = bounds.lng;
                targetPlot.y = bounds.lat;
            }
        }
    }
}
