import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { IMapMetaService } from '../interfaces/IMapMetaService';
import { IBackgroundLayerModel } from '../interfaces/IBackgroundLayerModel';
import { IAssetLayerModel } from '../interfaces/IAssetLayerModel';
import assetLayersData from '../../config/asset-layers.json';
import backgroundLayersData from '../../config/background-layers.json';
import { AssetLayerModel } from '../models/asset-layer.model';
import { LayerModel } from '../models/layer.model';
import { IProviderModel } from '../interfaces/IProviderModel';
import { ProviderService } from './provider.service';

@Injectable({
  providedIn: 'root'
})
export class MapMetaService implements IMapMetaService {
  private assetLayers: IAssetLayerModel[] = [];
  private backgroundLayers: IBackgroundLayerModel[] = [];
  private providers: IProviderModel[] = [];

  constructor(private providerService: ProviderService) {
    this.assetLayers = (assetLayersData as any).assetLayers as IAssetLayerModel[];
    this.backgroundLayers = ((backgroundLayersData as any).backgroundLayers as IBackgroundLayerModel[]);
    this.providers = this.providerService.getProviders();
  }

  private mapAssetLayersData(data: IAssetLayerModel[]) {
    return data.map(ass => {
      return Object.assign( new AssetLayerModel(), {
        classification: ass.classification,
        layers: ass.layers.map(layer => Object.assign(new LayerModel(), layer, { provider: this.providerService.getProviders().find(p => p.id == layer.providerId)?.name}))
      }) as AssetLayerModel;
    });
  }

  /**
   * Return array of all background layers
   */
  getBackgroundLayers(): Observable<IBackgroundLayerModel[]> {
    return of(this.backgroundLayers);
  }

  /**
   * Return an array of all available asset layers
   */
  getAssetLayers(): IAssetLayerModel[] {
    // map from data to create a cloned deep copy to return
    return this.mapAssetLayersData(this.assetLayers);
  }

  /**
   * Return an array of all asset layer providers
   */
  private getAssetLayerProviders(): IProviderModel[] {
     // map to new objects before returning
     return this.providers.map(p => {
      return Object.assign({}, p);
    });
  }

  /**
   * Get the provider of an asset layer by id
   * @param id the id of the asset layer provider
   */
  getAssetLayerProvider(id: number): IProviderModel {
    const clonedProviders = this.getAssetLayerProviders();
    if (clonedProviders) {
      return clonedProviders.find(p => p.id === id);
    }
    return null;
  }

  getClassification(mapLayerId: string): string {
    const classifications = this.assetLayers.filter(a => {
      return a.layers.some(l => l.mapLayer === mapLayerId);
    });
    if (classifications && classifications.length > 0) {
      return classifications[0].classification;
    }
    return '';
  }

}
