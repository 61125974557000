<app-modal [id]="idleModalId">
    <div header>
        Session Time Out
    </div>
    <div body>
        <p>Your session will expire in:</p>
        <h1>
            {{getTimeRemaining()}}
        </h1>
        <p>
            Click the 'Stay Logged In' button if you wish to continue and remain logged in
        </p>
    </div>
    <div footer>
        <button id="idle-button-logout" class="btn btn-secondary" (click)="logout()">Log Out</button>
        <button id="idle-button-stay" class="btn btn-primary" (click)="stay()">Stay Logged In</button>
    </div>
</app-modal>
