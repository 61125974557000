import { Component, OnInit, Input, OnDestroy, ElementRef, ViewEncapsulation, ViewChild, Output, EventEmitter, HostListener, Renderer2, RendererFactory2 } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { ModalSizeOptions, CustomModalOptions } from 'src/app/models/custom-modal-options';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.less'],
  encapsulation: ViewEncapsulation.None
})

export class ModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() plotAreaNumber: number;
  private openClass = 'modal-open';
  private backdropOpenClass = 'in';
  private dialogCssClass = 'custom-modal-dialog';
  private subModalClass = 'sub-modal';
  private dialogLgCssClass = 'custom-modal-lg';
  private dialogFullScreenCssClass = 'custom-modal-full';
  private footerCssClass = 'custom-modal-footer';
  private footerCssClassHidden = 'custom-modal-footer-hidden';
  private tabKey = false;
  private shiftKey = false;
  currentModalClass = '';
  currentBackdropClass = '';
  currentDialogClass = '';
  currentFooterClass = '';
  currentAdditionalClass = '';
  currentCustomCssClass = '';
  showClose = true;
  modalOpen = false;
  @ViewChild('footerContainer') footerContainer: ElementRef;
  @ViewChild('headerContainer') headerContainer: ElementRef;
  @ViewChild('contentContainer') contentContainer: ElementRef;
  @Output() closed$: EventEmitter<void> = new EventEmitter();

  constructor(private modalService: ModalService, private element: ElementRef) {
  }

  private setHeaderId(): void {
    if (this.headerContainer) {
      if (this.headerContainer.nativeElement) {
        if (this.headerContainer.nativeElement.childElementCount > 0) {
          const header = this.headerContainer.nativeElement.children[0] as HTMLElement;
          header.id = this.id + '-h';
        }
      }
    }
  }

  ngOnInit(): void {
      // add self (this modal instance) to the modal service so it's accessible from controllers
      this.modalService.add(this);
  }

  // remove self from modal service when directive is destroyed
  ngOnDestroy(): void {
      this.close();
      this.modalService.remove(this.id);
  }

  /**
   * Set the footerDisplayed true/false by checking for the footer element and then checking that content (e.g. buttons)
   * have been defined.  If not then set false.
   */
  private setFooterDisplayed() {
    setTimeout(() => {
      if (this.footerContainer) {
        if (this.footerContainer.nativeElement) {
          if (this.footerContainer.nativeElement.childElementCount > 0) {
            if (this.footerContainer.nativeElement.children[0].childElementCount > 0) {
              this.currentFooterClass = this.footerCssClass;
              return;
            }
          }
        }
      }
      this.currentFooterClass = this.footerCssClassHidden;
    }, 1);
  }

  private setModalSize(size: ModalSizeOptions) {
    if (size === ModalSizeOptions.Large) {
      this.currentDialogClass = this.dialogCssClass + ' ' + this.dialogLgCssClass;
    } else if (size === ModalSizeOptions.FullScreen) {
      this.currentDialogClass = this.dialogCssClass + ' ' + this.dialogFullScreenCssClass;
    }
  }

  private setDialogClass(options?: CustomModalOptions) {
    this.currentDialogClass = this.dialogCssClass;
    if (options) {
      if (options.size) {
        this.setModalSize(options.size);
      }
      if (options.hideClose) {
        this.showClose = false;
      }
    }
  }

  private setBackdropClass(options?: CustomModalOptions) {
    this.currentBackdropClass = this.backdropOpenClass;
    if (options) {
      if (options.subModal) {
        this.currentBackdropClass += ' ' + this.subModalClass;
      }
    }
  }

  private setModalClass(options?: CustomModalOptions) {
    this.currentModalClass = this.openClass;
    if (options) {
      if (options.subModal) {
        this.currentModalClass += ' ' + this.subModalClass;
      }
    }
  }

  open(options?: CustomModalOptions): void {
      this.setFooterDisplayed();
      this.setHeaderId();
      this.currentCustomCssClass = options && options.customCssClass ? options.customCssClass : '';
      this.element.nativeElement.style.display = 'block';
      this.setModalClass(options);
      this.setBackdropClass(options);
      this.setDialogClass(options);
      document.body.classList.add(this.openClass);
      this.modalOpen = true;
      setTimeout(() => {
        this.contentContainer.nativeElement.focus();
      }, 1);
      
  }

  close(): void {
      if (this.modalOpen) {
        const modal = document.body.querySelector('.custom-modal.modal-open');
        if (modal) {
          modal.scrollTop = 0;
        }
        this.element.nativeElement.style.display = 'none';
        this.currentModalClass = '';
        this.currentBackdropClass = '';
        this.setDialogClass();
        document.body.classList.remove(this.openClass);
        this.modalOpen = false;
        this.currentCustomCssClass = '';
        this.closed$.emit();
    }
  }

  modalFocusOut(e: Event) {
    if (this.modalOpen) {
      if (this.tabKey && this.shiftKey) {
        e.preventDefault();
        e.stopImmediatePropagation();
        this.contentContainer.nativeElement.focus();
      }
    }
  }

  backdropFocusIn(e: Event) {
    if (this.modalOpen) {
      if (this.tabKey) {
        e.preventDefault();
        e.stopImmediatePropagation();
        this.contentContainer.nativeElement.focus();
      }
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
      if (this.modalOpen) {
        this.tabKey = event.key === 'Tab';
        this.shiftKey = event.shiftKey;
        return;
      }
      this.tabKey = false;
      this.shiftKey = false;
    }
}
