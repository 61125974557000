<div id="login-container" class="login-page" *ngIf="!navigating">
    <div class="login-panel col-md-6">
            
        <div class="login-message-container {{logoutReason}}" [ngClass]="{'loginFailed' : loginFailed}">
            <div class="login-message-btn">
                <button id="login-message-close-btn" (click)="close();" type="button" class="close" aria-label="close login message">
                    <i aria-label-hidden="true" class="close fa-solid fa-xmark"></i>
                </button>
            </div>
            
            <div *ngIf="loginFailed" class="login-fail">
                <h4 id="login-failed-header">Log In Attempt Failed</h4>
                <p id="login-failed-message">Your email address or password wasn't recognised, please try again.</p>
            </div>   

            <div *ngIf="logoutReason != null && !loginFailed" class="logout-reason {{logoutReason}}" tabindex="0">
                <h4 id="login-signed-out-header">Logged Out</h4>
                <p id="login-signed-out-expired" *ngIf="logoutReason == 'idle'">Your session has expired. Please log in again.</p>
                <p id="login-signed-out-success" *ngIf="logoutReason == 'click'">You have been successfully logged out.</p>
                <p id="login-signed-out-error" *ngIf="logoutReason == 'error'">Something went wrong. Please log in again.</p>
            </div>
        </div>
    
       

        <div class="login-form-container col-lg-8 col-lg-offset-2">
            <div class="login-align">
                <div class="login-contact-container">
                    <a id="login-contact-link" class="btn btn-secondary" aria-label="navigate to contact us" [routerLink]="['/contact-us']">Contact Us</a>
                </div>

                <div class="login-brand" aria-label="digdat utilities logo"></div>

                <div id="login-sub-heading" class="sub-heading">Welcome to the Next Generation digdat Utilities</div>

                <div class="login-text">
                    <p>
                        Please note this site is currently <b>only available for users with statutory access</b>. 
                        If you are a non-statutory user needing to view asset networks or purchase assets plans, please continue to use the 
                        <a id="login-nav-digdat-account-link" aria-label="navigate to exsting utilities" (click)="legacyLogin()">existing digdat Utilities here</a>.
                    </p>

                    <p>
                        To start using the Next Generation digdat Utilities log in below, or find out more about the exciting 
                        <a id="btn-login-features" href="#" (click)="features()">new features and enhancements</a> we've made to Next Gen Utilities.
                    </p>
                    
                    <p>You can log in using your current digdat Utilities details.</p>
                </div>

                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" autocomplete="on" class="input-form" [ngClass]="{'error': loginFailed}">
                    <div class="form-group" [ngClass]="{'invalid': loginForm.controls.userName.errors}">
                        <div for="userName" class="form-label required">Email Address</div>
                        <input id="login-username" placeholder="e.g. john.smith@digdat.co.uk" type="email" formControlName="userName" class="form-control" />

                        <div class="invalid-feedback">
                            <div id="login-email-validation-required" *ngIf="loginForm.controls.userName.errors?.required">Email address is required</div>
                            <div id="login-email-validation-invalid" *ngIf="loginForm.controls.userName.errors?.email">Must be valid email address</div>
                        </div>
                    </div>
                    <div class="form-group" [ngClass]="{'invalid': loginForm.controls.password.errors}">
                        <div for="password" class="form-label required">Password</div>
                        <div id="login-password-container">
                            
                            <input id="login-password" placeholder="Enter Password" [attr.type]="showPassword ? 'text' : 'password'" formControlName="password" class="form-control" />
                            
                            <button id="login-show-password-toggle" type="button" (click)="toggleShowPassword()" aria-label="toggle password visibility">
                                <i id="login-password-eye" class="fa-solid fa-eye" *ngIf="!showPassword"></i>
                                <i id="login-password-eye-slash" class="fa-solid fa-eye-slash" *ngIf="showPassword"></i>
                            </button>
                          
                            <span id="login-password-caps-warning" class="caps-warning alert alert-warning"><i class="fa-solid fa-exclamation-triangle"></i> Caps Locked</span>
                        </div>

                        <div class="invalid-feedback">
                            <div id="login-password-validation-required" *ngIf="loginForm.controls.password.errors?.required">Password is required</div>
                        </div>
                    </div>

                    <button id="btn-login" class="btn btn-primary btn-block btn-login" [class.busy-cursor]="loading" >
                        Log In
                    </button>

                    <a id="login-forgotten-password" role="button" href="#" (click)="forgottenPassword()" class="btn-forgotten-pwd">Forgotten your password?</a>
                </form>

                <span id="register-text" class="register-text">Don't have an account yet? <a id="btn-login-register" (click)="register()" href="#">Register here</a>  <i class="fa-solid fa-arrow-up-right-from-square"></i></span>
            </div>
        </div>
    </div>

    <div id="placeholder" class="col-md-6">

        <div class="carousel-placeholder">
            <app-carousel slideShow="true">
                <ng-container *carouselItem>
                    <div class="slide-content">
                        <div class="slide">
                            <div class="slide-img-container">
                                <img src="../../../assets/our_partners.png">
                            </div>

                            <div class="slide-header">Our Partners</div>
                            <div class="slide-text">
                                We work with our partners to provide asset information, ensuring the avoidance of unnecessary asset strikes.
                            </div>
                        </div>
                    </div>
                </ng-container>
                <!--
                <ng-container *carouselItem>
                    <div class="slide-content">
                        <div class="slide">
                            <div class="slide-img-container">
                                <img src="../../../assets/slide2.png">
                            </div>

                            <div class="slide-header">Who are our maps for?</div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *carouselItem>
                    <div class="slide-content">
                        <div class="slide">
                            <div class="slide-img-container">
                                <img src="../../../assets/slide3.png">
                            </div>

                            <div class="slide-header">What's in your area</div>
                        </div>
                    </div>
                </ng-container>
                -->
            </app-carousel>
        </div>
        <div id="os-image-container">

        </div>
    </div>
</div>
