<ng-container *ngIf="modalOpen">
    <div [ngClass]="currentModalClass" class="custom-modal" [id]="id">
        <!--<div class="vertical-alignment-helper">-->
            <div [ngClass]="currentDialogClass" role="dialog">
                <div #contentContainer class="custom-modal-content" [ngClass]="currentCustomCssClass" tabindex="0" (focusout)="modalFocusOut($event)">
                    <div class="custom-modal-header" id="{{id + '-h'}}">
                        <div class="modal-close-btn" *ngIf="showClose">
                            <button id="modal-close-btn-{{id}}{{plotAreaNumber? '-' + plotAreaNumber : ''}}" (click)="close()" type="button" class="close" data-dismiss="modal" aria-label="close modal">
                                <i aria-label-hidden="true" class="close fa-solid fa-xmark"></i>
                            </button>
                        </div>
                        <span #headerContainer class="custom-modal-title">
                            <ng-content select="[header]"></ng-content>
                        </span>
                    </div>
        
                    <div class="custom-modal-body">
                        <ng-content select="[body]"></ng-content>
                    </div>
                
                    <div #footerContainer [ngClass]="currentFooterClass">
                        <ng-content select="[footer]"></ng-content>
                    </div>
                </div>
            </div>
        <!--</div>vertical align-->
    </div>
    <div [ngClass]="currentBackdropClass" class="custom-modal-backdrop fade" tabindex="0" (focusin)="backdropFocusIn($event)"></div>
</ng-container>
