import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { INavigateService } from '../interfaces/INavigateService';
import { MapService } from './map.service';

@Injectable({
  providedIn: 'root'
})

export class NavigateService implements INavigateService {
  private digdatConnectUrl: string;
  private digdatSupportUrl: string;
  private digdatForgottonPasswordUrl: string;
  private digdatMyAccountUrl: string;
  private digdatHomeUrl: string;
  private digdatCookiePolicy: string;
  private digdatRegister: string;
  private digdatFeatures: string;
  private digdatPrivacyPolicy: string;
  private digdatTermsUse: string;
  private urls: string[] = [];

  constructor(private mapService: MapService, private router: Router) {
    this.digdatConnectUrl = environment.digdatConnectUrl;
    this.digdatSupportUrl = environment.digdatSupportUrl;
    this.digdatForgottonPasswordUrl = environment.digdatForgottenPasswordUrl;
    this.digdatMyAccountUrl = environment.digdatMyAccount;
    this.digdatHomeUrl = environment.digdatHome;
    this.digdatCookiePolicy = environment.digdatCookiePolicy;
    this.digdatRegister = environment.digdatRegister;
    this.digdatFeatures = environment.digdatFeatures;
    this.digdatPrivacyPolicy = environment.digdatPrivacyPolicy;
    this.digdatTermsUse = environment.digdatTermsUse;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.trackUrls(event.url);
      }
    });
  }

  private trackUrls(url: string) {
    this.urls.push(url);
    if (this.urls.length > 2) {
      this.urls.shift();
    }
  }

  navigateToConnect() {
    const url = this.digdatConnectUrl;
    const param = `?PassThrough=${this.mapService.getCenter().lat.toString()},${this.mapService.getCenter().lng.toString()}`;
    window.open(url + param, '_blank');
  }

  navigateToSupport() {
    window.open(this.digdatSupportUrl, '_blank');
  }

  navigateToForgottonPassword() {
    window.open(this.digdatForgottonPasswordUrl, '_blank');
  }

  previousUrl(): string {
    if (this.urls.length > 0) {
      return this.urls[0];
    }
    return null;
  }

  navigateToAccount() {
    window.open(this.digdatMyAccountUrl, '_blank');
  }

  navigateToHome() {
    window.open(this.digdatHomeUrl, '_blank');
  }

  navigateToLegacyLogin() {
    window.location.href = this.digdatHomeUrl + 'Account/Login.aspx';
  }

  navigateToCookiePolicy() {
    window.open(this.digdatCookiePolicy, '_blank');
  }

  navigateToRegister() {
    window.open(this.digdatRegister, '_blank');
  }

  navigateToFeatures() {
    window.open(this.digdatFeatures, '_blank');
  }

  navigateToPrivacyPolicy() {
    window.open(this.digdatPrivacyPolicy, '_blank');
  }

  navigateToTermsUse() {
    window.open(this.digdatTermsUse , '_blank');
  }
}
