import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAssetLayerModel } from '../interfaces/IAssetLayerModel';
import { IAssetLayerService } from '../interfaces/IAssetLayerService';
import { IBoundaryInfo } from '../interfaces/IBoundaryInfo';
import { ILayerModel } from '../interfaces/ILayerModel';
import { ILayersAreas } from '../interfaces/ILayersAreas';
import { IPermissionsToken } from '../interfaces/IPermissionsToken';
import { IPolygonRestriction } from '../interfaces/IPolygonRestriction';
import { ServiceErrorHandler } from '../shared/service-error-handler';
import { AssetPermissionService } from './asset-permission.service';
import { MapMetaService } from './map-meta.service';

@Injectable({
  providedIn: 'root'
})

/**
 * Gets asset layers by permission for the current user
 */
export class AssetLayerService implements IAssetLayerService {
  private mapApiUrl: string;

  constructor(private http: HttpClient, private errorHandler: ServiceErrorHandler, private mapMetaService: MapMetaService, private permissionService: AssetPermissionService) {
    this.mapApiUrl = environment.mapApiUrl;
  }

  /**
   * Get Layer Ids by location
   * @param xL lower left x coordinate
   * @param yL lower left y coordinate
   * @param xU upper right x coordinate
   * @param yU upper right y coordinate
   */
  private getLayersAndAreas(xL: number, yL: number, xU: number, yU: number): Observable<ILayersAreas> {
    const apiAction = '/GetLayersAndAreas/';

    const params = new HttpParams()
    .set('xL', xL.toString())
    .set('yL', yL.toString())
    .set('xU', xU.toString())
    .set('yU', yU.toString());

    return this.http.get<ILayersAreas>(this.mapApiUrl + apiAction, {
      params
    }).pipe(
      map(layerAreaResponse => {
        if (layerAreaResponse) {
          layerAreaResponse.layers.forEach(l => l.id = l.id.toLowerCase());
        }
        return layerAreaResponse;
      }),
      catchError(err => {
        this.errorHandler.handleError(err);
        return of(null);
      })
    );
  }

  /**
   * Return the parent Asset Layer for a given layer
   * @param layerId child layer id to get parent asset layer from
   */
  getAssetLayer(layerId: string): IAssetLayerModel {
    return this.mapMetaService.getAssetLayers().find(a => a.layers.some(l => l.mapLayer === layerId));
  }

  /**
   * Create AssetLayerModels.
   * Only return AssetLayers (with layers) for this location.
   * Set 'viewable' property based on the id of the layer being in the user's permissions.
   * Apply polygon restriction logic.
   * @param layersAreas layers and areas in the current location
   * @param permissionsToken layers user has permission to and polygon restrictions
   */
  toAssetLayerModels(layersAreas: ILayersAreas, permissionsToken: IPermissionsToken): IAssetLayerModel[] {
    try {
      if (layersAreas && permissionsToken) {
        const locationLayerIds = layersAreas.layers.map(l => l.id);

        // Only return asset layers (from a list of all asset layers) where the layer id is in the current location
        return this.mapMetaService.getAssetLayers().filter(ass => ass.layers.some(l => locationLayerIds.includes(l.mapLayer)))
        .map(ass => {
          // Only return layers which are in the current location
          ass.layers = ass.layers.filter(l => locationLayerIds.includes(l.mapLayer));

          // Set properties for layers in the current location
          ass.layers.forEach(l => {
            this.toLayerModel(l, permissionsToken, layersAreas);
          });

          ass.layers.sort((a, b) => a.provider.localeCompare(b.provider));

          return ass;
        });
      }
    } catch (error) {
      // handled
      return [];
    }
  }

  toLayerModel(d: ILayerModel, permissionsToken: IPermissionsToken, layersAreas: ILayersAreas) {
    const hasPermission = this.hasPermissionToView(d.providerId, permissionsToken.polygonRestrictions, layersAreas.areas);
    d.restricted = !hasPermission;
    d.viewable = permissionsToken.allowedLayerIds.includes(d.mapLayer) && hasPermission;
  }

  /**
   * Determine if a layer is allowed to be viewed based on polygon restrictions
   * @param providerId layer's provider Id
   * @param polygonRestrictions a collection of polygon restrictions for the current user
   * @param areas a list of boundary info (polygons) for the current area
   */
   private hasPermissionToView(providerId: number, polygonRestrictions: IPolygonRestriction[], areas: IBoundaryInfo[]): boolean {
    // If user does not have polygon restrictions ignore
    if (polygonRestrictions) {
      const providerPolyRestrictions = [...polygonRestrictions].filter(i => i.providerId === providerId);
      if (providerPolyRestrictions.length > 0) {
        // If any polygon id in the Areas response matches one in the user’s polygon restrictions (for the current layer's provider)
        if (areas.some(a => providerPolyRestrictions.map(p => p.polygonId).includes(a.id))) {
          // Layers for that provider are allowed in current map area
          const allowedProviderIds = providerPolyRestrictions.filter(p => areas.map(a => a.id).includes(p.polygonId)).map(p => p.providerId);
          return allowedProviderIds.includes(providerId);
        }
        return false;
      }
    }
    return true;
  }

  /**
   * Get asset layers (and layers) by permission and location, then return the result
   * @param xL lower left x coordinate
   * @param yL lower left y coordinate
   * @param xU upper right x coordinate
   * @param yU upper right y coordinate
   */
  getAssetLayers(xL: number, yL: number, xU: number, yU: number): Observable<IAssetLayerModel[]> {
    // Call multiple sources for required data
    return forkJoin({
      permissionToken: this.permissionService.getPermissions(),
      layersAreas: this.getLayersAndAreas(xL, yL, xU, yU)
    }).pipe(
      switchMap((result) => {
        const assetLayers = this.toAssetLayerModels(result.layersAreas, result.permissionToken);
        return of(assetLayers);
      }),
      catchError(() => of([]))
    );
  }

}
