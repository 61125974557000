<div id="order-summary-items" *ngIf="orderItems">
    <table class="table table-fixed">
        <thead>
            <tr>
                <th>
                    Plot Area
                </th>

                <th>
                    Plot Title
                </th>

                <th>
                    Asset Layer
                </th>

                <th>
                    Asset Owner
                </th>
<!--
                <th>
                    Assets
                </th>
-->
                <th>
                    Scale
                </th>
                
                <th>
                    Orientation
                </th>

                <th>
                    Paper Size
                </th>

                <th>
                    Background
                </th>

                <th *ngIf="validationError">
                    &nbsp;
                </th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let item of orderItems; let i = index;" [ngClass]="item.plotAreaNumber | oddEven">
                <td  id="order-summary-item-plotarea-{{i}}"> 
                    {{item.plotAreaNumber}}
                </td>

                <td id="order-summary-item-title-{{i}}">
                    {{item.plotTitle}}
                </td>

                <td id="order-summary-item-layer-{{i}}">
                    {{item.assetLayer}}
                </td>

                <td id="order-summary-item-owner-{{i}}">
                    {{item.assetOwner}}
                </td>
 <!--
                <td id="order-summary-item-assets-{{i}}">
                   
                    <i *ngIf="item.assets" class="yes-assets fa fa-check"></i>
                    <span *ngIf="!item.assets">No</span>
                   
                </td>
  -->               
                <td id="order-summary-item-scale-{{i}}">
                    {{item.scale}}
                </td>

                <td id="order-summary-item-orientation-{{i}}">
                    {{item.orientation}}
                </td>

                <td id="order-summary-item-paper-size-{{i}}">
                    {{item.pageSize}}
                </td>

                <td id="order-summary-item-background-{{i}}">
                    {{item.theme}}
                </td>

                <td id="order-summary-item-validation-error-{{i}}" class="validation-result-col" *ngIf="validationError">
                    <i id="order-summary-item-validation-error-icon-{{i}}" *ngIf="item.validationResult !== 2" class="validation-icon fa-solid fa-circle-exclamation"></i>
                    <span *ngIf="!item.validationResult">&nbsp;</span>
                </td>
            </tr>
        </tbody>
       
    </table>
</div>

