import { Injectable } from '@angular/core';
import { ModalComponent } from '../shared/modal/modal.component';
import { CustomModalOptions } from '../models/custom-modal-options';
import { IModalService } from '../interfaces/IModalService';

@Injectable({
  providedIn: 'root'
})

export class ModalService implements IModalService {

  private modals: ModalComponent[] = [];
  private currentModal: ModalComponent | null;

  constructor() {
    this.currentModal = null;
  }

  add(modal: ModalComponent) {
    if (!this.modals.find(m => m.id === modal.id)) {
    // add modal to array of active modals
    this.modals.push(modal);
    } else {
      console.log('A modal with an id of ' + modal.id + ' already exists');
    }
  }

  remove(id: string) {
    // remove modal from array of active modals
    this.modals = this.modals.filter(x => x.id !== id);
    if (this.currentModal && this.currentModal.id === id) {
      this.currentModal = null;
    }
  }

  open(id: string, options?: CustomModalOptions) {
    // open modal specified by id
    const modal: any = this.modals.filter(x => x.id === id)[0];

    if (this.currentModal != null) {
      this.close(this.currentModal.id);
    }
    if (modal) {
      this.currentModal = modal;
      modal.open(options);
    }
  }

  close(id?: string) {
    if (!id) {
      if (this.currentModal) {
        id = this.currentModal.id;
      }
    }
    // close modal specified by id
    const modal = this.modals.filter(x => x.id === id)[0];
    if (modal) {
      modal.close();
    }
    this.currentModal = null;
  }

}
