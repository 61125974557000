import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { IUserModel } from '../interfaces/IUserModel';
import { IAuthenticationService } from '../interfaces/IAuthenticationService';
import { LogoutReason } from '../models/logout-reason';
import { ServiceErrorHandler } from '../shared/service-error-handler';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService implements IAuthenticationService {
  private httpHeaders = new HttpHeaders({
    Content: 'application/json',
    'Access-Control-Allow-Origin': '*'
  });
  private apiUrl: string;
  private currentUser = new BehaviorSubject<IUserModel | null>(null);
  private loggedOut = new BehaviorSubject<LogoutReason | null>(null);
  private refreshTokenTimeout: any;
  currentUser$ = this.currentUser.asObservable();
  loggedOut$ = this.loggedOut.asObservable();

  constructor(private httpClient: HttpClient, private serviceErrorHandler: ServiceErrorHandler) {
    this.apiUrl = environment.authApiUrl;
    const user = localStorage.getItem('user');
    if (user) {
      this.currentUser.next(JSON.parse(user) as IUserModel);
    }

    this.serviceErrorHandler.$criticalError.subscribe(() => {
      if (environment.dev  !== 'true') {
        this.logout(LogoutReason.Error);
      }
    });

  }

  private setLocalStorage(user: IUserModel): void {
    localStorage.setItem('user', JSON.stringify(user));
  }

  public get currentUserValue(): IUserModel {
    return this.currentUser.value ? this.currentUser.value : null;
  }

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.currentUser.value.accessToken.split('.')[1]));
    
    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    
    const timeout = expires.getTime() - Date.now() - 1000; // expires.getTime() - Date.now() - (60 * 1000);
 
    this.refreshTokenTimeout = setTimeout(() => {
      this.refreshToken().subscribe();
    }, timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  login(userName: string, password: string): Observable<IUserModel> {
    const formData = new FormData();
    formData.append('userName', userName);
    formData.append('password', password);

    return this.httpClient.post<IUserModel>(`${this.apiUrl}/login`, formData, { headers: this.httpHeaders, withCredentials: true }).pipe(
        delay(1),
        map(user => {
          this.setLocalStorage(user);
          this.currentUser.next(user);
          this.startRefreshTokenTimer();
          return user;
      }),
      catchError(error  => {
        if (error && error.status) {
          if (error.status === 400) {
            return of(null);
          }
        }
        this.logout(LogoutReason.Error);
      })
    );
  }

  refreshToken(): Observable<string> {

    if (this.currentUserValue && this.currentUserValue.userName) {

      const formData = new FormData();
      formData.append('userName', this.currentUserValue.userName);

      return this.httpClient.post<IUserModel>(`${this.apiUrl}/refreshToken`, formData, { headers: this.httpHeaders, withCredentials: true }).pipe(
        map(user => {
          this.setLocalStorage(user);
          if (this.currentUser.value === null) {
            this.currentUser.next(user);
          } else {
            this.currentUser.value.accessToken = user.accessToken;
          }
          this.startRefreshTokenTimer();
          return user.accessToken;
        })
      );
    } else {
      return of(null);
    }
  }

  logout(reason?: LogoutReason) {
    reason = !reason? LogoutReason.Error : reason;
    const user = (this.currentUserValue && this.currentUserValue.userName) ? this.currentUserValue.userName : '';
    this.killAuth();
    this.loggedOut.next(reason);
    this.stopRefreshTokenTimer();
    location.href = location.origin + '/login?logoutReason=' + reason.toString() +
    '&returnUrl=' + window.location.pathname +
    '&lastUser=' + user;
  }

  hasClaim(claimType: any, claimValue?: any): boolean {
    // TODO.  Check out Paul's advice here https://app.pluralsight.com/library/courses/angular-security-json-web-tokens
    throw new Error('Method not implemented.');
  }

  killAuth(){
    this.setLocalStorage(null);
    this.currentUser.next(null);
  }

}
