
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './services/jwt.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './public/login/login.component';
import { RegistrationComponent } from './public/register/registration.component';
import { HomeComponent } from './home/home.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HeaderModule } from './header/header.module';
import { TrackCapsDirective } from './public/track-caps.directive';
import { SharedModule } from './shared/shared.module';
import { SpinnerService } from './services/spinner.service';
import { IdleExpiry, NgIdleModule, SimpleExpiry } from '@ng-idle/core';
import { TitleService } from './services/title.service';
import { FaqsComponent } from './home/faqs/faqs.component';
import { GlossaryComponent } from './home/glossary/glossary.component';
import { GlossaryItemComponent } from './home/glossary/glossary-item/glossary-item.component';
import { HelpVideosComponent } from './home/help-videos/help-videos.component';
import { VideoItemComponent } from './home/help-videos/video-item/video-item.component';
import { ContactUsComponent } from './public/contact-us/contact-us.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistrationComponent,
    HomeComponent,
    TrackCapsDirective,
    FaqsComponent,
    GlossaryComponent,
    GlossaryItemComponent,
    HelpVideosComponent,
    VideoItemComponent,
    ContactUsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgIdleModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    HeaderModule,
    SharedModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    SpinnerService, TitleService,
    { provide: IdleExpiry, useClass: SimpleExpiry },
    { provide: ErrorHandler, useClass: ApplicationinsightsAngularpluginErrorService } // Log unhandled exceptions,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
