import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-search-help',
  templateUrl: './search-help.component.html',
  styleUrls: ['./search-help.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class SearchHelpComponent implements OnInit {
  searchHelpModalId = 'search-help-modal';

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
  }

  showModal(e: Event) {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.modalService.open(this.searchHelpModalId);
  }
}
