import { inject } from '@angular/core';
import { ActivatedRoute, CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';

export const canActivateAuth: CanActivateFn = () => {
    const router = inject(Router);
    const service = inject(AuthenticationService);
    const state = inject(ActivatedRoute);

    if (service.currentUserValue) {
        return true;
    }
    router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
};
  