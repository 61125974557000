<app-modal [id]="orderSummaryModalId">
    <div header>
        <div id="order-summary-modal-main-h" class="main-title">
            Order Summary
        </div>
        <div class="sub-title"><span id="order-summary-plot-count" class="sub-title-inner"><span id="order-summary-plot-count-val">{{orderItems.length}}</span> Plot<span *ngIf="orderItems.length > 0">s</span></span></div>
        <div id="order-summary-title-container" *ngIf="orderItems.length > 0">
            <label id="order-summary-title-label" for="order-summary-title">Order Title (Optional)</label>
            <input id="order-summary-title" autocomplete="off" type="text" maxlength="20" placeholder="Name of Order" class="form-control" [(ngModel)]="orderTitle">
            <button id="order-summary-title-clear" *ngIf="orderTitle" (click)="clearTitle()" type="button" class="btn btn-clear-input" aria-label="clear plot title">
                <i aria-hidden="true" class="fa-solid fa-xmark fa-lg"></i>
            </button>
        </div>
    </div>
    <div body>
        <div *ngIf="orderItems.length > 0">
            <app-order-summary [validationError]="validationError" [orderItems]="orderItems"></app-order-summary>
        </div>
    </div>
    <div footer>
        <div id="order-summary-modal-validation-error" *ngIf="validationError">
            <i id="order-summary-modal-validation-error-icon" class="validation-icon fa-solid fa-circle-exclamation"></i>
            <span id="order-summary-modal-validation-error-message">You no longer have access to this asset layer.  To manage your access please go to <a (click)="navMyAccount()">digdat Utilities</a>.</span>
        </div>

        <ng-container *ngIf="isReorder; else orderTemplate">
            <div id="order-summary-edit-container" class="col-xs-6">
                <button id="order-summary-edit" class="btn btn-secondary" (click)="tryEditOrder()" type="button">Edit Order</button>
            </div>
    
            <div id="order-summary-confirm-container" class="col-xs-6">
                <button id="order-summary-confirm" class="btn btn-primary" (click)="confirmOrder()" type="button">Confirm Order</button>
            </div>
        </ng-container>

        <ng-template #orderTemplate>
            <div id="order-summary-confirm-container" class="col-sm-12 centered">
                <button id="order-summary-confirm" class="btn btn-primary" (click)="confirmOrder()" type="button">Confirm Order</button>
            </div>
        </ng-template>
    </div>
</app-modal>


<app-modal #basketItemsModal id="order-summary-basket-items-modal">
    <div header>
        <div id="order-summary-basket-items-modal-h">
            There are already plots in your basket
        </div>
    </div>

    <div body>
       You already have plots in your basket.  If you continue to edit this reorder, you will lose the plots currently in your basket.  Do you want to continue to edit your reorder?
    </div>

    <div footer>
        <button id="order-summary-basket-items-go-basket" class="btn btn-secondary" type="button" (click)="navigateToMap()">No, go to basket</button>
        <button id="order-summary-basket-items-edit-order" class="btn btn-primary" type="button" (click)="editOrder()">Yes, edit reorder</button>
    </div>
</app-modal>
