import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './public/login/login.component';
import { FaqsComponent } from './home/faqs/faqs.component';
import { GlossaryComponent } from './home/glossary/glossary.component';
import { HomeComponent } from './home/home.component';
import { HelpVideosComponent } from './home/help-videos/help-videos.component';
import { ContactUsComponent } from './public/contact-us/contact-us.component';
import { canActivateAuth } from './can-activate';

const routes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [canActivateAuth] },
  { path: 'login', component: LoginComponent},
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'help-videos', component: HelpVideosComponent, canActivate: [canActivateAuth] },
  { path: 'faqs', component: FaqsComponent, canActivate: [canActivateAuth] },
  { path: 'glossary', component: GlossaryComponent, canActivate: [canActivateAuth] },
  { path: 'orders', loadChildren: () => import('./orders/orders.module').then(mod => mod.OrdersModule), canActivate: [canActivateAuth] },
  { path: 'mapping', loadChildren: () => import('./mapping/mapping.module').then(mod => mod.MappingModule), canActivate: [canActivateAuth] },
  { path: 'account', loadChildren: () => import('./account/account.module').then(mod => mod.AccountModule), canActivate: [canActivateAuth] },
  { path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload'} as ExtraOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
