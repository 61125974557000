export const environment = {
    dev: '#{dev}#',
    production: '#{production}#',
    authApiUrl: '#{authApiUrl}#',
    digdatConnectUrl: '#{digdatConnectUrl}#',
    mapApiUrl: '#{mapApiUrl}#',
    notificationApiUrl: '#{notificationApiUrl}#',
    searchApiUrl: '#{searchApiUrl}#',
    userApiUrl: '#{userApiUrl}#',
    orderHistoryApiUrl: '#{orderHistoryApiUrl}#',
    basketApiUrl: '#{basketApiUrl}#',
    notificationHubUrl: '#{notificationHubUrl}#',
    userPreferenceApiUrl: '#{userPreferenceApiUrl}#',
    plotApiUrl: '#{plotApiUrl}#',
    orderApiUrl: '#{orderApiUrl}#',
    digdatSupportUrl: '#{digdatSupportUrl}#',
    digdatForgottenPasswordUrl: '#{digdatForgottenPasswordUrl}#',
    digdatMyAccount: '#{digdatMyAccount}#',
    digdatHome: '#{digdatHome}#',
    digdatCookiePolicy: '#{digdatCookiePolicy}#',
    digdatRegister: '#{digdatRegister}#',
    digdatFeatures: '#{digdatFeatures}#',
    digdatPrivacyPolicy: '#{digdatPrivacyPolicy}#',
    digdatTermsUse: '#{digdatTermsUse}#',
    helpVideoUrl: '#{helpVideoUrl}#',
    additionalScripts: '#{additionalScripts}#',
    insightsConnection: '#{insightsConnection}#',
    mapTileApiKey: '#{mapTileApiKey}#',
    googleGTag: '#{googleGTag}#',
    testAddress: '#{testAddress}#'
};
