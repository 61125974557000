import { IPlotModel } from '../interfaces/IPlotModel';
import { AssetLayerModel } from './asset-layer.model';

export class PlotModel implements IPlotModel {
    id = '';
    plotTitle = null;
    printScale = null;
    landscape = false;
    mono = false;
    paperSize = null;
    comments = null;
    printScales = [];
    paperSizes = [];
    printPreviewPaperSizes = [];
    assetLayers = new Array<AssetLayerModel>();
    plotTitleMaxLength: number;
    commentsMaxLength: number;
    plotVisible = true;
    y = 0;
    x = 0;
    plotAreaNumber = 0;
    isCurrent = false;
    isCreated = true;
    isDirty = false;
    plotToolbarVisible = false;
    canDuplicate = false;
    valid = false;
    canEdit = false;
    assetLayersUpdating = false;
    orderEdit = false;

    constructor(y?: number, x?: number) {
        this.plotTitleMaxLength = 20;
        this.commentsMaxLength = 50;
        if (y) {
            this.y = y;
        }

        if (x) {
            this.x = x;
        }
    }
}

