<div class="help-videos-content">

    <div class="container">
        <div class="custom-breadcrumb">
            <a id="help-videos-breadcrumb-home" title="home" [routerLink]="['/']">Home</a>
            <span class="crumb-separator">/</span>
            <span id="help-videos-breadcrumb-glossary">Help Videos</span>
        </div>

        <h1>Help Videos</h1>

        <div class="row">

            <div class="help-video-item col-md-6" appVideoItem path="https://www.youtube.com/embed/8fIs-ZY_H64">
                <ng-container title>Introduction to Next Generation digdat Utilities</ng-container>
            </div>

            <div class="help-video-item col-md-6" appVideoItem path="https://www.youtube.com/embed/5kaXpaiaIZU">
                <ng-container title>Create plots quickly and efficiently</ng-container>
            </div>

            <div class="help-video-item col-md-6" appVideoItem path="https://www.youtube.com/embed/dJ7U5qb43kM">
                 <ng-container title>Order asset plans, view order details and order history</ng-container>
            </div>
    
            <div class="help-video-item col-md-6" appVideoItem path="https://www.youtube.com/embed/irASRAprVt8">
                <ng-container title>Generate A4 and A3 PDFs quickly via Print Preview</ng-container>
            </div>

            <div class="help-video-item col-md-6" appVideoItem path="https://www.youtube.com/embed/7MLmtR9G55o">
                <ng-container title>Set and use user preferences</ng-container>
            </div>
    
           
            <div class="help-video-item col-md-6" appVideoItem path="https://www.youtube.com/embed/7czWNdUhOvw">
                <ng-container title>View and manage your account details</ng-container>
            </div>
        </div>
    </div>
</div>