import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { merge } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './services/authentication.service';
import { BrowserService } from './services/browser.service';
import { ScriptService } from './services/script.service';
import { SpinnerService } from './services/spinner.service';
import { TitleService } from './services/title.service';
import { UrlHelperService } from './services/url-helper-service';
import { WindowResizeService } from './services/window-resize.service';
import { HeaderComponent } from './header/header.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';

declare const dataLayer: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  standalone: false
})

export class AppComponent implements OnInit {
  title = 'digdat-web';
  showHead = false;
  private overflowYClass = 'overflowed-y';
  private overflowXClass = 'overflowed-x';
  private analyticsImplemented = false;
  private gsTagScriptLoaded = false;

  constructor(router: Router, private browserService: BrowserService, private urlHelperService: UrlHelperService, 
    private titleService: TitleService, private scriptService: ScriptService, private authService: AuthenticationService,
    private windowResizeService: WindowResizeService, private spinnerService: SpinnerService) {

    this.setAnalyticsImplemented();

    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });

        this.urlHelperService.addPathToBody(event.url);
        this.titleService.setTitle(event.url);
        if (event.url.startsWith('/login')) {
          this.showHead = false;
        } else {
          this.showHead = true;
        }
      } else if (event instanceof NavigationEnd) {
        this.logAnalytics(event);
      }

    });

    merge(this.windowResizeService.height$, this.windowResizeService.width$).subscribe(() => {
      this.applyOverflowClasses();
    });

    this.spinnerService.visibleChanged$.subscribe(() => {
      this.applyOverflowClasses();
    });

    this.applyOverflowClasses();
  }

  private setAnalyticsImplemented(): void {
    try {
      if (environment.production === 'true') {
        if (environment.googleGTag) {
          this.analyticsImplemented = true;
        }
      }
    }
    catch {
    }
  }

  private logAnalytics(event: NavigationEnd): void {
    if (this.analyticsImplemented && this.gsTagScriptLoaded) {
      try {
        if (dataLayer) {
          (dataLayer as any).push({
            event: 'page_view',
            page_path: event.urlAfterRedirects
          });
        }
      }
      catch {
      }
    }
  }

  private addExternalScripts() {
    this.addGoogleAnalytics();

    const scripts = environment.additionalScripts;
    if (scripts && scripts.length > 0) {
      scripts.split(',').forEach(s => {
        this.scriptService.appendScript(s, document.head);
      });
    }
  }

  private addGoogleAnalytics() {
    try {
      if (this.analyticsImplemented) {
        this.scriptService.appendScript(environment.googleGTag, document.head, null, () => {
          this.gsTagScriptLoaded = true;
        });
      }
    }
    catch {
      this.analyticsImplemented = false;
    }
  }

  /**
   * Identify if body is overflowed and add/remove CSS classes
   */
  private applyOverflowClasses() {
    let overflowedX = false;
    let overflowedY = false;
    const body = document.getElementsByTagName('body')[0];

    if (body) {
      overflowedY = body.offsetHeight < body.scrollHeight;
      overflowedX = body.offsetWidth < body.scrollWidth;

      if (overflowedY) {
        body.classList.add(this.overflowYClass);
      } else if (body.classList.contains(this.overflowYClass)) {
        body.classList.remove(this.overflowYClass);
      }

      if (overflowedX) {
        body.classList.add(this.overflowXClass);
      } else if (body.classList.contains(this.overflowXClass)){
        body.classList.remove(this.overflowXClass);
      }
    }
  }

  ngOnInit(): void {
    const body = document.getElementsByTagName('body')[0];
    const browser = this.browserService.getBrowser(window.navigator.userAgent);
    if (browser) {
      body.classList.add(browser);
    }
    
    this.addExternalScripts();
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(): void {
      if (environment.dev !== 'true') {
        this.authService.killAuth();
      }
  }
}
