import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject, throwError } from 'rxjs';
import { IServiceErrorHandler } from '../interfaces/IServiceErrorHandler';
import { LoggerService } from '../services/logger.service';
import { LoggingType } from '../models/logging-type';
import { UrlHelperService } from '../services/url-helper-service';

@Injectable({
    providedIn: 'root'
})

export class ServiceErrorHandler implements IServiceErrorHandler {
    private criticalError = new Subject<string>();
    private nonCriticalPaths = ['order', 'search', 'plot', 'orderhistory', 'notification'];
    $criticalError = this.criticalError.asObservable();

    constructor(private loggerService: LoggerService, private urlHelper: UrlHelperService) {
    }

    /**
     * Check the error url for a non-critical path
     * @param error http error response
     * @returns boolean
     */
    private isCriticalError(error: HttpErrorResponse): boolean 
    {
      if (error && error.url) {
        const path = this.urlHelper.getPathFromUrl(error.url);
        return this.nonCriticalPaths.findIndex(p => p === path.toLowerCase()) === -1;
      }
      return true;
    }

    handleError(error: HttpErrorResponse) {
      let errorMessage = '';
      if (error.error && error.error instanceof ErrorEvent) {
          // Get client-side error
          errorMessage = error.error.message;
        } else {
          // Get server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          
          if (this.isCriticalError && this.isCriticalError(error)) {
            this.criticalError.next(error.message);
            this.loggerService.log(error, LoggingType.Critical);
          }
        }
      return throwError(errorMessage);
    }
}
