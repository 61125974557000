import { BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ISpinnerService } from '../interfaces/ISpinnerService';
import { Injectable } from '@angular/core';

@Injectable()
export class SpinnerService implements ISpinnerService {
    private delay = 200;
    private message = '';
    private visible = new BehaviorSubject<{message?: string, visible: boolean}>({ visible: false });
    visibleChanged$ = this.visible.asObservable().pipe(delay(this.delay));

    private getId() {
        return Math.random().toString(36).substring(2, 34);
    }

    constructor() {
    }

    /**
     * Show the spinner
     */
    show(): void {
        this.visible.next({ message: this.message, visible: true });
    }

    /**
     * Hide the spinner.
     */
    hide(): void {
        if (this.visible.value) {
            this.message = '';
            this.visible.next({ visible: false });
        }
    }

    /**
     * Set a message to display when the spinner is shown
     * @param message the message to display with the spinner
     */
    setMessage(message: string) {
        this.message = message;
    }
}
