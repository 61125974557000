<app-modal [id]="orderUnavailableModalId">
    <div header>
        <div id="order-unavailable-modal-title">
            Reorder Unavailable
        </div>
    </div>
    <div id="order-unavailable-modal-body" body>
        <p id="order-unavailable-modal-body-para-1">Reordering is not possible, please create the order again.</p>

        <p id="order-unavailable-modal-body-para-2">
            To manage your access please go to <a id="order-unavailable-account-link" href="#" (click)="navMyAccount($event);" aria-label="navigate to my account">digdat Utilities</a>.
        </p>
    </div>
    <div footer class="modal-footer-center">
        <button id="order-unavailable-close" class="btn btn-primary" (click)="close()" type="button">Close</button>
    </div>
</app-modal>
