import { Injectable } from '@angular/core';
import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IPlotModel } from '../interfaces/IPlotModel';
import { IPlotStateService } from '../interfaces/IPlotStateService';
import { PlotValidationService } from './plot-validation.service';

@Injectable({
  providedIn: 'root'
})

/** In-memory storage and management of plots in the current order/session.
 * Marshall changes to plot instances, apply logic to instances dependant on state
 * and only emit changes to relevant observables when checkState is called.
 */
export class PlotStateService implements IPlotStateService {
  private emitCounter = 0;
  private emitter = new BehaviorSubject<number>(this.emitCounter);
  private plots = new BehaviorSubject<IPlotModel[]>([]);
  private lastEmittedPlots: IPlotModel[] = []; // last emitted plots state
  private frozenCurrentPlot: IPlotModel = null; // state when first set current.  Ignore checkState

  currentPlotChanged$ = this.emitter.asObservable().pipe(
    map(() => {
      const current = this.plots.value.find(p => p.isCurrent);
      if (current) {
        return current;
      }
      return null;
    })
  );

  plotsChanged$ = this.emitter.asObservable().pipe(
    map(() => {
      this.plots.value.sort(this.sortPlots);
      return this.plots.value;
    })
  );

  constructor(private plotValidationService: PlotValidationService) {
  }

  /**
   * Sort plots into numerical plotAreaNumber order
   * @param item1 
   * @param item2 
   * @returns 
   */
  private sortPlots(item1: IPlotModel, item2: IPlotModel) {
    if (item1.plotAreaNumber > item2.plotAreaNumber) {
        return 1;
    }

    if (item1.plotAreaNumber < item2.plotAreaNumber) {
        return -1;
    }

    return 0;
  }

  /**
   * Has the state of the plots value changed since last checkstate call.
   * Compare lastEmittedPlots and the plots.value and return if both are no longer equal.
   */
  private havePlotsChanged(): boolean {
    if ((this.lastEmittedPlots && !this.plots.value) ||
        (!this.lastEmittedPlots && this.plots.value)) {
      return true;
    }

    if (this.lastEmittedPlots.length !== this.plots.value.length) {
      return true;
    }

    return !isEqual(this.lastEmittedPlots, this.plots.value);
  }

  /**
   * Compare two instances of a plot model and return if both are equal.
   * Optionally ignore some properties for comparison.
   * @param source plot to compare
   * @param target plot to compare
   * @param ignore optional - plot properties to ignore for comparison
   */
   private plotEquals(source: IPlotModel, target: IPlotModel, ignore?: Partial<IPlotModel>): boolean {
    ignore = ignore ? ignore : {};
    source = Object.assign({}, source, ignore);
    target = Object.assign({}, target, ignore);
    return isEqual(source, target);
  }

  /**
   * If the plot is the current plot and there is a safe copy to compare it with
   * then return whether or not the safe copy and the plot share the same property values (if not then the plot is dirty).
   * @param plot the plot instance to use to determine if dirty
   */
   private isPlotDirty(plot: IPlotModel): boolean {
    if (this.isCurrentPlot(plot)) {
      if (this.isCurrentPlot(plot) && this.frozenCurrentPlot) {
        const propertiesIgnore = { canDuplicate: true, plotToolbarVisible: true, isDirty: true };
        return !this.plotEquals(plot, this.frozenCurrentPlot, propertiesIgnore);
      }
    }

    return false;
  }

  private updatePlotInPlots(plot: IPlotModel): void {
    if (plot) {
      const plotIdx = this.getPlotIndex(plot);
      if (plotIdx > -1) {
        plot.isCurrent = this.isCurrentPlot(plot);
        plot.isDirty = this.isPlotDirty(plot);
        this.setAllToolbarVisibility(plot);
        plot.valid = this.plotValidationService.isValid(plot);
        this.plots.value[plotIdx] = plot;
        this.plots.next(this.plots.value);
      }
    }
  }

  private clonePlot(plot: IPlotModel): IPlotModel {
    return cloneDeep(plot);
  }

  private updatePlots(plots: IPlotModel[]) {
    plots = plots ? plots : [];
    this.setAllCanEdit();
    this.setAllCanDuplicate();
    this.plots.next(plots.map(p => this.clonePlot(p)));
  }

  private canDuplicate(plots: IPlotModel[]): boolean {
    const anyDirty = plots.findIndex(p => p.isDirty) !== -1;
    const anyNotCreated = plots.findIndex(p => !p.isCreated) !== -1;
    return !anyDirty && !anyNotCreated;
  }

  private canEdit(plot: IPlotModel): boolean {
    const allCreated = this.plots.value.findIndex(p => !p.isCreated) === -1;
    const currentPlot = this.getCurrentPlot();
    if (currentPlot && currentPlot.id === plot.id) {
      return true;
    }
    return currentPlot == null && allCreated;
  }

  private setAllCanEdit() {
    this.plots.value.forEach(p => {
      p.canEdit = this.canEdit(p);
    });
  }

  private setAllCanDuplicate() {
    const canDuplicate = this.canDuplicate(this.plots.value);

    this.plots.value.forEach(p => {
      p.canDuplicate = canDuplicate;
    });
  }

  private setAllToolbarVisibility(plot: IPlotModel) {
    if (plot.plotToolbarVisible) {
      this.plots.value.forEach(p => {
        if (p.id !== plot.id) {
          p.plotToolbarVisible = false;
        }
      });
    }
  }

  private reSequencePlotAreaNumbers(): string[] {
    const changed = Array<string>();
    this.plots.value.forEach((plot, idx) => {
      if (plot.plotAreaNumber !== idx + 1) {
        changed.push(plot.id);
      }
      plot.plotAreaNumber = idx + 1;
    });

    this.plots.next(this.plots.value);
    return changed;
  }

  private getNextPlotAreaNumber(plots: IPlotModel[]): number {
    if (plots.length === 0) {
      return 1;
    }
    const max = plots.map(p => p.plotAreaNumber).reduce((a, b) => {
      return Math.max(a, b);
    });
    return max + 1;
  }

  /**
   * Get the index of a plot instance in the plot array
   * @param plot instance
   */
  getPlotIndex(plot: IPlotModel): number {
    return this.plots.value.findIndex(p => p.id === plot.id);
  }

  /**
   * Return a cloned copy of the plots collection
   * @param ids optional plot.id filter to apply to return array
   */
  getPlots(ids?: string[]): ReadonlyArray<IPlotModel> {
    let cloned = this.plots.value.map(p => {
      p.isCurrent = this.isCurrentPlot(p);
      return this.clonePlot(p);
    });
    if (ids) {
      cloned = cloned.filter(p => ids.indexOf(p.id) !== -1);
    }
    return cloned.sort(this.sortPlots);
  }

  /**
   * Return a cloned copy of the plot witin the plots collection
   * @param id plot.id of the required plot
   */
  getPlot(id: string): IPlotModel {
    return this.getPlots([id])[0];
  }

  /**
   * Return a cloned copy of the current plot, if set.
   */
  getCurrentPlot(): IPlotModel {
    const current = this.plots.value.find(p => p.isCurrent);
    if (current) {
      return this.clonePlot(current);
    }
    return null;
  }

  /**
   * Is the plot instance the current plot
   * @param plot plot instance
   */
  isCurrentPlot(plot: IPlotModel): boolean {
    const currentPlot = this.getCurrentPlot();
    if (currentPlot) {
      return currentPlot.id === plot.id;
    }
    return false;
  }

  /**
   * Set a plot instance to be the current plot.
   * @param plot plot instance to set to current plot
   */
  setCurrent(plot: IPlotModel): IPlotModel {
    const currentPlot = this.plots.value.find(p => p.isCurrent);
    let alreadyCurrentPlot = false;

    if (currentPlot && !plot) {
      currentPlot.isCurrent = false;
      this.frozenCurrentPlot = null;
    }

    if (currentPlot && plot) {
      if (currentPlot.id !== plot.id) {
        currentPlot.isCurrent = false;
      }

      alreadyCurrentPlot = currentPlot.id === plot.id;
    }

    let plotIdx = -1;
    if (plot) {
      plotIdx = this.getPlotIndex(plot);
      if (plotIdx > -1) {
        this.plots.value[plotIdx].isCurrent = true;
        this.plots.value[plotIdx].plotToolbarVisible = true;
      }
      this.setAllToolbarVisibility(this.plots.value[plotIdx]);
    }

    this.updatePlots(this.plots.value);

    if (plotIdx > -1 && !alreadyCurrentPlot) {
      this.frozenCurrentPlot = this.clonePlot(this.plots.value[plotIdx]);
    }

    return this.getCurrentPlot();
  }

  /**
   * Update a plot instance in the plots collection.
   * @param plot plot instance to update
   */
  update(plot: IPlotModel): void {
    this.updatePlotInPlots(this.clonePlot(plot));
    this.setAllCanEdit();
    this.setAllCanDuplicate();
  }

  /**
  * Remove plot instances from the plots collection and re-sequence plot area numbers
  * @param plots plot instances to delete
  * @returns array of remaining plot ids that have changed state as a result of a delete (e.g. plot area number changed due to deletion of sibling)
  */
  delete(plots: IPlotModel | IPlotModel[]): string[] {
    if (!Array.isArray(plots)) {
      plots = [plots];
    }

    plots.forEach(plot => {
      const plotIndex = this.getPlotIndex(plot);
      if (plotIndex > -1) {
        if (this.isCurrentPlot(plot)) {
          this.setCurrent(null);
        }
        this.plots.value.splice(plotIndex, 1);
      }
    });
    
    const changed = this.reSequencePlotAreaNumbers();
    this.updatePlots(this.plots.value);
    return changed;
  }

  /**
   * Delete all plots
   */
  deleteAll() {
    this.plots.next([]);
    this.frozenCurrentPlot = null;
  }

  /**
   * Add instance/s to the plots collection
   * @param plots instance or array of instances to add to the plots collection
   */
  add(plots: IPlotModel | IPlotModel[]): void {
    if (!Array.isArray(plots)) {
      plots.plotAreaNumber =  this.getNextPlotAreaNumber(this.getPlots() as []);
      plots = [plots];
    }
    plots.forEach((plot, i, plots) => {
      const idx = this.getPlotIndex(plot);
      if (idx === -1) {
        plot.valid = this.plotValidationService.isValid(plot);
        this.plots.value.push(this.clonePlot(plot));
        this.setAllCanEdit();
        this.setAllCanDuplicate();

        if (i === (plots.length -1)) {
          this.plots.next(this.plots.value);
        }
      }
    });
  }

  /**
   * Set the plotVisible property of all plots to the "visible" value
   * @param visible value to apply to plotVisible property
   */
  setPlotVisibility(visible: boolean) {
    this.plots.value.forEach((plot) => {
        plot.plotVisible = visible;
    });
    this.plots.next(this.plots.value);
  }

  /**
   * Reorder an existing plot based on the old and new plot index.
   * Return an array of plot ids that have been altered
   * @param oldPlotIdx the original index of the plot in the plots array
   * @param newPlotIndex  the new index of the plot in the plots array
   */
  reorder(oldPlotIdx: number, newPlotIndex: number): string[] {
    if (oldPlotIdx > -1 && newPlotIndex > -1 && oldPlotIdx !== newPlotIndex) {
      if (this.plots.value.length > 1) {
        if (newPlotIndex <= this.plots.value.length - 1) {
          let plot = this.plots.value[oldPlotIdx];
          if (plot) {
            plot = this.clonePlot(plot);
            this.plots.value.splice(oldPlotIdx, 1);
            this.plots.value.splice(newPlotIndex, 0, plot);
            return this.reSequencePlotAreaNumbers();
          }
        }
      }
    }
    return [];
  }

  /**
   * Replace the state of the current plot back to the original state before editing.
   * @param plot instance to reset
   */
  reset(plot: IPlotModel): void {
    const safePlot = this.frozenCurrentPlot.id === plot.id ? this.frozenCurrentPlot : null;
    if (safePlot) {
      const resetPlot = this.clonePlot(safePlot);
      // use plot area number in plots as PlotAreaNumber can be altered via basket (not reflected in frozen state)
      resetPlot.plotAreaNumber = this.getPlots([plot.id])[0].plotAreaNumber;
      this.updatePlotInPlots(resetPlot);
    }
  }

  /**
   * Determine if any plot values have changed in the plots collection.
   * If so, take another safe copy (for future comparisson) of the plots.
   * Emit any observables with changes (checkout the plotsChanged$ and currentPlotChanged$ observable
   * definitions).
   */
  checkState() {
    if (this.havePlotsChanged()) {
      this.lastEmittedPlots = this.plots.value.map(p => {
        return this.clonePlot(p);
      });
      this.emitter.next(this.emitCounter++);
    }
  }

}
