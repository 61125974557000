import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalSizeOptions, CustomModalOptions } from 'src/app/models/custom-modal-options';
import { OrderItemSummaryModel } from 'src/app/models/order-item-summary-model';
import { MapLayerValidationType } from 'src/app/models/map-layer-validation.type';
import { ModalService } from 'src/app/services/modal.service';
import { NavigateService } from 'src/app/services/navigate.service';
import { OrderService } from 'src/app/services/order.service';
import { PlotService } from 'src/app/services/plot.service';
import { filter, take } from 'rxjs/operators';
import { ModalComponent } from '../modal/modal.component';
import { BasketService } from 'src/app/services/basket.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-summary-modal',
  templateUrl: './order-summary-modal.component.html',
  styleUrls: ['./order-summary-modal.component.less']
})
export class OrderSummaryModalComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private orderSummaryCssClass = 'order-summary';
  private validationCssClass = 'validation-error';
  orderItems: OrderItemSummaryModel[] = [];
  orderSummaryModalId = 'order-summary-modal';
  validationError = false;
  isReorder = false;
  @ViewChild('basketItemsModal') basketItemsModal: ModalComponent;
  orderTitle: string = null;

  constructor(private plotService: PlotService, private modalService: ModalService, private orderService: OrderService, private navigateService: NavigateService, 
    private basketService: BasketService, private router: Router) {
    this.subscriptions.push(this.plotService.generatePlotsRequest$.subscribe(orderItems => {
        this.validationError = false;
        this.isReorder = false;
        this.orderItems = orderItems;
        this.modalService.open(this.orderSummaryModalId, { size: ModalSizeOptions.FullScreen, customCssClass: this.getCustomCssClass() } as CustomModalOptions);
      })
    );

    this.subscriptions.push(this.orderService.reorderRequest$.pipe(
      // filter: only when ALL items are valid (i.e. there is something to reorder with current permissions)
      filter(o => o.some(i => i.validationResult === MapLayerValidationType.Valid)))
        .subscribe(orderItems => {
        this.validationError = orderItems.some(i => i.validationResult !== MapLayerValidationType.Valid);
        this.isReorder = true;
        this.orderItems = orderItems;
        this.modalService.open(this.orderSummaryModalId, { size: ModalSizeOptions.FullScreen, customCssClass: this.getCustomCssClass() } as CustomModalOptions);
      })
    );
    
    this.subscriptions.push(this.plotService. deletedAllPlots$.subscribe(() => {
        this.clearTitle();
    }));

    this.subscriptions.push(this.plotService. deletedPlots$.subscribe(plots => {
      if (plots.length === 0) {
        this.clearTitle();
      }
    }));

   }

   private getCustomCssClass(): string {
    let css = this.orderSummaryCssClass;
    if (this.validationError) {
      css += ' ' + this.validationCssClass;
    }
    return css;
   }

   private getOrderId(): number {
    if (this.orderItems && this.orderItems.length > 0) {
      return parseInt(this.orderItems[0].orderId);
    }
    return -1;
  }

  private getValidOrderItemIds(): string[] {
    if (this.orderItems && this.orderItems.length > 0) {
      return this.orderItems.filter(i => i.validationResult === MapLayerValidationType.Valid).map(i => i.orderItemId);
    }
    return [];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
     sub.unsubscribe();
    });
  }

  ngOnInit(): void {
  }

  confirmOrder() {
    if (this.isReorder) {
      const orderId = this.getOrderId();
      const orderItemIds = this.getValidOrderItemIds();
      if (orderId !== -1) {
        this.orderService.reorder(orderId, orderItemIds, true, this.orderTitle);
        this.clearTitle();
      }
    } else {
      this.plotService.generate(true, this.orderTitle);
      this.modalService.close();
      this.clearTitle();
    }
    
    this.modalService.close();
  }

  navMyAccount() {
    this.navigateService.navigateToAccount();
  }

  tryEditOrder() {
    if (this.isReorder) {      
      this.basketService.hasItems().subscribe(items => {
        if (items === true) {
          this.showBasketItemsModal();
        } else if (items === false) {
          this.editOrder();
        }
      });
    }
  }

  navigateToMap() {
    this.basketService.fetchedBasketItems$.pipe(take(1)).subscribe(() => {
      // on fetch completion navigate to the map page
      this.router.navigate(['/mapping'], { state: { edit: 'true' }});
    });

    // force a re-fetch from the basket, plot service listens and runs through appropriate orchestration
    this.basketService.get();
    this.modalService.close();
    this.basketItemsModal.close();
  }

  private showBasketItemsModal() {
    this.basketItemsModal.open({ subModal: true } as CustomModalOptions);
  }

  editOrder() {
    const orderId = this.getOrderId();

    if (orderId !== -1) {
      const orderItemIds = this.getValidOrderItemIds();
      this.modalService.close();
      this.basketItemsModal.close();
      
      this.orderService.editOrder(orderId, orderItemIds).subscribe(result => {
        if (result) {
          if (result.success) {
            this.navigateToMap();
          }
        }
      });
    }
  }

  clearTitle() {
    this.orderTitle = null;
  }
}