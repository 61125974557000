import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StatutoryAccessModel } from '../models/statutory-access.model';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ServiceErrorHandler } from '../shared/service-error-handler';
import { IStatutoryAccessService } from '../interfaces/IStatutoryAccessService';
import { MapMetaService } from './map-meta.service';

@Injectable({
  providedIn: 'root'
})

export class StatutoryAccessService implements IStatutoryAccessService {
  private userApiUrl: string;

  constructor(private http: HttpClient, private errorHandler: ServiceErrorHandler, private mapMetaService: MapMetaService) {
    this.userApiUrl = environment.userApiUrl;
  }

  getUserStatutoryAccess(): Observable<StatutoryAccessModel[]> {
    const apiAction = '/GetUserStatutoryAccess';
    return this.http.get<StatutoryAccessModel[]>(this.userApiUrl + apiAction).pipe(
      map(accesses => {
        if (accesses) {
          accesses = accesses.map(a => {
            a.provider = this.mapMetaService.getAssetLayerProvider(a.supplier).name;
            return a;
          });
        } else {
          accesses = [];
        }
        return accesses.sort((a, b) => a.provider.localeCompare(b.provider));
      }),
      catchError(this.errorHandler.handleError)
    );
  }
}
