<ng-container>
    <div class="glossary-item">
        <div class="glossary-title">
            <ng-content select="[title]"></ng-content>
        </div>
        <div class="glossary-content">
            <ng-content select="[body]"></ng-content>
        </div>
    </div>
</ng-container>
