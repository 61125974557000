import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomModalOptions } from 'src/app/models/custom-modal-options';
import { LogoutReason } from 'src/app/models/logout-reason';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NavigateService } from 'src/app/services/navigate.service';
import { PlotService } from 'src/app/services/plot.service';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-invalid-basket-modal',
  templateUrl: './invalid-basket-modal.component.html',
  styleUrls: ['./invalid-basket-modal.component.less']
})
export class InvalidBasketModalComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private invalidIds: string[] = [];
  @ViewChild('modal', { static: false }) modal: ModalComponent;
  
  constructor(private navigateService: NavigateService, private plotService: PlotService, private authService: AuthenticationService) {
    this.subscriptions.push(this.plotService.invalidBasketFetch$.subscribe(invalidIds => {
      if (invalidIds && invalidIds.length > 0) {
        this.invalidIds = invalidIds;
        this.modal.open({ hideClose: true } as CustomModalOptions);
      }
    }));
   }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
     s.unsubscribe();
    });
  }

  navMyAccount(e: Event) {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.navigateService.navigateToAccount();
  }

  logout() {
    this.modal.close();
    this.authService.logout(LogoutReason.Click);
  }

  deleteInvalidPlots() {
    this.plotService.deleteSome(this.invalidIds);
    this.modal.close();
  }
}
