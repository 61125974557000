import { WmtsParameters } from './map-wmts.model';

export class MapConfiguration {
    defaultZoom: number | undefined;
    apiUrl: string;
    maxZoom: number | undefined;
    minZoom: number | undefined;
    defaultBounds: L.LatLngBoundsLiteral | undefined;
    wmtsParams: WmtsParameters;
    attrPrefix: string;
    attrCopyright: string;
    licenceNumber: string;
}
