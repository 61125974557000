import { Component, Input, OnInit } from '@angular/core';
import { OrderItemSummaryModel } from 'src/app/models/order-item-summary-model';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['../order-summary-modal.component.less']
})
export class OrderSummaryComponent implements OnInit {
  @Input () orderItems: OrderItemSummaryModel[];
  @Input () validationError: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
