import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, filter, map, take, tap } from 'rxjs/operators';
import { IPlotModel } from 'src/app/interfaces/IPlotModel';
import { IUserModel } from 'src/app/interfaces/IUserModel';
import { HeaderModel } from 'src/app/models/header.model';
import { NotificationModel } from 'src/app/models/notification.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MapService } from 'src/app/services/map.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PlotService } from 'src/app/services/plot.service';
import { SidebarHandlerService } from 'src/app/services/sidebar-handler.service';
import { UserService } from 'src/app/services/user.service';
import { LogoutReason } from '../models/logout-reason';
import { SidebarMenuItemType } from '../models/sidebar-item-type';
import { NavigateService } from '../services/navigate.service';
import { UrlHelperService } from '../services/url-helper-service';
import { WindowResizeService } from '../services/window-resize.service';
import { NavItemDirective } from '../shared/nav-item.directive';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit  {
  private subscriptions: Subscription[] = [];
  private mobileBreakpoint = 1024;
  private windowResizeWait = 500;
  private lastWindowSize = 0;
  private mobileMenuOpenCss = 'mobile-menu-open';
  headerModel = new HeaderModel();
  isMapPage = false;
  plots: IPlotModel[] = [];
  notifications: NotificationModel[] = [];
  basketLoaded = false;
  navigating = false;
  userInitials$: Observable<string> = of('');
  @ViewChildren(NavItemDirective) menuItems: QueryList<NavItemDirective>;
  showMobileMenu = false;
  resizing = false;
  
  constructor(private authService: AuthenticationService, private mapService: MapService, private changeDetectorRef: ChangeDetectorRef,
    private plotService: PlotService, private notificationService: NotificationService, private router: Router,
    private sidebarHandlerService: SidebarHandlerService, private userService: UserService, private windowResizeService: WindowResizeService,
    private navigateService: NavigateService, private urlHelperService: UrlHelperService) {

      this.userInitials$ = this.userService.userDetail$.pipe(
        map(userDetail => {
          if (userDetail) {
            let initials = '';
            if (userDetail.firstName && userDetail.firstName.length > 0) {
              initials = userDetail.firstName.substring(0, 1);
              if (userDetail.lastName && userDetail.lastName.length > 0) {
                initials += userDetail.lastName.substring(0, 1);
              }
              return initials;
            }
          }
          return null;
        })
      );
  }

  ngAfterContentInit(): void {
    this.setMenuItemActive(this.router.url);
  }

  ngAfterViewInit(): void {
    this.menuItems.forEach(menuItem => {
      this.subscriptions.push(menuItem.changed.subscribe(() => {
        if (menuItem.expanded) {
          this.closeAllMenus(menuItem);
        }
      }));
    });
    this.setTouchEnabled();
  }

  private setTouchEnabled() {
    const touchEnabled = window.innerWidth <= this.mobileBreakpoint;
    this.menuItems.forEach(m => {
      m.touchEnabled = touchEnabled;
    });
  }

  private closeAllMenus(except?: NavItemDirective) {
    this.menuItems.forEach(m => {
      if (!except) {
        m.close();
      } else if (except.uniqueId !== m.uniqueId) {
        if (m.expanded) {
          m.close();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
      if (s) {
        s.unsubscribe();
      }
    });
  }

  ngOnInit() {
    this.resetHeaderState();

    this.subscriptions.push(this.authService.currentUser$.subscribe((user) => {
        this.setUser(user);
      })
    );
      
    this.subscriptions.push(this.plotService.basketLoaded$.subscribe(loaded => {
      this.basketLoaded = loaded;
      this.changeDetectorRef.detectChanges();
    }));

    this.subscriptions.push(this.mapService.initialised$.subscribe(() => {
        // TODO sub within a sub?  Not sure that is right...
        this.addMapVisibleSubscription();
      })
    );

    this.subscriptions.push(this.plotService.plotsChanged$.subscribe(plots => {
          this.plots = [...plots];
          this.changeDetectorRef.detectChanges();
      })
    );

    this.subscriptions.push(this.notificationService.notifications$.subscribe(notifications => {
        this.notifications = notifications;
      })
    );
  
    this.subscriptions.push(this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(() => {
      this.navigating = true;
      this.resetMenuState();
      })
    );

    this.subscriptions.push(this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(e => {
        setTimeout(() => {
          this.navigating = false;
          this.closeAllMenus();
          this.setMenuItemActive(this.urlHelperService.getPathFromUrl((e as RouterEvent).url));
        }, 1);
      })
    );

    // hide mobile menu if resized larger than mobile size (e.g. desktop/orientation change)
    this.windowResizeService.width$.pipe(debounceTime(this.windowResizeWait)).subscribe(width => {
        if (this.showMobileMenu && this.lastWindowSize < width && width > this.mobileBreakpoint) {
          this.resetMenuState();
        }
        this.lastWindowSize = width;
        this.setTouchEnabled();
      }
    );

    // hide menu on resize as orientation change catches out mobile menu and skews page width
    this.windowResizeService.width$.subscribe(() => {
      this.resizing = true;

      setTimeout(() => {
       this.resizing = false;
      }, 200);
    });
  }

  /**
   * Set active state of menu items
   * @param url
   */
  private setMenuItemActive(url: string) {
    const path = this.urlHelperService.getPathFromUrl(url);
    this.menuItems.forEach(i => i.setActive(false));
    const pathItem = this.menuItems.find(i => this.itemPathMatchesPath(path, i));
    if (pathItem) {
      pathItem.setActive(true);
    }
  }
  
  private itemPathMatchesPath(path: string, menuItem: NavItemDirective): boolean {
    if (path && path.length > 0) {
      if (menuItem.path) {
        const paths = menuItem.path.split('|');
        return paths.some(p => p.toLowerCase() === path.toLowerCase());
      }
    }
    return false;
  }

  private resetMenuState() {
    this.showMobileMenu = false;
    this.closeAllMenus();
    this.toggleMobileMenuClass();
  }

  logout(e: Event) {
    e.stopImmediatePropagation();
    e.preventDefault();
    this.authService.logout(LogoutReason.Click);
  }

  editBasket(e: Event) {
    e.stopImmediatePropagation();
    e.preventDefault();
    if (this.isMapPage) {
      this.sidebarHandlerService.setMenuItem(SidebarMenuItemType.CurrentPlots);
    } else {
      this.router.navigate(['/mapping'], { state: { edit: 'true' }});
    }
  }

  addNewPlot(e: Event) {
    e.stopImmediatePropagation();
    e.preventDefault();
    this.plotService.create();
    if (!this.isMapPage) {
      this.router.navigate(['/mapping'], { state: { create: 'true' }});
    }
  }

  generatePlots(e: Event) {
    e.stopImmediatePropagation();
    e.preventDefault();
    this.plotService.generate();
  }

  dismissNotification(event$: any) {
    event$.event.stopImmediatePropagation();
    event$.event.stopPropagation();
    this.notificationService.dismiss(event$.id);
  }

  toggleMobileMenu(e: Event){
    e.preventDefault();
    e.stopImmediatePropagation();
    this.showMobileMenu = !this.showMobileMenu;

    if (this.showMobileMenu) {
      this.closeAllMenus();
    }

    this.toggleMobileMenuClass();
  }

  /**
   * Add or remove css class to body in order to drive CSS behavior.  e.g. document overflow if menu open
   */
  private toggleMobileMenuClass() {
    const body = document.getElementsByTagName('body')[0];

    if (body) {
      if (this.showMobileMenu) {
        body.classList.add(this.mobileMenuOpenCss);
      } else if (body.classList.contains(this.mobileMenuOpenCss)) {
        body.classList.remove(this.mobileMenuOpenCss);
      }
    }
  }

  navPrivacyPolicy(e: Event) {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.navigateService.navigateToPrivacyPolicy();
  }

  navTermsUse(e: Event) {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.navigateService.navigateToTermsUse();
  }

  private addMapVisibleSubscription() {
    this.subscriptions.push(this.mapService.isMapVisible$.subscribe(visible => {
        this.isMapPage = visible;
        this.changeDetectorRef.detectChanges();
      })
    );
  }

  private resetHeaderState() {
    this.headerModel = new HeaderModel();
  }

  private setUser(user: IUserModel) {
    if (user) {
      if (user.accessToken) {
        this.headerModel.isAuthenticated = true;
        this.headerModel.userName = user.userName;
        this.userService.getUserDetail();
        return;
      }
    }
    this.resetHeaderState();
  }

}
