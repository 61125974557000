import { Component, OnInit, OnDestroy } from '@angular/core';
import { SpinnerService } from '../../services/spinner.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.less']
  })

  export class SpinnerComponent implements OnInit, OnDestroy {
    private spinnerSvcSub: Subscription;
    message = '';
    show = false;
    dev = false;

    constructor(private spinnerService: SpinnerService) {
      if (window.location.origin.indexOf('localhost') > -1) {
        // this.dev = true;
      }
    }

    ngOnInit() {
      this.spinnerSvcSub = this.spinnerService.visibleChanged$.subscribe((change) => {
        this.show = change.visible;
        this.message = this.show ? change.message : '';
      });
    }

    ngOnDestroy() {
      this.spinnerSvcSub.unsubscribe();
    }
  }
