import { Injectable } from '@angular/core';

import { MapConfiguration } from '../models/map-configuration.model';
import mapConfigData from '../../config/map-configuration.json';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapConfigurationService {
  private configuration: MapConfiguration;

  constructor() { 
    this.configuration = (mapConfigData as any) as MapConfiguration;
  }

  getConfiguration(): Observable<MapConfiguration> {
    this.configuration.attrCopyright = this.configuration.attrCopyright.replace('YYYY', new Date().getFullYear().toString());
    return of(Object.assign(new MapConfiguration(), this.configuration));
  }

}
