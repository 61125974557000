import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, concatMap, map, switchMap, take } from 'rxjs/operators';
import { ServiceErrorHandler } from '../shared/service-error-handler';
import { MapService } from './map.service';
import { SpinnerService } from './spinner.service';
import { PrintPreviewModel } from '../models/print-preview.model';
import { IPlotChange } from '../interfaces/IPlotChange';
import { IPlotService } from '../interfaces/IPlotService';
import { IPlotModel } from '../interfaces/IPlotModel';
import { IDuplicatePlotModel } from '../interfaces/IDuplicatePlotModel';
import { PlotHelperService } from './plot-helper.service';
import { SearchService } from './search.service';
import { PlotAssetLayerService } from './plot-asset-layer.service';
import { SearchResult } from '../models/search-result.model';
import { PlotMapperService } from './plot-mapper.service';
import { PlotStateService } from './plot-state.service';
import { OrderItemSummaryModel } from '../models/order-item-summary-model';
import { BasketService } from './basket.service';
import { PlotSettingsService } from './plot-settings.service';
import { AuthenticationService } from './authentication.service';
import { OrderService } from './order.service';
import { OrderResultModel } from '../models/order-result.model';
import { MapLayerValidationType } from '../models/map-layer-validation.type';
import { IAssetLayerModel } from '../interfaces/IAssetLayerModel';

@Injectable({
  providedIn: 'root'
})

export class PlotService implements IPlotService {
  private userApiUrl: string;
  private plotApiUrl: string;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  // Collection of all plots in the current "session" (current plots - sidebar menu item)
  plotsChanged$ = this.plotStateService.plotsChanged$;

  // The current plot.  The plot that is currently being created/edited
  currentPlotChanged$ = this.plotStateService.currentPlotChanged$;

  // The plot that has just been deleted
  private deletedPlots = new Subject<IPlotModel[]>();
  deletedPlots$ = this.deletedPlots;

  private deletedAllPlots = new Subject<void>();
  deletedAllPlots$ = this.deletedAllPlots;

  private deletePlotRequest = new Subject<IPlotModel>();
  deletePlotRequest$ = this.deletePlotRequest.asObservable();

  private deleteAllPlotRequest = new Subject<void>();
  deleteAllPlotRequest$ = this.deleteAllPlotRequest.asObservable();

  private printPreviewLink = new Subject<string>();
  printPreview$ = this.printPreviewLink.asObservable();

  // Get user to save or undo changes to current plot
  private currentPlotRequest = new Subject<IPlotChange>();
  currentPlotRequest$ = this.currentPlotRequest.asObservable();

  private savedPlot = new Subject<IPlotModel>();
  savedPlot$ = this.savedPlot.asObservable();

  private createdPlot = new Subject<IPlotModel>();
  plotCreated$ = this.createdPlot.asObservable();

  private generatePlotsRequest = new Subject<OrderItemSummaryModel[]>();
  generatePlotsRequest$ = this.generatePlotsRequest.asObservable();

  private basketLoaded = new Subject<boolean>();
  basketLoaded$ = this.basketLoaded.asObservable();

  private invalidBasketFetch: Subject<string[]> = new Subject<string[]>();
  invalidBasketFetch$ = this.invalidBasketFetch.asObservable();

  constructor(private http: HttpClient, private errorHandler: ServiceErrorHandler, private mapService: MapService,
              private spinnerService: SpinnerService, private plotHelperService: PlotHelperService,
              private searchService: SearchService,
              private plotAssetLayerService: PlotAssetLayerService,
              private plotMapperService: PlotMapperService,
              private plotStateService: PlotStateService,
              private basketService: BasketService,
              private plotSettingsService: PlotSettingsService,
              private authService: AuthenticationService,
              private orderService: OrderService) {
    this.userApiUrl = environment.userApiUrl;
    this.plotApiUrl = environment.plotApiUrl;
    this.subscribeToSearchResult();
    this.subscribeToBasket();
    this.subscribeToUserAuth();
  }

  private subscribeToBasket() {
    this.basketService.fetchedBasketItems$.subscribe(plots => {
      this.addBasketFetchToPlotState(plots);
    });
  }

  private subscribeToUserAuth() {
    this.authService.currentUser$.subscribe(user => {
      if (user) {
        this.basketService.get();
      }
    });
  }

  /**
   * Get plot ids for plots with asset layers, with (map) layers which have a non-valid basket validation value
   * @param plots plots with basket validation
   * @returns array of plot/basket ids of items which have failed basket validation
   */
  private getBasketValidationFailures(plots: IPlotModel[]): string[] {
    return plots.reduce((result: string[], plot) => {
      if (plot.assetLayers.some(ass => ass.layers.some(l => l.basketValidation !== MapLayerValidationType.Valid && l.selected))) {
        result.push(plot.id);
      }
      return result;
    }, []);
  }

  /**
   * If any plots fail basket validation then emit invalid basket fetch
   * @param plots plots with basket validation
   * @returns if plots have failed basket validation
   */
  private validateBasketFetch(plots: IPlotModel[]): boolean {
    const invalidIds = this.getBasketValidationFailures(plots);
    if (invalidIds && invalidIds.length > 0) {
      this.invalidBasketFetch.next(invalidIds);
      return false;
    }
    return true;
  }

   /**
   * Add items from basket service fetch and update plot state service
   */
  private addBasketFetchToPlotState(plots: IPlotModel[]) {
    this.deleteAllFromPlotState();
    const basketValid = this.validateBasketFetch(plots);
    this.plotStateService.add(plots);
    this.setCurrentAndPositionMap(basketValid);
    this.plotStateService.checkState();
    this.basketLoaded.next(true);
  }

  private setCurrentAndPositionMap(basketValid: boolean) {
    // if basket is not valid then don't reposition map, etc.
    // rely on the user to delete invalid plots and the subsequent basket fetch will reposition map, etc.
    try {
      if (basketValid) {
        const plots = this.plotStateService.getPlots();
        if (plots.length > 0) {
          const unsaved = plots.filter(p => !p.isCreated);
          if (unsaved.length > 0) {
            this.setCurrent(unsaved[0]);
          } else {
            this.recentre(plots[plots.length-1]);
          }
        }
      }
    } catch {
    }
  }

  private subscribeToSearchResult() {
    this.searchService.resultSelected$.subscribe((result: SearchResult) => {

      // if the current plot is NOT created then reposition the plot to the new search result x/y
      const currentPlot = this.plotStateService.getCurrentPlot();
      if (currentPlot && result) {
        if (!currentPlot.isCreated) {
          currentPlot.x = result.longitude;
          currentPlot.y = result.latitude;
          this.update(currentPlot);
          const bounds = this.plotHelperService.getBoundsForPlot(currentPlot);
          if (bounds) {
            this.mapService.fitBounds(bounds);
          }
        }
      }
    });
  }

  /**
   * Return a default instance of the plot model.
   */
  private getDefaultPlot(): Observable<IPlotModel> {
    const apiAction = '/GetDefaultPlot/';
    return this.http.get<IPlotModel>(this.userApiUrl + apiAction).pipe(
      map(data => {
        data.y = this.mapService.getCenter().lat;
        data.x = this.mapService.getCenter().lng;
        data.paperSizes = this.plotSettingsService.getPaperSizes();
        data.printPreviewPaperSizes = this.plotSettingsService.getPrintPreviewPaperSizes();
        data.printScales = this.plotSettingsService.getPrintScales();
        const model = this.plotMapperService.mergeToPlotModel(data, data);
        model.isCreated = false;
        return model;
      }),
      catchError(this.errorHandler.handleError)
    );
  }

  private reallyDelete(plot: IPlotModel) {
    plot = this.plotStateService.getPlot(plot.id);
    this.basketService.delete(plot).subscribe(success => {
      if (success) {
        const changed = this.plotStateService.delete(plot);
        this.plotStateService.checkState();

        // update remaining plots if any (e.g. plot area number could have been re-sequenced)
        if (changed && changed.length > 0) {
          const changedPlots = this.plotStateService.getPlots(changed).filter(p => p.id !== plot.id);
          if (changedPlots.length > 0) {
            this.basketService.updatePlotAreaNumbers(this.plotStateService.getPlots(changed) as IPlotModel[]).subscribe();
          }
        }
        this.deletedPlots.next([plot]);
      }
    });
  }

  private reallyDeleteAll(): void {
    this.basketService.deleteAll().subscribe(success => {
        if (success) {
          this.deleteAllFromPlotState();
        }
      }
    );
  }

  /**
   * Remove all plots from state and emit deletedAll
   */
  private deleteAllFromPlotState() {
    this.plotStateService.deleteAll();
    this.plotStateService.checkState();
    this.deletedAllPlots.next();
  }

  /**
   * If there is already a plot defined in the "session"
   * then apply the last added plot's relevant values
   * @param plot the plot to apply defaults to
   */
  private applyLastPlotDefaults(plot: IPlotModel) {
    const plots = this.plotStateService.getPlots();
    if (plots.length > 0) {
      this.applyPlotDefaults(plot, plots.length - 1);
    }
  }

  /**
   * Apply the source plot's default properties to the target plot
   * @param targetPlot the target plot to apply defaults to
   * @param sourcePlotIndex the plot index to get default values from
   */
  private applyPlotDefaults(targetPlot: IPlotModel, sourcePlotIndex: number): void {
    const plots = this.plotStateService.getPlots();
    if (plots.length > 0) {
      const sourcePlot = plots[sourcePlotIndex];
      targetPlot.paperSize = sourcePlot.paperSize;
      targetPlot.printScale = sourcePlot.printScale;
      targetPlot.landscape = sourcePlot.landscape;
      targetPlot.mono = sourcePlot.mono;
    }
  }

  /**
   * Apply duplication proeprties to a plot instance (e.g. x, y, plot title)
   * @param targetPlot the target plot to apply property values to
   * @param duplication duplication descriptor object
   */
  private applyDuplicateProperties(targetPlot: IPlotModel, duplication: IDuplicatePlotModel): void {
    const sourcePlot = this.plotStateService.getPlot(duplication.plotId);
    if (sourcePlot) {
      targetPlot.x = duplication.latLng.lng;
      targetPlot.y = duplication.latLng.lat;
      targetPlot.plotTitle = sourcePlot.plotTitle;
    }
  }

  /**
   * Determine if any of the plot properties which determine the plot bounds have changed
   * @param source plot
   * @param target plot
   */
  private havePlotBoundsChanged(source: IPlotModel, target: IPlotModel): boolean {
    if (source && target) {
      if (source.printScale !== target.printScale ||
        source.paperSize !== target.paperSize ||
        source.landscape !== target.landscape
        ) {
          return true;
        }
    }

    if (source.x && source.y) {
      if (source.x !== target.x ||
        source.y !== target.y){
          return true;
      }
    }
    return false;
  }

  /**
   * Update asset layers and layers if the bounds of the plot have changed
   * @param plot the plot in the plots collection
   * @param boundsChanged have the plot bounds changed
   */
  private refreshAssetLayers(plot: IPlotModel, boundsChanged: boolean) {
    // change asset layers updating
    if ((boundsChanged || plot.orderEdit) && this.plotStateService.isCurrentPlot(plot)) {
      plot.assetLayersUpdating = true;
      this.plotStateService.update(plot);
      this.plotStateService.checkState();
    }

    return this.plotAssetLayerService.refreshAssetLayers(plot, boundsChanged).pipe(
      take(1),
      map(assetLayers => {
        plot.assetLayers = assetLayers;
        plot.assetLayersUpdating = false;
        this.plotStateService.update(plot);
        return plot;
      })
    );
  }

/**
 * Orchestrate state changes to clear down an order after completion
 * @param result order object returned after creating order
 */
  private clearPlots(result: OrderResultModel) {
    if (result && result.orderId) {
      this.basketService.update([]);
      this.plotStateService.deleteAll();
      this.plotStateService.checkState();
    }
  }

  /**
   * If duplication is defined, return a duplicated asset layers modal array
   * @param duplication model
   * @returns new, duplicate asset layers model array
   */
  private getDuplicateAssetLayers(duplication?: IDuplicatePlotModel): IAssetLayerModel[] {
    let sourceAssetLayers: IAssetLayerModel[];

    if (duplication) {
      const sourcePlot = this.plotStateService.getPlot(duplication.plotId);
      sourceAssetLayers = [...sourcePlot.assetLayers];
    }

    return sourceAssetLayers;
  }

  /**
   * Set an existing instance of the current Plot model back to the original state
   */
  reset(plot: IPlotModel): void {
    this.plotStateService.reset(plot);
    this.setCurrent(plot, true);
  }

  /**
   * Update a plot in the in-memory plots array, merge the properties of the model with the existing plot properties
   * @param updatedPlot the updated plot model
   * @param forceRefresh if true then force refresh of asset layers (ignore bounds changing)
   */
  update(updatedPlot: IPlotModel, forceRefresh?: boolean): void {
    if (updatedPlot) {
      const storedPlot = this.plotStateService.getPlot(updatedPlot.id);
      updatedPlot = this.plotMapperService.mergeToPlotModel(storedPlot, updatedPlot);
      const boundsChanged = forceRefresh && forceRefresh === true ? forceRefresh : this.havePlotBoundsChanged(updatedPlot, storedPlot);

      this.refreshAssetLayers(updatedPlot, boundsChanged).subscribe(updatedPlot => {
        this.plotStateService.update(updatedPlot);
        this.plotStateService.checkState();
        if (!storedPlot.plotToolbarVisible && updatedPlot.plotToolbarVisible) {
          this.recentre(updatedPlot);
        }
        if (boundsChanged && updatedPlot.isCreated && !this.plotStateService.isCurrentPlot(updatedPlot)) {
          this.basketService.update([updatedPlot]).subscribe();
        }
      });
    }
  }

  /**
   * Delete the plot from the Plots collection
   * @param plot The plot to delete
   * @param really Optional, if true then don't prompt user before deletion
   */
  delete(plot: IPlotModel, really?: boolean): void {
    if (really === true) {
      this.reallyDelete(plot);
    } else {
      this.deletePlotRequest.next(plot);
    }
  }

  deleteAll(really?: boolean): void {
    if (really === true) {
      this.reallyDeleteAll();
    } else {
      this.deleteAllPlotRequest.next();
    }
  }

  /**
   * Remove a collection of basket items from the basket and from in-memory plot state, update the remaining basket items with re-sequenced plot area numbers
   * @param basketItemIds basket item (plot) ids to remove from basket
   */
  deleteSome(basketItemIds: string[]): void {
    const plotsToDelete = this.plotStateService.getPlots(basketItemIds) as [];

    this.basketService.deleteSome(basketItemIds).pipe(
      switchMap(success => {
        if (success) {
          this.plotStateService.delete(plotsToDelete);
          return this.basketService.updatePlotAreaNumbers(this.plotStateService.getPlots() as []);
        }
        return of(success);
      })
    ).subscribe(success => {
      if (success) {
        this.deletedPlots.next(plotsToDelete);
        this.basketService.get();
      }
    });

  }

  /**
   * Save changes to a plot instance update returned value from API
   * @param plot plot instance to save
   */
  save(plot: IPlotModel): Observable<void> {
    // merge in-memory plot with the model as properties of the plot are distributed and not encapsulated in every component/form
    const storedPlot = this.plotStateService.getPlot(plot.id);
    plot = this.plotMapperService.mergeToPlotModel(storedPlot, plot);
    return this.basketService.add(plot).pipe(
      map(success => {
        if (success) {
          plot.isDirty = false;
          plot.plotToolbarVisible = false;
          plot.isCreated = true;
          this.setCurrent(null);
          this.plotStateService.update(plot);
          this.plotStateService.checkState();
          plot = this.plotStateService.getPlot(plot.id);
          this.basketService.update([plot]).subscribe(() => {
              this.savedPlot.next(plot);
            }
          );
        }
      })
    );
  }

  /**
   * Set visibility on all plots within the plots collection
   * @param visible visibility value
   */
  setPlotVisibility(visible: boolean) {
    this.plotStateService.setPlotVisibility(visible);
    this.plotStateService.checkState();
  }

  /**
   * Generate a pdf url which points to the print preview Plot pdf
   * @param model Plot to create a print preview of.
   */
  printPreview(model: IPlotModel): void {
    this.spinnerService.setMessage('Processing Print Preview');
    const plot = this.plotStateService.getPlot(model.id);
    const request = this.plotHelperService.toPrintPreviewRequest(plot);
    const apiAction = '/PrintPreview/';
    this.http.post<PrintPreviewModel>(this.plotApiUrl + apiAction, JSON.stringify(request), this.httpOptions).pipe(
      data => data,
      catchError(err => {
          this.errorHandler.handleError(err);
          return of(null);
          }
      )
    ).subscribe(data => {
      if (data) {
        if (data.url) {
          this.printPreviewLink.next(data.url);
          return;
        }
      }
      this.printPreviewLink.next(null);
    });
  }

  /**
   * Set the required plot to be the current one.  Set the isCurrent property to true and make any other plots isCurrent false.
   * If model is null then unset the current plot.
   * If there is already a current plot and the plot is dirty then prompt to get the "really" param set.
   * @param plot plot to make the "currentPlot" (isCurrent = true)
   * @param really Optional, if true then don't prompt user to confirm unsaved changes
   */
  setCurrent(plot: IPlotModel, really?: boolean) {
    if (!plot) {
      this.plotStateService.setCurrent(null);
      this.plotStateService.checkState();
      return;
    } else if (really) {
      plot = this.plotStateService.setCurrent(plot);
    } else {
      const isPlotCurrent = this.plotStateService.isCurrentPlot(plot);

      if (isPlotCurrent) {
        this.recentre(plot);
      }

      const currentPlot = this.plotStateService.getCurrentPlot();
      if (!isPlotCurrent && currentPlot) {
        // current plot is already set and has been made dirty OR is new/unsaved . Confirm save/undo changes via the "really" param.
        if (currentPlot.isDirty || !currentPlot.isCreated) {
          this.currentPlotRequest.next({ source: currentPlot, target: plot} as IPlotChange);
          return;
        }
      }

      plot = this.plotStateService.setCurrent(plot);
    }
    
    this.refreshAssetLayers(plot, true).subscribe(plot => {
      plot.orderEdit = false; // only relevant on first edit whilst missing layers are applied
      this.plotStateService.update(plot);
      this.plotStateService.checkState();
    });
  }

  /**
   * Move a plot within the Plots collection from the old to the new position and update the Plot area numbers of
   * all affected plots.
   * @param oldPlotIdx the original Plot index in the Plots collection
   * @param newPlotIndex the new Plot index in the Plots collection
   */
  reorder(oldPlotIdx: number, newPlotIndex: number) {
    const changed = this.plotStateService.reorder(oldPlotIdx, newPlotIndex);
    this.plotStateService.checkState();
    if (changed && changed.length > 0) {
      this.basketService.updatePlotAreaNumbers(this.plotStateService.getPlots(changed) as IPlotModel[]).subscribe();
    }
  }

  /**
   * Recentre a plot in the viewable map area
   * @param plot the plot to centre
   */
  recentre(plot: IPlotModel) {
    this.mapService.fitBounds(this.plotHelperService.getBoundsForPlot(plot));
  }

  /**
   * Create a new "blank" instance of the Plot model and set it to be the current plot.
   * Unless the current plot isn't yet "created", in which case return the current plot.
   */
  create(): void {
    const currentPlot = this.plotStateService.getCurrentPlot();
    if (currentPlot) {
      if (!currentPlot.isCreated) {
        return;
      }
    }

    this.createPlotAndSave();
  }

  /**
   * If the last plot exists and the new plot center is within it's bounds, offset the new plot longitude
   */
  private offsetPlotIfWithinLastPlotBounds(newPlot: IPlotModel) {
    const plots = this.plotStateService.getPlots();
    if (plots && plots.length > 0) {
      const lastPlot = plots[plots.length-1];
      if (lastPlot.id !== newPlot.id) {
        if (this.plotHelperService.containsPlotCenter(lastPlot, newPlot)) {
          this.plotHelperService.shiftLongitudeOnly(lastPlot, newPlot);
        }
      }
    }
  }

  /**
   * Create a new plot by getting a default plot with user preferences applied.
   * Apply last plot values & get Asset Layers for new plot area (based on boundary coordinates)
   * Add to plots collection and save in basket.
   * @param duplication optional model to use a template for new plot. 
   */
  private createPlotAndSave(duplication?: IDuplicatePlotModel): void {
    const sourceAssetLayers = this.getDuplicateAssetLayers(duplication);

     // first call the gateway to get a default plot with id and preferences applied
     this.getDefaultPlot().pipe(
      map(plot => {
        if (duplication) {
          this.applyPlotDefaults(plot, this.plotStateService.getPlotIndex({ id: duplication.plotId } as IPlotModel));
          this.applyDuplicateProperties(plot, duplication);
        } else {
          this.applyLastPlotDefaults(plot);
          this.offsetPlotIfWithinLastPlotBounds(plot);
        }
        return plot;
      })
    ).pipe(
       concatMap(plot => {
           // then call the asset layer service for the asset layers in the plot bounds
          return this.plotAssetLayerService.getAssetLayersForNewPlot(plot, sourceAssetLayers).pipe(
            map(assetLayers => {
              plot.assetLayers = assetLayers;
              plot.plotToolbarVisible = true;
              this.plotStateService.add(plot);
              plot = this.plotStateService.setCurrent(plot);
              this.plotStateService.checkState();
              this.recentre(plot);
              return plot;
            })
          );
         }
      ),
      concatMap(plot => {
        // store the unplaced plot in the basket to support reordering
        return this.basketService.add(plot).pipe(
          map(success => {
            if (success) {
              this.createdPlot.next(plot);
            }
            return;
          }
        ));
      })
    ).subscribe();
  }

  /**
   * Create a duplicate of an existing plot and set as current plot
   * Duplicate paper size, print scale, orientation, x & y and mono
   * @param duplication plot to make a duplicate of and coordinates to use for x & y values
   */
  duplicate(duplication: IDuplicatePlotModel): void {
    this.createPlotAndSave(duplication);
  }

  generate(really?: boolean, orderTitle?: string): void {
    if (really) {
     this.orderService.create(orderTitle).subscribe(result => {
      this.clearPlots(result);
     });
    } else {
      this.generatePlotsRequest.next(this.plotHelperService.toOrderItemSummaries(this.plotStateService.getPlots() as IPlotModel[]));
    }
  }
}
