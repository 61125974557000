import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SearchService } from 'src/app/services/search.service';
import { SearchResult } from 'src/app/models/search-result.model';
import { MapService } from 'src/app/services/map.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-geocoder',
    templateUrl: './geocoder.component.html',
    styleUrls: ['./geocoder.component.less']
})

export class GeocoderComponent implements OnInit, OnDestroy {
    private searchCompletedSub: Subscription;
    private searchStartingSub: Subscription;
    private searchChangeSub: Subscription;
    private errorSearchText = 'error';
    private allowedSearchRegex = /[\w\s\,\-\!\'\.\’]/gm;
    private notAllowedSearchRegex = /[^\w^\s^\,^\-^\!^\'\.\’]/gm;
    private previousResultMax = 3;
    @ViewChild('geocoderSearch') geocoderSearch: ElementRef;
    geocoderSearchForm: UntypedFormGroup;
    showResultsContainer = false;
    errorMessage = '';
    resultsCount = -1;
    results: SearchResult[] = [];
    previousSearches: SearchResult[] = [];
    state = '';
    showPreviousSearches = false;

    constructor(private formBuilder: UntypedFormBuilder, private searchService: SearchService,
                private mapService: MapService) {
    }

    private get formControls() { return this.geocoderSearchForm.controls; }

    private displayNoResults() {
        this.resultsCount = 0;
        this.errorMessage = '';
        this.showResultsContainer = true;
        this.formControls.geocoderSearch.setErrors({ 'no results': true });
        this.setState();
    }

    private displayErrorMessage() {
        this.clearDownResults();
        this.resultsCount = 0;
        this.errorMessage = 'error';
        this.showResultsContainer = true;
        this.setState();
    }

    private displayResults(results: SearchResult[]) {
        this.resultsCount = results.length;
        this.results = results;
        this.showResultsContainer = true;
        this.setState();
    }

    displayPreviousSearches() {
        if (this.hasSearchInputGotFocus()) {
            if (this.previousSearches.length > 0) {
                this.showPreviousSearches = true;
                this.showResultsContainer = true;
            }
        }
    }

    private removeResultFromPreviousSearches(result: SearchResult) {
        const prevIdx = this.previousSearches.findIndex(p => p.displayIdentifier === result.displayIdentifier);
        if (prevIdx > -1) {
            this.previousSearches.splice(prevIdx, 1);
        }
    }

    private addToPreviousSearches(result: SearchResult) {
        this.removeResultFromPreviousSearches(result);

        this.previousSearches.unshift(result);
        if (this.previousSearches.length > this.previousResultMax) {
            this.previousSearches.pop();
        }
    }

    private searchCompleted(results: SearchResult[]) {
        if (results) {
            if (results.length > 0) {
                if (results.length === 1) {
                    this.setSearchResult(results[0], null);
                } else {
                    this.displayResults(results);
                }
                return;
            }
        }

        this.resetSearch();
    }

    setSearchResult(result: SearchResult, e: Event) {
        if (result) {
            this.addToPreviousSearches(result);
            this.resetSearch();
            this.mapService.setView(result.latitude, result.longitude, result.zoomLevel);
            this.searchService.selectResult(result);
        }
        if (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
        }
    }

    ngOnInit() {
        this.geocoderSearchForm = this.formBuilder.group({
            geocoderSearch: ['', Validators.required]
        });

        this.searchCompletedSub = this.searchService.searchCompleted$.subscribe(result => {
            if (result) {
                if (result.length > 0) {
                    this.searchCompleted(result);
                    return;
                }
                this.displayNoResults();
                return;
            }
            this.displayErrorMessage();
        });

        this.searchStartingSub = this.searchService.searchStarting$.subscribe(() => {
            this.showResultsContainer = false;
        });

        this.searchChangeSub = this.formControls.geocoderSearch.valueChanges.subscribe(val => {
            let newValue = val as string;
            if (newValue) {
                newValue = newValue.replace(this.notAllowedSearchRegex, '');
            }
            this.formControls.geocoderSearch.setValue(newValue, { emitEvent: false });
            this.setState();
        });
    }

    ngOnDestroy(): void {
        this.searchCompletedSub.unsubscribe();
        this.searchStartingSub.unsubscribe();
        this.searchChangeSub.unsubscribe();
    }

    setState() {
        this.displayPreviousSearches();

        if (this.errorMessage !== '') {
            this.state = 'error';
            return;
        }

        if (this.resultsCount === 0) {
            this.state = 'no-results';
            return;
        }

        if (this.formControls.geocoderSearch.value !== '') {
            this.state = 'dirty';
            return;
        }

        if (this.hasSearchInputGotFocus()) {
            this.state = 'focus';
            return;
        }

        this.state = '';
    }

    onSubmit() {
        if (environment.production  !== 'true') {
            if (this.formControls.geocoderSearch.value === this.errorSearchText) {
                this.displayErrorMessage();
                return;
            }
        }
        this.searchService.search(this.formControls.geocoderSearch.value);
    }

    private clearDownResults() {
        this.results = [];
        this.showResultsContainer = false;
        this.errorMessage = '';
        this.resultsCount = -1;
    }

    private resetSearch() {
        this.clearDownResults();
        this.formControls.geocoderSearch.setValue('', { emitEvent: false });
        this.geocoderSearch.nativeElement.blur();
        this.geocoderSearchForm.markAsPristine();
        this.setState();
    }

    private hasSearchInputGotFocus(): boolean {
        if (document.activeElement) {
            if (this.geocoderSearch) {
                return document.activeElement.id === this.geocoderSearch.nativeElement.id;
            }
        }
        return false;
    }

    clearText() {
        this.resetSearch();
    }

    documentClicked() {
        this.showResultsContainer = false;
        this.errorMessage = '';
        this.resultsCount = -1;
        this.setState();
    }

    userTyped(event: any) {
        return new RegExp(this.allowedSearchRegex).test(event.key);
    }
}
