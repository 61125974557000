import { Directive } from '@angular/core';
import * as L from 'leaflet';

@Directive({
  selector: '[appCentreMarker]'
})

export class CentreMarkerDirective extends L.Layer {
  private map: L.Map;
  private marker: L.Marker;
  private iconId = 'map-centre-marker';
  private zIndex = 2147483647;

  constructor() {
    super();
  }

  addTo(map: L.Map) {
    super.addTo(map);
    return this;
  }

  onAdd(map: L.Map) {
    this.map = map;

    this.map.on('move', () => {
      this.recentre();
    });

    this.addIcon();

    return this;
  }

  private recentre(){
    this.marker.setLatLng(this.map.getCenter());
  }

  private addIcon() {
    if (!this.marker) {
      const icon = new L.DivIcon({
        html: '<div id="' + this.iconId + '"></div>',
        className: this.iconId,
        iconSize: new L.Point(0, 0)
      } as L.DivIconOptions);

      this.marker = L.marker(this.map.getCenter(), {
        icon,
        interactive: false,
        draggable: false,
        keyboard: false,
        zIndexOffset: this.zIndex
      });

      this.marker.addTo(this.map);
    }
  }

}
