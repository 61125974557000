import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'oddEven'
})
export class OddEvenPipe implements PipeTransform {

  transform(value: number): unknown {
    if (value % 2 === 0) {
      return 'even';
    }
    return 'odd';
  }

}
