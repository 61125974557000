import { Injectable } from '@angular/core';
import siteData from '../../config/site-settings.json';
import { ISiteSettings } from '../interfaces/ISiteSettings';

@Injectable({
  providedIn: 'root'
})
export class SiteSettingsService {
  private settings: ISiteSettings;

  constructor() {
    this.settings = siteData as ISiteSettings;
  }

  getSettings(): ISiteSettings {
    return Object.assign({}, this.settings);
  }
}
