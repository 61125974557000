export class PrintPreviewRequestModel {
    id: string;
    title: string;
    email: string;
    scale: number;
    landscape: boolean;
    mono: boolean;
    paperSize: string;
    mapLayers: string[];
    comments: string;
    x: number;
    y: number;
}
