<div class="container contact-component">

    <div class="custom-breadcrumb">
        <a id="contact-breadcrumb-home" *ngIf="loggedIn" title="home" [routerLink]="['/home']" path="home">Home</a>
        <a id="contact-breadcrumb-login" *ngIf="!loggedIn" title="login" [routerLink]="['/']">Log In</a>
        <span class="crumb-separator">/</span>
        <span id="contact-breadcrumb-contact" class="current-crumb">Contact Us</span>
    </div>

    <div class="row">

        <div id="contact-title-container" class="col-xs-12">

            <h1 id="contact-page-title">Contact Us</h1>

        </div>

    </div>

    <div class="row">

        <div id="contact-form-container" class="col-md-7">

           <h2>Get in touch</h2>

           <p id="contact-us-pre">
                If you have any queries or would like to find out more, please get in touch using the form 
                below and a member of the team will respond as soon as possible.
           </p>

           <form id="contact-form" [formGroup]="contactForm" (ngSubmit)="submit()" class="input-form">
            <div class="form-group row">
                <div class="col-lg-12">
                    <label class="control-label" for="contact-title">Title</label>

                    <select id="contact-title" class="form-control" formControlName="title" aria-label="select title">
                        <option value="" selected="true" disabled>Please select one...</option>
                        <option *ngFor="let title of titles;let i = index;" [value]="title">{{title}}</option>
                    </select>

                </div>
            </div>

                <div class="form-group row">
                    <div class="col-lg-6">
                        <label class="control-label required" for="contact-first-name">First Name</label>

                        <input id="contact-first-name" maxlength="20" class="form-control" autocomplete="off" placeholder="First Name" type="text" formControlName="firstName">
                    </div>

                    <div class="col-lg-6">
                        <label class="control-label required" for="contact-first-name">Last Name</label>

                        <input id="contact-last-name" maxlength="20" class="form-control" autocomplete="off" placeholder="Last Name" type="text" formControlName="lastName">
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-lg-12">
                        <label class="control-label required" for="contact-phone">Phone Number</label>

                        <input id="contact-phone" class="form-control" autocomplete="off" placeholder="Phone Number" type="text" maxlength="15" formControlName="phone">
                       
                        <div id="contact-phone-validation-invalid" class="invalid-feedback onblur" *ngIf="contactForm.controls.phone.errors?.pattern">Please enter a valid phone number that contains only numbers or starts with a '+’</div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-lg-12">
                        <label class="control-label required" for="contact-email">Email Address</label>

                        <input id="contact-email" class="form-control" autocomplete="off" placeholder="Email Address" type="text" formControlName="email">
                        
                        <div id="contact-email-validation-invalid" class="invalid-feedback onblur" *ngIf="this.contactForm.controls.email.errors?.email?.emailValid === false">Please enter a valid email address</div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-lg-6">
                        <label class="control-label required" for="contact-provider">Utility Provider</label>
  
                        <select id="contact-provider" class="form-control required" formControlName="provider" aria-label="select provider">
                            <option value="" selected="true" disabled>Please select one...</option>
                            <option *ngFor="let provider of providers;let i = index;" [value]="provider">{{provider}}</option>
                        </select>
                    </div>

                    <div class="col-lg-6">
                        <label class="control-label required" for="contact-query">Query Type</label>

                        <select id="contact-query" class="form-control" formControlName="query" aria-label="select query">
                            <option value="" selected="true" disabled>Please select one...</option>
                            <option *ngFor="let query of queries;let i = index;" [value]="query">{{query}}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-lg-12">
                        <label class="control-label required" for="contact-message">Message</label>
                        <textarea id="contact-message" class="form-control" rows="5" cols="1" autocomplete="off" placeholder="Message" formControlName="message"></textarea>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-lg-12">
                        <label role="checkbox" class="looky-checkbox required" for="contact-terms" [ngClass]="termsInvalid? 'invalid' : ''">
                            <input id="contact-terms" type="checkbox" tabindex="0" formControlName="terms" aria-label="select to agree to our privacy policy">
                            <span></span>
                            <span>Please tick to agree to our <a id="contact-privacy" aria-label="click to view privacy policy" (click)="viewPrivacy($event)">privacy policy</a></span>
                        </label>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-lg-12">
                          
                        <div id="contact-required-validation-invalid" class="invalid-feedback" *ngIf="requiredValueMissing">To proceed you must complete all the fields marked as mandatory <span class="form-label required"></span></div>
                    
                        <button id="contact-form-submit" class="btn btn-primary btn-block" type="submit">Submit</button>
                    </div>
                </div>
           </form>

        </div>

        <div id="contact-details-container" class="col-md-5">
            <div id="contact-details">

               <div id="contact-details-phone" class="contact-details-section row">

                    <div id="contact-phone-icon" class="col-xs-2">
                        <i class="fa-solid fa-phone"></i>
                    </div>

                    <div class="col-xs-10">
                        <h2>Phone</h2>

                        <div class="details">
                            Monday - Friday from 9am - 5pm
                            (excluding bank holidays)
                        </div>

                        <div class="details-method">
                            {{customerServiceNumber}}
                        </div>
                    </div>
               </div>

               <div id="contact-details-address" class="contact-details-section row">

                <div class="col-xs-2">
                    <img id="contact-address-icon" alt="watermark icon" src="../../../assets/watermark.svg">
                </div>

                <div class="col-xs-10">
                    <h2>Office</h2>

                    <div class="details">
                        Want to send us a letter or come say hello?
                    </div>

                    <div class="details-method">
                        {{officeAddress}}
                    </div>
                </div>
           </div>
            </div>
        </div>

    </div>
</div>


<app-modal #contactSuccessModal id="contactSuccessModal">
    <div header> 
        Thank You
    </div>
    <div body>
        Thanks for submitting your query, a member of the team will get back to you as soon as possible.
    </div>
    <div footer>
    </div>
</app-modal>

<app-modal #contactFailureModal id="contactFailureModal">
    <div header> 
        Problem with submitting form
    </div>
    <div body>
        Sorry there was a problem submitting your query. Please try again or call a member of our team directly on {{customerServiceNumber}} or email us at {{supportEmail}}.
    </div>
    <div footer>
    </div>
</app-modal>

