
<div #carousel class="carousel-wrapper" [ngClass]="{'buttons-left': buttonsLeft}">
    <div #carouselInner class="carousel-inner" [ngStyle]="carouselInnerStyle">
        <div *ngFor="let item of items;let i = index;" class="carousel-item" [ngStyle]="carouselItemStyle" [class.on]="i == currentSlide">
            <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
        </div>
    </div>
    <div class="carousel-buttons" *ngIf="items.length > 1">
        <button type="button" *ngFor="let item of items;let i = index;" class="carousel-button" (click)="setSlide(i)" [class.on]="i == currentSlide" attr.aria-label="slide {{i}}">
        </button>
    </div>
</div>
