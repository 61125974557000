import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '../shared/spinner/spinner.component';
import { ModalComponent } from './modal/modal.component';
import { SafePipe } from './safe.pipe';
import { PagerComponent } from './pager/pager.component';
import { DashDelimPipe } from './dash-delim.pipe';
import { OrderSummaryModalComponent } from './order-summary-modal/order-summary-modal.component';
import { OrderSummaryComponent } from './order-summary-modal/order-summary/order-summary.component';
import { IdleModalComponent } from './idle-modal/idle-modal.component';
import { TextTruncatePipe } from './text-truncate.pipe';
import { DocumentClickDirective } from './document-click.directive';
import { OddEvenPipe } from './odd-even.pipe';
import { PlotStatusClassPipe } from './plot-status-class.pipe';
import { ObjectTruePropNamesPipe } from './object-true-prop-names.pipe';
import { LoadingDotsComponent } from './loading-dots/loading-dots.component';
import { OrderSubmittedModalComponent } from './order-submitted-modal/order-submitted-modal.component';
import { CarouselComponent } from './carousel/carousel.component';
import { CarouselItemDirective } from './carousel/carousel-item.directive';
import { MenuItemDirective } from './menu-item.directive';
import { NavItemDirective } from './nav-item.directive';
import { ExpandoDirective } from './expando.directive';
import { OrderUnavailableModalComponent } from './order-unavailable-modal/order-unavailable-modal.component';
import { InvalidBasketModalComponent } from './invalid-basket-modal/invalid-basket-modal.component';
import { DropdownFilterDirective } from './dropdown-filter.directive';
import { ClickDragDirective } from './click-drag.directive';

@NgModule({
  declarations: [SpinnerComponent, ModalComponent, SafePipe, PagerComponent, DashDelimPipe, OrderSummaryModalComponent,
    OrderSummaryComponent, IdleModalComponent, TextTruncatePipe, DocumentClickDirective, OddEvenPipe, PlotStatusClassPipe, ObjectTruePropNamesPipe, LoadingDotsComponent,
    OrderSubmittedModalComponent,
    CarouselComponent,
    CarouselItemDirective,
    MenuItemDirective,
    NavItemDirective,
    ExpandoDirective,
    OrderUnavailableModalComponent,
    InvalidBasketModalComponent,
    DropdownFilterDirective,
    ClickDragDirective],
  imports: [
      CommonModule,
      FormsModule
  ],
  exports: [
      SpinnerComponent, ModalComponent, SafePipe, PagerComponent, DashDelimPipe, IdleModalComponent, OrderSummaryModalComponent, TextTruncatePipe, DocumentClickDirective,
      OddEvenPipe, PlotStatusClassPipe, ObjectTruePropNamesPipe, LoadingDotsComponent, OrderSubmittedModalComponent, CarouselComponent, CarouselItemDirective, 
      MenuItemDirective, NavItemDirective, ExpandoDirective, OrderUnavailableModalComponent, InvalidBasketModalComponent, DropdownFilterDirective, ClickDragDirective
  ]
})

export class SharedModule { }
