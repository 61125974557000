import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { ContactModel } from 'src/app/models/contact.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ContactService } from 'src/app/services/contact.service';
import { NavigateService } from 'src/app/services/navigate.service';
import { SiteSettingsService } from 'src/app/services/site-settings.service';
import { emailValidator } from 'src/app/shared/email-validator';
import { ModalComponent } from 'src/app/shared/modal/modal.component';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.less']
})
export class ContactUsComponent implements OnInit {
  providers: string[] = [];
  titles: string[] = [];
  queries: string[] = [];
  requiredValueMissing = false;
  termsInvalid = false;
  loggedIn = false;
  customerServiceNumber: string;
  supportEmail: string;
  officeAddress: string;
  @ViewChild('contactSuccessModal') contactSuccessModal: ModalComponent;
  @ViewChild('contactFailureModal') contactFailureModal: ModalComponent;

  contactForm = this.formBuilder.group({
    title: new FormControl('', { nonNullable: true, validators: [] }),
    firstName: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    lastName: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    phone: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.pattern('^[\+]?[0-9]{7,15}$')] }),
    email: new FormControl('', { nonNullable: true, validators: [Validators.required, emailValidator()] }),
    provider: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    query: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    message: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    terms: new FormControl(false, { nonNullable: true, validators: [Validators.requiredTrue] }),
    source: new FormControl(window.location.hostname, { nonNullable: true, validators: []})
  }, { validators: [ ], updateOn: 'blur' });

  constructor(private formBuilder: FormBuilder, private contactService: ContactService, private authService: AuthenticationService,
    private navigateService: NavigateService, private siteSettingsService: SiteSettingsService) {
    this.providers = this.contactService.getProviders();
    this.titles = this.contactService.getTitles();
    this.queries = this.contactService.getQueries();

    this.customerServiceNumber = this.siteSettingsService.getSettings().customerServiceNumber;
    this.supportEmail = this.siteSettingsService.getSettings().supportEmail;
    this.officeAddress = this.siteSettingsService.getSettings().officeAddress;

    if (this.authService.currentUserValue) {
      this.loggedIn = this.authService.currentUserValue.accessToken !== null && 
      this.authService.currentUserValue.accessToken !== '';
    }
  }

  ngOnInit(): void {
  }

  /**
   * Return if any required form controls are invalid
   * @returns boolean 
   */
  private anyRequiredInvalid(): boolean {
    let invalid = false;
    Object.keys(this.contactForm.controls).filter(key => {
      const controlErrors: ValidationErrors = this.contactForm.get(key).errors;
      if(controlErrors?.required) {
        this.contactForm.get(key).markAsDirty();
        invalid = true;
      }
    });
    return invalid;
  }

  private reset() {
    this.contactForm.reset();
    this.requiredValueMissing = false;
    this.termsInvalid = false;
    this.contactForm.enable({ onlySelf: true});
  }

  submit() {
    if (this.contactForm.valid && this.contactForm) {
      const contact = this.contactForm.value as ContactModel;
      this.contactForm.disable({ onlySelf: true});
      this.contactService.send(contact).subscribe(success => {
        if (success) {
          this.contactSuccessModal.open();
          this.reset();
        } else {
          this.contactForm.enable({ onlySelf: true});
          this.contactFailureModal.open();
        }
      });
    } else {
      // requirement to only display a single message when a required field is not completed
      this.requiredValueMissing = this.anyRequiredInvalid();
      this.termsInvalid = this.contactForm.controls.terms.errors?.required;
    }
  }

  viewPrivacy(e: Event) {
    e.stopPropagation();
    e.preventDefault();
    this.navigateService.navigateToPrivacyPolicy();
  }

}
