import { ILayerModel } from '../interfaces/ILayerModel';
import { MapLayerValidationType } from './map-layer-validation.type';

export class LayerModel implements ILayerModel {
    mapLayer = '';
    name = '';
    provider = '';
    selected = false;
    viewable = false;
    providerId = 0;
    restricted = false;
    basketValidation = MapLayerValidationType.Valid;
}
