import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IPlotModel } from 'src/app/interfaces/IPlotModel';

@Component({
  selector: '[app-basket]',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.less']
})
export class BasketComponent implements OnInit, OnChanges {
  @Input() initialised = false;
  @Input() plots: IPlotModel[] = [];
  @Output() create = new EventEmitter<Event>();
  @Output() generate = new EventEmitter<Event>();
  @Output() edit = new EventEmitter<Event>();
  @ViewChild('generatePlotsTitleElem') generatePlotsTitleElem: ElementRef<HTMLElement>;
  total = 0.00;
  generateDisabled = true;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.setGenerateState();

    if (changes.initialised) {
      if (changes.initialised.currentValue) {
        this.initialised = true;
      }
    }
  }

  private setGenerateState() {
    const unsaved = this.plots.filter(p => !p.isCreated).length > 0;
    const current = this.plots.filter(p => p.isCurrent).length > 0;
    const invalid = this.plots.filter(p => !p.valid).length > 0;
    this.generateDisabled = current || unsaved || invalid || this.plots.length === 0;
  }

  ngOnInit() {
  }

  addNewPlot(e: Event) {
    this.create.emit(e);
  }

  editBasket(e: Event) {
    this.edit.emit(e);
  }

  generatePlots(e: Event) {
    this.generate.emit(e);
  }

  generateTitle(): void {
    if (this.generateDisabled) {
      this.generatePlotsTitleElem.nativeElement.title = 'Check Basket for Unsaved or Invalid Plots';
    } else {
      this.generatePlotsTitleElem.nativeElement.removeAttribute('title');
    }
  }
}
