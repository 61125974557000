import { IAssetLayerModel } from '../interfaces/IAssetLayerModel';
import { LayerModel } from './layer.model';

export class AssetLayerModel implements IAssetLayerModel {
    classification = '';
    layers: LayerModel[] = [];
    selectedCount = 0;
    expanded = false;
    outOfArea = false;

    constructor() {
        this.layers = new Array<LayerModel>();
    }
}
