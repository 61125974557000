import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, finalize, filter, take } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { SpinnerService } from './spinner.service';
import { environment } from 'src/environments/environment';
import { LogoutReason } from '../models/logout-reason';
import { ServiceErrorHandler } from '../shared/service-error-handler';

@Injectable(
    { providedIn: 'root' }
)

export class JwtInterceptor implements HttpInterceptor {
    private refreshPath = 'refreshToken';
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private requestCounter = 0;

    constructor(private authenticationService: AuthenticationService, private spinnerService: SpinnerService, private errorHandler: ServiceErrorHandler) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.url.toLowerCase().indexOf(this.refreshPath.toLowerCase()) === -1) {
            this.spinnerService.show();
        }

        // add authorization header with jwt token if available
        request = this.addToken(request);
        this.requestCounter++;

        return next.handle(request).pipe(
            finalize(() => {
                this.requestCounter--;
                if (this.requestCounter === 0) {
                    this.spinnerService.hide();
                }
            }),
            catchError(error => {
                if (request.url.startsWith(environment.authApiUrl)) {
                    this.isRefreshing = false;
                    if (request.url.toLowerCase().indexOf('login') === -1) {
                        this.authenticationService.logout(LogoutReason.Error);
                    }
                    return throwError(error);
                } else if (error instanceof HttpErrorResponse) {
                    if (error.status === 401) {
                        return this.handle401Error(request, next);
                    } else {
                        // bad response
                        this.errorHandler.handleError(error); 
                    }
                }
                
                return throwError(error);
            })
        );
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.isRefreshing) {
            // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
            // – which means the new token is ready and we can retry the request again
            return this.refreshTokenSubject.pipe(
                filter(result => result !== null),
                take(1),
                switchMap(() => next.handle(this.addToken(request)))
            );
        } else {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.authenticationService.refreshToken().pipe(
                switchMap((token) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(token);
                    return next.handle(this.addToken(request));
                }),
                catchError(error => {
                    this.isRefreshing = false;
                    this.authenticationService.logout(LogoutReason.Error);
                    return throwError(error);
                })
            );
        }
    }

    private addToken(request: HttpRequest<any>) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser && currentUser.accessToken) {
            return request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.accessToken}`
                }
            });
        }

        return request;
    }
}
