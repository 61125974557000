<div class="glossary-content">

    <div class="container">
        <div class="custom-breadcrumb">
            <a id="glossary-breadcrumb-home" title="home" [routerLink]="['/']">Home</a>
            <span class="crumb-separator">/</span>
            <span id="glossary-breadcrumb-glossary">Glossary</span>
        </div>

        <h1>Glossary</h1>

    <div class="row">

        <div class="col-md-6">
            <app-glossary-item>
                <ng-container title>Assets</ng-container>
                <ng-container body>
                    Represent foreground features laid over a background OS MasterMap Topographic layer in downloadable PDFs.
                </ng-container>
            </app-glossary-item> 

            <app-glossary-item>
                <ng-container title>Asset Layer</ng-container>
                <ng-container body>
                    A specific set of related features for an asset owner and asset classification that is available to the user (i.e. Anglian Water - Clean Water, Thames Water - Wastewater, Virgin Media - Telecoms).
                </ng-container>
            </app-glossary-item> 
    
            <app-glossary-item>
                <ng-container title>Asset Plan</ng-container>
                <ng-container body>
                    A PDF map generated per plot either via print preview or by creating an order of plots. Each PDF will comprise all asset plans for all asset layers selected. When a wastewater asset plan is selected it will always contain a manhole report in the PDF.
                </ng-container>
            </app-glossary-item> 

            <app-glossary-item>
                <ng-container title>Asset Owner</ng-container>
                <ng-container body>
                    The organisation that owns assets e.g. Virgin Media.
                </ng-container>
            </app-glossary-item> 
   
            <app-glossary-item>
                <ng-container title>Background</ng-container>
                <ng-container body>
                    The option to render asset layers, in the downloadable PDF, over a colour or mono OS MasterMap Topographic layer background.
                </ng-container>
            </app-glossary-item> 
            
            <app-glossary-item>
                <ng-container title>Basket</ng-container>
                <ng-container body>
                    Place where all placed and saved plots will be stored for the current order. The basket is accessible from the left side bar.
                </ng-container>
            </app-glossary-item> 
     
            <app-glossary-item>
                <ng-container title>Basket Pop-up</ng-container>
                <ng-container body>
                    A quick overview of the contents of the basket, accessible from the top navigation bar.
                </ng-container>
            </app-glossary-item> 

            <app-glossary-item>
                <ng-container title>Duplicate</ng-container>
                <ng-container body>
                    It is possible to duplicate a plot once it has been placed, using the 'Duplicate' icon on the plot control tool bar. This allows you to create multiple plots with the same criteria. Please note, you cannot duplicate a plot whilst it is in the process of being created or edited, you will need to click 'Place Plot' or 'Save Plot' first. When created, duplicate plots will slightly overlap the original plot, but can then be dragged to any location. 
                </ng-container>
            </app-glossary-item> 
    
            <app-glossary-item>
                <ng-container title>Expiry Date</ng-container>
                <ng-container body>
                    The date on which your <a id="statutory-access-link" [routerLink]="['/account/settings']" fragment="statutory-access">Statutory Access</a> for that Asset Owner will expire.
                </ng-container>
            </app-glossary-item> 

            <app-glossary-item>
                <ng-container title>Order</ng-container>
                <ng-container body>
                    An order can be made up of several different plot areas, each of which can have one or more asset layer. Once an order has been generated and confirmed, it will be processed and given a unique order ID. Previous orders can be found via the <a id="order-history-link" [routerLink]="['/orders/order-history']">Order History</a> page.
                </ng-container>
            </app-glossary-item> 

            <app-glossary-item>
                <ng-container title>Order Statuses</ng-container>
                <ng-container body>
                    <ul>
                        <li>
                            <span class="subtitle">Processing</span>
                            - An order will be in processing when it is first created. The time taken to process that order depends on the size of the order and the paper size of individual plots (i.e. A1 and A0 plots may take longer to process). Whilst an order is in processing, any plots which are still being created will show as 'Queued' on the Order Details page, and it is not possible to download these plots yet. Once each plot has been generated, it will be possible to select that plot to download it.
                        </li>

                        <li>
                            <span class="subtitle">Completed</span>
                            - All plots have been generated and are ready to download.
                        </li>

                        <li>
                            <span class="subtitle">Failed</span>
                            - Something went wrong during order processing. You need to 'Regenerate' the order before you can download any of the plots.
                        </li>

                        <li>
                            <span class="subtitle">Expired</span>
                            - Orders expire after 90 days as the data the plots are based on may have changed. In order to download the plots in an expired order, you need to process it again using 'Reorder'.
                        </li>
                    </ul>
                </ng-container>
            </app-glossary-item> 

            <app-glossary-item>
                <ng-container title>Pending Orders</ng-container>
                <ng-container body>
                    Orders which are currently processing or have failed are displayed on the  <a id="pending-orders-link" [routerLink]="['/orders/pending']">Pending Orders</a> page.
                </ng-container>
            </app-glossary-item> 
        </div>

        <div class="col-md-6">
            <app-glossary-item>
                <ng-container title>Plot</ng-container>
                <ng-container body>
                    A plot area with one asset layer. When you create a plot area, if you tick several asset layers, this will create a separate plot for each asset layer selected.
                </ng-container>
            </app-glossary-item> 

            <app-glossary-item>
                <ng-container title>Plot Area</ng-container>
                <ng-container body>
                    Rectangular extents defining the user's location of potential work on the map. Derives from a centre point, paper size, orientation and scale. A plot area can have one or more asset layers, each of which will create a separate plot.
                </ng-container>
            </app-glossary-item> 

            <app-glossary-item>
                <ng-container title>Plot Control Toolbar</ng-container>
                <ng-container body>
                    Accessible by clicking on a plot area on the map. The toolbar enables the user to quickly change plot orientation, paper size, centre the plot on the map, download a print preview, duplicate or delete a plot or open the edit plot side panel. Print preview is only available when A4 and A3 paper size is selected.
                </ng-container>
            </app-glossary-item> 

            <app-glossary-item>
                <ng-container title>Polygon Restrictions</ng-container>
                <ng-container body>
                    Specific areas that a user has access to assets in, within the asset owner area that they have Statutory Access. It is possible to have Statutory Access to an asset owner, but with a polygon restriction to a specific area. This would mean that the user would be restricted to creating plots in the area defined and not anywhere else in the asset owner's operational boundaries.
                </ng-container>
            </app-glossary-item> 
       
            <app-glossary-item>
                <ng-container title>Print Preview</ng-container>
                <ng-container body>
                    The ability to view and download an asset plan of the plot area you are currently creating or editing. You can either use the 'Print Preview' icon at the bottom of the Add Plot / Edit Plot panels, or the 'Print Preview' icon in the plot control bar. Please note, it is only possible to use print preview on plot areas with A4 or A3 Paper Size.
                </ng-container>
            </app-glossary-item> 

            <app-glossary-item>
                <ng-container title>Print Scale</ng-container>
                <ng-container body>
                    The map scale to be used for a plot. Possible print scale options are 1:500, 1:1000 and 1:1250.    
                </ng-container>
            </app-glossary-item> 
      
            <app-glossary-item>
                <ng-container title>Regenerate</ng-container>
                <ng-container body>
                    If an order fails when it is processed, you can use regenerate to process that same order again. As it is the same order, it will have the same order ID, order date and plots as before. It is recommended that you regenerate a failed order as soon as possible after it fails.    
                </ng-container>
            </app-glossary-item> 

            <app-glossary-item>
                <ng-container title>Reorder</ng-container>
                <ng-container body>
                    If an order has expired (because it is more than 90 days old), you can use the reorder function to create a copy of that order with up-to-date data. This will have a new order ID and order date, and will include all plots from the original order that you still have Statutory Access to.   
                </ng-container>
            </app-glossary-item> 
      
            <app-glossary-item>
                <ng-container title>Statutory Access</ng-container>
                <ng-container body>
                    Asset owner operational boundaries that you have <a id="account-settings-link" [routerLink]="['/account/settings']" fragment="statutory-access">access</a> to create plots in.   
                </ng-container>
            </app-glossary-item> 

            <app-glossary-item>
                <ng-container title>Statutory Access Status</ng-container>
                <ng-container body>
                    <ul>
                        <li>
                            <span class="subtitle">Accepted</span>
                             - Statutory Access for this asset owner has been confirmed, so you can create plots in this area.
                        </li>

                        <li>
                            <span class="subtitle">Expired</span>
                            - Statutory Access for this asset owner has expired, so is no longer in effect.
                        </li>

                        <li>
                            <span class="subtitle">Requested</span>
                            - Statutory Access for this asset owner has been requested and is awaiting confirmation.
                        </li>

                        <li>
                            <span class="subtitle">Rejected</span>
                            - Statutory Access for this asset owner has been requested but has been rejected.
                        </li>
                    </ul>    
                </ng-container>
            </app-glossary-item> 
   
            <app-glossary-item>
                <ng-container title>User Preferences</ng-container>
                <ng-container body>
                    Default settings for a plot area: Print scale, orientation, paper size and background.
                    <br><br>
                    If you set your user preferences, every time you create a new plot it will use these settings by default (although they can then be changed if required). This is useful if you always want to create plots with the same criteria.
                    <br><br>
                    User preferences are only applied when you have no existing plots in your basket. If you have already created one or more plots, the criteria of any new plots will be based on the last plot you created.
                </ng-container>
            </app-glossary-item>
        </div>

    </div>
</div>
