import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OrderResultType } from 'src/app/models/order-result-type';
import { ModalService } from 'src/app/services/modal.service';
import { NavigateService } from 'src/app/services/navigate.service';
import { OrderService } from 'src/app/services/order.service';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-order-submitted-modal',
  templateUrl: './order-submitted-modal.component.html',
  styleUrls: ['./order-submitted-modal.component.less']
})
export class OrderSubmittedModalComponent implements OnInit, OnDestroy, AfterViewInit {
  private subscriptions: Subscription[] = [];
  private orderDetailPath = 'order-detail';
  modalId = 'order-submitted-modal';
  errorModalId = 'order-submitted-error-modal';
  orderId = 0;
  displayNavToDetail = true;
  @ViewChild('orderSubmittedModal', { static: false }) orderSubmittedModal: ModalComponent;
  @ViewChild('orderSubmittedErrorModal', { static: false }) orderSubmittedErrorModal: ModalComponent;

  constructor(private orderService: OrderService, private modalService: ModalService, private router: Router,
              private route: ActivatedRoute, private navService: NavigateService) {
                this.initOrderCreateSub();
  }

  private setNavToDetail(context: OrderResultType) {
    if (context === OrderResultType.Regenerate) {
      if (this.router.url.toLowerCase().indexOf(this.orderDetailPath) === -1) {
        this.displayNavToDetail = true;
      } else {
        this.displayNavToDetail = false;
      }
    } else {
      this.displayNavToDetail = true;
    }
  }

  private initOrderCreateSub() {
    this.subscriptions.push(this.orderService.orderSubmitted$.subscribe(result => {
        if (result) {
          this.orderId = result.orderId;
          this.setNavToDetail(result.context);
          if (this.orderId && result.success) {
            this.modalService.open(this.modalId);
          } else{
            this.modalService.open(this.errorModalId);
          }
        }
      })
    );
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.orderSubmittedModal.closed$.subscribe(() => {
        this.orderService.orderSubmitComplete();
      })
    );

    this.subscriptions.push(this.orderSubmittedErrorModal.closed$.subscribe(() => {
        this.orderService.orderSubmitComplete();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  ngOnInit(): void {
  }

  close() {
    this.modalService.close(this.modalId);
  }

  closeError() {
    this.modalService.close(this.errorModalId);
  }

  navOrderDetail() {
    this.router.navigate(['/orders/order-detail', this.orderId], { state: { create: 'true' }, relativeTo: this.route});
    this.close();
  }

  navSupport() {
    this.navService.navigateToSupport();
  }
}
