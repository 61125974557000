import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomModalOptions, ModalSizeOptions } from '../models/custom-modal-options';
import { StatutoryAccessModel } from '../models/statutory-access.model';
import { UserDetailModel } from '../models/user-detail.model';
import { StatutoryAccessService } from '../services/statutory-access.service';
import { UserService } from '../services/user.service';
import { CarouselComponent } from '../shared/carousel/carousel.component';
import { ModalComponent } from '../shared/modal/modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './home.component.html',
  styleUrls: [ './home.component.less']
})

export class HomeComponent implements AfterViewInit {
    private helpVideoSource = '';
    userDetail$: Observable<UserDetailModel>;
    statutoryAccesses$: Observable<StatutoryAccessModel[]>;
    @ViewChild('helpVideoModal') helpVideoModal: ModalComponent;
    @ViewChild('carousel') carousel: CarouselComponent;
    helpVideoModalId = 'help-video-modal';
    helpVideoUrl = '';
    digdatHome = '';

    constructor(private userService: UserService, private statutoryAccessService: StatutoryAccessService) {
      this.helpVideoSource = environment.helpVideoUrl;
      this.digdatHome = environment.digdatHome;
      this.userDetail$ = this.userService.userDetail$.pipe();
    
      this.statutoryAccesses$ = this.statutoryAccessService.getUserStatutoryAccess().pipe(
        map(sa => {
            return sa.filter(a => a.status?.toLowerCase() === 'accepted');
          }
        )
      );
  }

  ngAfterViewInit(): void {
    this.helpVideoModal.closed$.subscribe(() => {
      this.helpVideoUrl = '';
    });
  }

  showHelpVideo(e: Event) {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.helpVideoModal.open({ size: ModalSizeOptions.Large, customCssClass: this.helpVideoModalId } as CustomModalOptions);
    this.helpVideoUrl = this.helpVideoSource;
  }

  upcomingFeatures(e: Event) {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.carousel.nextSlide();
  }
}
