import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IOrderService } from '../interfaces/IOrderService';
import { OrderCreateRequest } from '../models/order-create-request.model';
import { EditRequestModel } from '../models/edit-request.model';
import { EditResultModel } from '../models/edit-result.model';
import { OrderItemSummaryModel } from '../models/order-item-summary-model';
import { OrderResultType } from '../models/order-result-type';
import { OrderResultModel } from '../models/order-result.model';
import { ReorderRequestModel } from '../models/reorder-request.model';
import { ServiceErrorHandler } from '../shared/service-error-handler';
import { OrderReportService } from './order-report.service';
import { SpinnerService } from './spinner.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService implements IOrderService {
  private orderApiUrl: string;
  private orderSubmitCompleted = new Subject<void>();
  private orderSubmitted = new Subject<OrderResultModel>();
  private reorderRequest = new Subject<OrderItemSummaryModel[]>();
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };
  private validationSpinnerText = 'Please wait while we check your order';
  orderSubmitted$ = this.orderSubmitted.asObservable();
  orderSubmitCompleted$ = this.orderSubmitCompleted.asObservable();
  reorderRequest$ = this.reorderRequest.asObservable();

  constructor(private http: HttpClient, private errorHandler: ServiceErrorHandler, private orderReportService: OrderReportService, private spinnerService: SpinnerService) {
    this.orderApiUrl = environment.orderApiUrl;
   }

   private reallyReorder(orderId: number, orderItemIds: string[], orderTitle: string): void {
    const apiAction = '/Reorder/';
    const reorderRequest = { orderId, orderItemIds, title: orderTitle } as ReorderRequestModel;

    this.http.post<OrderResultModel>(this.orderApiUrl + apiAction, JSON.stringify(reorderRequest), this.httpOptions).pipe(
      map(result => {
        result.context = OrderResultType.Reorder;
        return result;
      }),
      catchError(err => {
          this.errorHandler.handleError(err);
          return of(Object.assign(new OrderResultModel, { orderId: orderId, success: false, message: '', context: OrderResultType.Reorder }));
        }
      )
    ).subscribe(result => {
      this.orderSubmitted.next(result);
    });
  }

  private getReorderRequest(orderId: number): Observable<OrderItemSummaryModel[]> {
    const validate = true;
    this.spinnerService.setMessage(this.validationSpinnerText);
    this.spinnerService.show();
    return this.orderReportService.getOrderSummaryItems(orderId.toString(), validate).pipe(
      map(items => {
        if (items) {
          return items;
        }
        return [];
      }),
      catchError(err => {
          this.errorHandler.handleError(err);
          return of(null);
        }
      )
    );
  }

   /**
    * Create an order from the items currently in the user's basket
    */
  create(orderTitle?: string): Observable<OrderResultModel> {
    const apiAction = '/CreateOrder/';
    const request = { title: orderTitle } as OrderCreateRequest;

    return this.http.post<OrderResultModel>(this.orderApiUrl + apiAction, JSON.stringify(request), this.httpOptions).pipe(
      map(result => {
        result.context = OrderResultType.Create;
        this.orderSubmitted.next(result);
        return result;
      }),
      catchError(err => {
          this.errorHandler.handleError(err);
          const errorResult = Object.assign(new OrderResultModel, { orderId: '', success: false, message: '', context: OrderResultType.Create });
          this.orderSubmitted.next(errorResult);
          return of(errorResult);
        }
      )
    );
  }

  /**
   * Regenerate an order
   * @param orderId relates to the order to regenerate...
   * @returns Order Result Response
   */
  regenerate(orderId: number): void {
    const apiAction = '/RegenerateOrder/';
    this.http.post<OrderResultModel>(this.orderApiUrl + apiAction, orderId).pipe(
      map(result => {
        result.context = OrderResultType.Regenerate;
        return result;
      }),
      catchError(err => {
          this.errorHandler.handleError(err);
          return of(Object.assign(new OrderResultModel, { orderId: orderId, success: false, message: '', context: OrderResultType.Regenerate }));
        }
      )
    ).subscribe(result => {
      this.orderSubmitted.next(result);
    });
  }
  
  reorder(orderId: number, orderItemIds?: string[], really?: boolean, orderTitle?: string): void {
    if (really) {
      this.reallyReorder(orderId, orderItemIds, orderTitle);
    } else {
      this.getReorderRequest(orderId).subscribe(items => {
        this.reorderRequest.next(items);
      });
    }
  }

  orderSubmitComplete(): void {
    this.orderSubmitCompleted.next();
  }

  editOrder(orderId: number, orderItemIds?: string[]): Observable<EditResultModel | null> {    
    const apiAction = '/EditOrder/';
    const editRequest = { orderId, orderItemIds } as EditRequestModel;

    return this.http.post<EditResultModel>(this.orderApiUrl + apiAction, JSON.stringify(editRequest), this.httpOptions).pipe(
      catchError(err => {
          this.errorHandler.handleError(err);
          return of(null);
        }
      )
    );
  }
}
