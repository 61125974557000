import { Injectable } from '@angular/core';
import proj4 from 'proj4';
import { ICoordConverterService } from '../interfaces/ICoordConverterService';
import { CoordinateModel } from '../models/coordinate.model';

@Injectable({
  providedIn: 'root'
})

export class CoordConverterService implements ICoordConverterService {
  private worldDef = 'EPSG:4326';
  private bngDef = 'EPSG:27700';
  private bngProjectionDef = '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs';

  constructor() {
    proj4.defs(this.bngDef, this.bngProjectionDef);
  }

  toBng(lat: number, lng: number, decimals: number): CoordinateModel {
    const point = proj4(this.worldDef, this.bngDef, [ lng, lat ]);
    const x = Number(point[0].toFixed(decimals));
    const y = Number(point[1].toFixed(decimals));
    return { x, y } as CoordinateModel;
   }
}
