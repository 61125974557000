import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IWindowResizeService } from '../interfaces/IWindowResizeService';

@Injectable({
  providedIn: 'root'
})
export class WindowResizeService implements IWindowResizeService {
  private debounceTiming = 0;
  private width = new BehaviorSubject<number>(window.innerWidth);
  width$ = this.width.asObservable();
  private height = new BehaviorSubject<number>(window.innerHeight);
  height$ = this.height.asObservable();
  private viewportWidth: number;
  private viewportHeight: number;
  private orientationChanged = new Subject<void>();
  orientationChanged$ = this.orientationChanged.asObservable();
  
  constructor(private zone: NgZone) {
    this.viewportWidth = window.screen.width;
    this.viewportHeight = window.screen.height;
    this.setWidthHeight();

    this.zone.runOutsideAngular(() => {
      fromEvent(window, 'resize').pipe(debounceTime(this.debounceTiming), distinctUntilChanged((prev, cur) => {
       return (prev.currentTarget as Window).innerWidth !== (cur.currentTarget as Window).innerWidth ||
       (prev.currentTarget as Window).innerHeight !== (cur.currentTarget as Window).innerHeight;
      })).subscribe(() => {
        this.checkOrientationChange();
         this.zone.run(() => {
           this.setWidthHeight();
         });
       }
       );
    });
  }

  private setWidthHeight() {
    if (this.width.value !== window.innerWidth) {
      this.width.next(window.innerWidth);
     }
     if (this.height.value !== window.innerHeight) {
      this.height.next(window.innerHeight);
     }
  }

  private checkOrientationChange() {
    if (window.screen.width !== this.viewportWidth || window.screen.height !== this.viewportHeight) {
      this.orientationChanged.next();
    }
  }
}
