import { Injectable } from '@angular/core';
import { IBrowserService } from '../interfaces/IBrowserService';

@Injectable({
  providedIn: 'root'
})

export class BrowserService implements IBrowserService {
  private userAgent: string;

  constructor() {
  }

  private isIe(): string {
    const ua = this.userAgent;
    const ieClassId = 'ie';
    let version = -1;

    const msie = ua.indexOf('msie ');
    if (msie > 0) {
      // IE 10 or older => return version number
      version = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      version =  parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    if (version > -1) {
      return ieClassId + version;
    } else {
      return '';
    }

  }

  private getEdgeVersion(): string {
    let version = '';
    if (this.isEdge()) {
      const edge = this.userAgent.indexOf('edge/');
      if (edge > 0) {
        version =  parseInt(this.userAgent.substring(edge + 5, this.userAgent.indexOf('.', edge)), 10).toString();
      }
    }
    return version;
  }

  private isEdge(): boolean {
    return this.userAgent.indexOf('edge/') > 0;
  }

  private isAndroid(): boolean {
      return this.userAgent.indexOf('android') > -1 && this.userAgent.indexOf('mozilla/5.0') > -1;
  }

  private isIPhone(): boolean {
      return /(iphone)/g.test(this.userAgent);
  }

  private isFirefox(): boolean {
      return this.userAgent.indexOf('gecko') > -1 && this.userAgent.indexOf('firefox') > -1;
  }

  private isSafari(): boolean {
    return this.userAgent.indexOf('safari') > -1;
  }

  private isChrome(): boolean {
      return /chrom(e|ium)/.test(this.userAgent) && !this.isEdge();
  }

  private isIos(): boolean {
      return /(ipad|iphone|ipod)/g.test(this.userAgent);
  }

  private isIPad(): boolean {
      return /(ipad)/g.test(this.userAgent);
  }

  /**
   * Determine if browser should use the Acrobat Reader plugin for rendering PDFs (e.g. Print Previews)
   * I haven't looked at plugin detection yet.  There are external pdf view libraries but they all seem to be large.
   * Logic taken from legacy.  Where a "webkit" test was done.  When I tested against legacy.  These 3 browsers were
   * the ones which rendered using the "object" (plugin) rather than an iFrame.
   */
  usePdfPlugin(userAgent: string): boolean {
    this.userAgent = userAgent.toLowerCase();
    // Just trying to copy the logic in legacy
    return this.isChrome() || this.isEdge() || this.isSafari();
  }

  /**
   * Return a string identifier for the current browser based on userAgent
   */
  getBrowser(userAgent: string): string {
    this.userAgent = userAgent.toLowerCase();
    try {
      if (this.isAndroid()) {
        return 'android';
      }

      if (this.isChrome()) {
        return 'chrome';
      }

      if (this.isFirefox()) {
        return 'firefox';
      }

      if (this.isIPad()) {
        return 'ipad';
      }

      if (this.isIPhone()) {
        return 'iphone';
      }

      const ie = this.isIe();
      if (ie !== '') {
        return ie;
      }

      if (this.isEdge()) {
        return 'edge';
      }

      return '';
    } catch {
      return '';
    }
  }

}
