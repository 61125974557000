import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAssetLayerModel } from '../interfaces/IAssetLayerModel';
import { IBasketService } from '../interfaces/IBasketService';
import { IPlotModel } from '../interfaces/IPlotModel';
import { BasketItemLayerModel } from '../models/basket-item-layer.model';
import { BasketItemModel } from '../models/basket-item.model';
import { ServiceErrorHandler } from '../shared/service-error-handler';
import { AuthenticationService } from './authentication.service';
import { PlotHelperService } from './plot-helper.service';

@Injectable({
  providedIn: 'root'
})

export class BasketService implements IBasketService {
  private basketApiUrl: string;
  private httpJsonHeader = new HttpHeaders({
    'Content-Type':  'application/json'
  });
  private httpOptions = {
    headers: this.httpJsonHeader
  };

  private fetchedBasketItems = new Subject<IPlotModel[]>();
  fetchedBasketItems$ = this.fetchedBasketItems.asObservable();

  constructor(private http: HttpClient, private errorHandler: ServiceErrorHandler, private authService: AuthenticationService, 
    private plotHelperService: PlotHelperService) {
    this.basketApiUrl = environment.basketApiUrl;
  }

  private toBasketItemLayer(assetLayers: IAssetLayerModel[], basketItemId: string): BasketItemLayerModel[] {
    if (assetLayers) {
     return assetLayers.map(a => {
        return a.layers.map(s => {
          const i = new BasketItemLayerModel();
          i.mapLayer = s.mapLayer;
          i.basketItemId = basketItemId;
          i.selected = s.selected;
          return i;
      });
     }).reduce((accumulator, value) => accumulator.concat(value), []);
    }
    return [];
 }

  private toBasketItem(plot: IPlotModel): BasketItemModel {
    return Object.assign(new BasketItemModel(), {
          basketItemId: plot.id,
          userId: this.authService.currentUserValue.userId,
          modifiedDate: new Date(Date.now()).toISOString(),
          plotTitle: plot.plotTitle,
          landscape: plot.landscape,
          mono: plot.mono,
          paperSize: plot.paperSize,
          comments: plot.comments,
          x: plot.x,
          y: plot.y,
          plotAreaNumber: plot.plotAreaNumber,
          scale: plot.printScale,
          isCreated: plot.isCreated,
          basketItemLayers: this.toBasketItemLayer(plot.assetLayers, plot.id)
      } as BasketItemModel);
  }

  add(plot: IPlotModel): Observable<boolean> {
    const apiAction = '/AddToBasket/';
    const basketItem = this.toBasketItem(plot);
    basketItem.orderEdit = false;
    return this.http.post(this.basketApiUrl + apiAction, JSON.stringify(basketItem), this.httpOptions).pipe(
      map(() => {
      return true;
      }),
      catchError(err => {
        this.errorHandler.handleError(err);
        return of(false);
        }
      )
    );
  }

  update(plots: IPlotModel[]): Observable<boolean> {
      const apiAction = '/UpdateBasketItems/';
      const basketItems = plots.map(p => this.toBasketItem(p));
      return this.http.post(this.basketApiUrl + apiAction, JSON.stringify(basketItems), this.httpOptions).pipe(
        map(() => {
        return true;
        }),
        catchError(err => {
          this.errorHandler.handleError(err);
          return of(false);
          }
        )
      );
  }

  updatePlotAreaNumbers(plots: IPlotModel[]): Observable<boolean> {
    const apiAction = '/UpdatePlotAreaNumbers/';
    const dictionary: { [key: string]: number; } = {};
    plots.forEach(p => dictionary[p.id] = p.plotAreaNumber);
    return this.http.post(this.basketApiUrl + apiAction, JSON.stringify(dictionary), this.httpOptions).pipe(
      map(() => {
      return true;
      }),
      catchError(err => {
        this.errorHandler.handleError(err);
        return of(false);
        }
      )
    );
  }

  delete(plot: IPlotModel): Observable<boolean> {
    const apiAction = '/DeleteFromBasket/';
    const params = new HttpParams().set('basketItemId', plot.id);
    return this.http.delete(this.basketApiUrl + apiAction, { params }).pipe(
      map(() => {
      return true;
      }),
      catchError(err => {
        this.errorHandler.handleError(err);
        return of(false);
        }
      )
    );
  }

  deleteAll(): Observable<boolean> {
    const apiAction = '/DeleteAll/';
    return this.http.delete(this.basketApiUrl + apiAction).pipe(
      map(() => {
      return true;
      }),
      catchError(err => {
        this.errorHandler.handleError(err);
        return of(false);
        }
      )
    );
  }

  deleteSomeLayers(basketItems: BasketItemLayerModel[]): Observable<boolean> {
    const apiAction = '/DeleteSomeLayers/';
    return this.http.post(this.basketApiUrl + apiAction, JSON.stringify(basketItems), this.httpOptions ).pipe(
      map(() => {
      this.get();
      return true;
      }),
      catchError(err => {
        this.errorHandler.handleError(err);
        return of(false);
        }
      )
    );
  }

  deleteSome(basketItemIds: string[]): Observable<boolean> {
    const apiAction = '/DeleteSome/';
    return this.http.post(this.basketApiUrl + apiAction, JSON.stringify(basketItemIds), this.httpOptions ).pipe(
      map(() => {
      this.get();
      return true;
      }),
      catchError(err => {
        this.errorHandler.handleError(err);
        return of(false);
        }
      )
    );
  }

  private getBasket(suppressValidate?: boolean): Observable<BasketItemModel[]> {
    let validate = true;

    if(suppressValidate && suppressValidate === true) {
      validate = false;
    }

    const params = new HttpParams().set('validate', validate);

    const apiAction = '/GetBasket/';
    return this.http.get<BasketItemModel[]>(this.basketApiUrl + apiAction, { params }).pipe(
      catchError(this.errorHandler.handleError)
    );
  }

  /**
   * Get all user items from the basket API, determine validation failures and convert to PlotModels
   */
  get(): void {
    this.getBasket().pipe(
      map(basketItems => {
        const plots = this.plotHelperService.toPlotModels(basketItems);
        return plots;
    },
    catchError(() => {
      return of([]);
    }))
    ).subscribe(plots => {
      this.fetchedBasketItems.next(plots);
    });
  }

  hasItems(): Observable<boolean|null> {
    return this.getBasket(true).pipe(
      map(basketItems => {
        return basketItems && basketItems.length > 0;
      },
      catchError(() => {
        return of(null);
      }))
    );
  }
}
