import { IMapService } from '../interfaces/IMapService';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import * as L from 'leaflet';
import { CentreMarkerDirective } from '../mapping/map/centre-marker.directive';
import { CoordConverterService } from './coord-converter.service';
import { CoordinateModel } from '../models/coordinate.model';
import { MapConfiguration } from '../models/map-configuration.model';
import { environment } from 'src/environments/environment';
import { MapConfigurationService } from './map-configuration.service';

@Injectable({
    providedIn: 'root'
})

export class MapService implements IMapService {
    private configuration: MapConfiguration;
    private watermarkCss = 'os-api-branding-logo';
    private isMapVisible = new BehaviorSubject<boolean | null>(null);
    private zoomLevel = new BehaviorSubject<number | undefined>(this.getMapInitZoom());
    private initialised = new Subject<boolean>();
    private moveEnd = new BehaviorSubject<L.LatLngBounds>(null);
    private moveStart = new Subject<void>();
    private savedBounds: L.LatLngBounds;
    private savedZoom = 0;
    private defaultBounds: L.LatLngBounds;
    private tiles: L.TileLayer;
    private watermark: L.Control;
    public isMapVisible$ = this.isMapVisible.asObservable();
    public zoomLevelChanged$ = this.zoomLevel.asObservable();
    public initialised$ = this.initialised.asObservable();
    public moveEnd$ = this.moveEnd.asObservable();
    public moveStart$ = this.moveStart.asObservable();
    public map: L.Map;

    constructor(private converterService: CoordConverterService, private mapConfigService: MapConfigurationService) {
        this.mapConfigService.getConfiguration().subscribe(config => {

            this.configuration = config;

            if (this.configuration) {
                this.configuration.wmtsParams.key = environment.mapTileApiKey;
                this.defaultBounds = new L.LatLngBounds(this.configuration.defaultBounds);
                this.zoomLevel.next(this.getMapInitZoom());
            }

        });
    }

    private getMapInitZoom(): number | undefined {
        if (this.savedZoom > 0) {
            return this.savedZoom;
        } else if (this.configuration) {
            return this.configuration.defaultZoom;
        }
        return;
    }

    private determineInitBounds() {
        if (this.savedBounds) {
            if (this.savedBounds && this.savedZoom > 0) {
                this.map.setView(this.savedBounds.getCenter(), this.savedZoom);
            } else if (this.savedBounds) {
                this.map.fitBounds(this.savedBounds, { maxZoom: this.map.getZoom(),  minZoom: this.map.getZoom(), paddingTopLeft: L.point(0, 100) } as L.FitBoundsOptions);
            }
        } else {
            this.map.setView(this.defaultBounds.getCenter(), this.configuration.defaultZoom);
        }
    }

    private addCentreMarker() {
        const marker = new CentreMarkerDirective();
        marker.addTo(this.map);
    }

    /**
     * Compare a LatLng value with the current map centre at a specified precision
     * @param src source LatLng to compare against
     * @param precision of comparision (e.g. if precision is "7" then 52.3282488888888 would be 52.32825)
     */
    private latLngEqual(src: L.LatLng, precision: number): boolean {
        return this.map.getCenter().lat.toPrecision(precision) ===
        src.lat.toPrecision(precision) &&
        this.map.getCenter().lng.toPrecision(precision) ===
        src.lng.toPrecision(precision);
    }

    private configureTileLayer() {
        if (this.configuration) {
            const wmtsParams = this.configuration.wmtsParams;

            const queryString = Object.keys(wmtsParams).map((property) => {
                return property + '=' + wmtsParams[property];
            }).join('&');

            this.tiles = new L.TileLayer(
                this.configuration.apiUrl + '?' + queryString,
                { maxZoom: this.configuration.maxZoom } as L.TileLayerOptions
            ).addTo(this.map);
        }
    }

    private addWatermark() {
        const watermarkControl = L.Control.extend({
            onAdd: () => {
                var img = L.DomUtil.create('div');
                img.classList.add(this.watermarkCss);
                return img;
            },
        
            onRemove: () => {}
        });

        this.watermark = new watermarkControl({ position: 'bottomleft' }).addTo(this.map);
    }

    initMap(mapElementId: string, callback: () => void): void {

        this.map = L.map(mapElementId, { zoomControl: false, minZoom: this.configuration.minZoom } as L.MapOptions);

        // Set attribution for copyright, etc.
        this.setAttribution();

        this.addWatermark();

        this.map.on('load', () => {
            callback();
            this.initialised.next(true);
            this.isMapVisible.next(true);
            this.zoomLevel.next(this.map.getZoom());
        }).on('zoomend', () => {
            this.zoomLevel.next(this.map.getZoom());
        }).on('moveend', () => {
            this.moveEnd.next(this.map.getBounds());
        }).on('movestart', () => {
            this.moveStart.next();
        });

        this.determineInitBounds();

        L.control.zoom({ position: 'topright' }).addTo(this.map);

        this.configureTileLayer();

        this.addCentreMarker();
    }

    private setAttribution() {
        if (this.configuration) {
            if (this.configuration.attrPrefix) {
                this.map.attributionControl.setPrefix(this.configuration.attrPrefix);
            } else {
                this.map.attributionControl.setPrefix(false);
            }
            this.map.attributionControl.addAttribution(this.configuration.attrCopyright + ' ' + this.configuration.licenceNumber);
        }
    }

    getCenter(): L.LatLng {
        if (this.map) {
            return this.map.getCenter();
        }
        return this.defaultBounds.getCenter();
    }

    getZoom(): number {
        if (this.map) {
            return this.map.getZoom();
        }
        return this.getMapInitZoom();
    }

    setVisible(visible: boolean) {
        this.isMapVisible.next(visible);
    }

    fitBounds(bounds: L.LatLngBounds): void {
        this.savedBounds = bounds;

        if (!this.map) {
            // e.g. map page not initialise on basket get and set position
            return;
        }

        this.savedZoom = this.configuration.defaultZoom;

        if (!this.map.getBounds().contains(bounds)) {
            this.map.fitBounds(bounds, { maxZoom: this.map.getZoom(),  minZoom: this.map.getZoom(), paddingTopLeft: L.point(0, 100) } as L.FitBoundsOptions);
        } else if (!this.latLngEqual(bounds.getCenter(), 6)) {
            this.map.panTo(bounds.getCenter());
        }
    }

    saveView() {
        this.savedBounds = this.map.getBounds();
        this.savedZoom = this.map.getZoom();
    }

    setCoordinates(latitude: number, longitude: number) {
        this.map.panTo(new L.LatLng(latitude, longitude));
    }

    setView(latitude: number, longitude: number, zoom: number) {
        zoom = zoom > this.configuration.maxZoom ? this.configuration.maxZoom : zoom;
        this.map.setView(new L.LatLng(latitude, longitude), zoom);
    }

    // refresh the map state
    refresh() {
        this.tiles.redraw();
    }

    getCenterBng(decimals: number): CoordinateModel {
        const center = this.getCenter();
        if (center) {
            return this.converterService.toBng(center.lat, center.lng, decimals);
        }
    }

    mapDrag(enable: boolean): void {
        if (enable) {
            this.map.dragging.enable();
        } else {
            this.map.dragging.disable();
        }
    }
}

