import { Injectable } from '@angular/core';
import { IAssetLayerModel } from '../interfaces/IAssetLayerModel';
import { IPlotModel } from '../interfaces/IPlotModel';
import { IPlotValidationService } from '../interfaces/IPlotValidationService';

@Injectable({
  providedIn: 'root'
})
export class PlotValidationService implements IPlotValidationService {

  constructor() { }

  isValid(plot: IPlotModel): boolean {
    if (!plot.plotTitle || plot.plotTitle.length === 0) {
      return false;
    } else if (plot.plotTitle.length > plot.plotTitleMaxLength) {
      return false;
    }

    if (!plot.paperSize || plot.paperSize.length === 0) {
      return false;
    }

    if (plot.comments) {
      if (plot.comments.length > plot.commentsMaxLength) {
        return false;
      }
    }

    return this.validateAssetLayers(plot.assetLayers);
  }

  validateAssetLayers(assetLayers: IAssetLayerModel[]): boolean {
    if (assetLayers) {
      if (assetLayers.length > 0) {
        return assetLayers.filter(ass => ass.layers.some(l => l.selected)).length > 0;
      }
    }
    return false;
  }
}
