import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LogoutReason } from 'src/app/models/logout-reason';
import { NavigateService } from 'src/app/services/navigate.service';
import { emailValidator } from 'src/app/shared/email-validator';

@Component({
    selector: 'app-root',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less'],
    encapsulation: ViewEncapsulation.None
})

export class LoginComponent implements OnInit {
    private lastUser = '';
    loginForm: UntypedFormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    loginFailed = false;
    logoutReason: LogoutReason = null;
    navigating = false;
    showPassword = false;

    constructor(private formBuilder: UntypedFormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private authService: AuthenticationService,
                private navigateService: NavigateService) {

                this.loginForm = this.formBuilder.group({
                    userName: ['', [Validators.required, emailValidator()]],
                    password: ['', Validators.required]
                }, {updateOn: 'blur'} );

                this.setLastUser();
                this.setLogoutReason();
    }

    private setLastUser() {
        this.lastUser = this.route.snapshot.queryParamMap.get('lastUser');
    }

    /**
     * Set the logoutReason from the query string and then remove from query string
     */
    private setLogoutReason() {
        const logoutReason = this.route.snapshot.queryParamMap.get('logoutReason');
        const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');

        if (logoutReason && returnUrl) {
                // set reason and remove from query string
                this.logoutReason = logoutReason as LogoutReason;
                const params = { returnUrl } as Params;
                this.router.navigate([], { relativeTo: this.route, queryParams: params });
        }
    }

    private userEqualsLastUser(): boolean {
        if (this.lastUser && this.loginForm.controls.userName.value) {
            return this.lastUser.toLowerCase() === this.loginForm.controls.userName.value.toString().toLowerCase();
        }
        return false;
    }

    ngOnInit() {
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
        this.loginForm.markAsUntouched();
        this.loginForm.markAsPristine();
    }

    onSubmit() {
        this.submitted = true;

        if (this.loginForm.invalid || this.loading) {
            return;
        }

        this.loading = true;
        this.loginFailed = false;
        this.logoutReason = null;

        this.authService.login(this.loginForm.controls.userName.value, this.loginForm.controls.password.value)
            .subscribe(user => {
                if (user == null) {
                    this.loginFailed = true;
                    this.loading = false;
                    return;
                }

                this.navigating = true;
                if (!this.userEqualsLastUser()) {
                    this.router.navigate(['']);
                } else {
                    this.router.navigate([this.returnUrl]);
                }
            }
        );
    }
    
    forgottenPassword() {
        this.navigateService.navigateToForgottonPassword();
    }

    register() {
        this.navigateService.navigateToRegister();
    }

    features() {
        this.navigateService.navigateToFeatures();
    }

    nonstat() {
        this.navigateService.navigateToHome();
    }

    legacyLogin() {
        this.navigateService.navigateToLegacyLogin();
    }

    close() {
        this.loginFailed = false;
        this.logoutReason = null;
    }

    toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }

}
