import { INotificationModel } from '../interfaces/INotificationModel';

export class NotificationModel implements INotificationModel {
    notificationId: string;
    message: string;
    showFromDate: Date;
    expiryDate: Date;
    dismissed: boolean;
    systemNotificationId: string;
    isMaintenance: boolean;
}
