import { ElementRef } from '@angular/core';
import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appDocumentClick]'
})
/**
 * Directive which binds to the document click event (in one place to avoid performance hit) and emits
 * if the document is clicked and if the click target was outside of the current element
 */
export class DocumentClickDirective {
  @Output() documentClicked$ = new EventEmitter();
  @Output() documentClickedOutside$ = new EventEmitter();

  constructor(private el: ElementRef) {
  }

  private clickInsideTargetParent(e: Event): boolean {
    return (this.el.nativeElement as Element).parentNode.contains((e.target as Element));
  }

  @HostListener('document:click', ['$event'])
  determineClick(e: Event) {
    if (!this.clickInsideTargetParent(e)) {
      this.documentClickedOutside$.emit();

    }
    this.documentClicked$.emit();
 }

}
