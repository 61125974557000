import { StatusFilterModel } from './status-filter-model';

export class OrderSummaryFilterModel {
    before: string;
    after: string;
    status: number[];
    pageNumber: number;
    resultsPerPage: number;
    dateType: string;
    statuses: StatusFilterModel[];
    searchText: string;
}
