import { Injectable } from '@angular/core';
import documentData from '../../config/supplier-documents.json';
import { ISupplierDocument } from '../interfaces/ISupplierDocument';
import providerData from '../../config/providers.json';
import { IProviderModel } from '../interfaces/IProviderModel';
import { IProviderService } from '../interfaces/IProviderService';

@Injectable({
  providedIn: 'root'
})
export class ProviderService implements IProviderService {
  private supplierDocuments: ISupplierDocument[] = [];
  private providers: IProviderModel[] = [];

  constructor() {
    this.supplierDocuments = (documentData as any) as ISupplierDocument[];
    this.providers = (providerData as any) as IProviderModel[];
  }
  
  getDocumentId(supplierId: number): string {
    const document = this.supplierDocuments.find(d => d.supplierId === supplierId && d.documentTypeId === 0);
    if (document) {
      return document.documentId;
    }
    return null;
  }

  getProviders(): IProviderModel[] {
    return this.providers.map(p => Object.assign({}, p));
  }
}
