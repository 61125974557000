import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ISearchService } from '../interfaces/ISearchServices';
import { SearchResult } from '../models/search-result.model';
import { ServiceErrorHandler } from '../shared/service-error-handler';
import { MapConfigurationService } from './map-configuration.service';

@Injectable({
    providedIn: 'root'
})

export class SearchService implements ISearchService {
    private httpHeaders = new HttpHeaders({
        Content: 'application/json',
        'Access-Control-Allow-Origin': '*'
    });
    private minZoom = 0;
    private maxZoom = 0;
    private apiUrl: string;
    private maxResultLimit = 10;
    private latLongRegex = new RegExp(/^(\-?([0-8]?[0-9](\.\d+)?|90(.[0]+)?)\s?[,]\s?)+(\-?([1]?[0-7]?[0-9](\.\d+)?|180((.[0]+)?)))$/g);
    private coordRegex = new RegExp(/^-?\d*\.?\d+$/g);
    private searchStarting = new Subject<void>();
    private searchCompleted = new Subject<SearchResult[]>();
    private resultSelected = new Subject<SearchResult>();
    searchStarting$: Observable<void> = this.searchStarting.asObservable();
    searchCompleted$: Observable<SearchResult[]> = this.searchCompleted.asObservable();
    resultSelected$: Observable<SearchResult> = this.resultSelected.asObservable();

    constructor(private httpClient: HttpClient, private errorHandler: ServiceErrorHandler, private mapConfigService: MapConfigurationService) {
        this.apiUrl = environment.searchApiUrl;
        this.mapConfigService.getConfiguration().subscribe(config => {
            if (config.minZoom < config.maxZoom) {
                this.minZoom = config.minZoom;
                this.maxZoom = config.maxZoom;
            }
        });
    }

    private getZoomLevel(resultType: string): number {
        resultType = resultType.toLowerCase().trim().replace( ' ', '');
        switch (resultType) {
            case 'address':
            case 'eastingnorthing':
            case 'ngr':
            case 'latlong':
            case 'postcode':
            case 'road':
            case 'other':
            case 'hamlet':
            case 'uprn':
                return this.maxZoom;

            case 'village':
                return this.maxZoom -1;

            case 'town':
                return this.minZoom + 1;

            case 'city':
            default:
                return this.minZoom;
        }
    }

    private setDisplayIdentifier(result: SearchResult, searchText: string, resultsLength: number) {
        if (result.resultType.toLowerCase() === 'address' ||
            result.resultType.toLowerCase() === 'ngr' ||
            (result.resultType.toLowerCase() === 'postcode' && resultsLength === 1)) {
            result.displayIdentifier = searchText.toUpperCase(); // retain original search value to display
        } else {
            result.displayIdentifier = result.placeIdentifier;

            // sometimes no spaces between coordinates
            const coords = this.getCoordinates(result.displayIdentifier);
            if (coords.length === 2) {
                result.displayIdentifier = coords.join(', ');
            }
        }
    }

    private mapToModel(results: SearchResult[], searchText: string) {
        if (results) {
            if (results.length > 0) {
                results.forEach((result) => {
                    result.zoomLevel = this.getZoomLevel(result.resultType);
                    this.setDisplayIdentifier(result, searchText, results.length);
                });
            }
        }
    }

    private cleanResults(results: SearchResult[]) {
        if (results) {
            // null results in the array...sometimes
            // also - currently the API returns 99 results each time you do a places search
            // restrict in case this doesn't get fixed
            return results.filter(e => e != null).splice(0, this.maxResultLimit);
        }
        return [];
    }

    private setLatlongResult(latLong: string[]): void {
        try {
            const result = new SearchResult();
            result.resultType = 'LatLong';
            result.placeIdentifier = `${latLong[0]}, ${latLong[1]}`;
            result.latitude = parseFloat(latLong[0]);
            result.longitude = parseFloat(latLong[1]);
            result.zoomLevel = this.getZoomLevel(result.resultType);
            result.displayIdentifier = result.placeIdentifier;
            this.searchCompleted.next([result]);
            this.resultSelected.next(result);
        } catch {
            // doh
        }
    }

    private getCoordinates(val: string): string [] {
        // remove whitespace and commas
        let coords = val.split(/[\s,]+/);
        coords = coords.filter(e => e != null && e !== '');
        coords = coords.filter(x => {
            return x.match(this.coordRegex);
        });
        return coords;
    }

    private isLatLong(searchText: string): boolean {
        const coords = this.getCoordinates(searchText);

        if (coords.length === 2) {
            const joinedCoords = coords.join(',');
            const matched = joinedCoords.match(this.latLongRegex);
            if (matched) {
                if (matched.length === 1) {
                    this.setLatlongResult(coords);
                    return true;
                }
            }
        }
        return false;
    }

    private processSearchResults(results: SearchResult[], searchText: string) {
        results = this.cleanResults(results);
        this.mapToModel(results, searchText);
        this.searchCompleted.next(results);
    }

    search(searchText: string) {
        if (!searchText) {
            return;
        }

        if (this.isLatLong(searchText)) {
            return;
        }

        this.httpClient.get<SearchResult[]>(`${this.apiUrl}/search?searchtext=${searchText}&limit=${this.maxResultLimit}`,
            { headers: this.httpHeaders }).subscribe(
            results => {
                this.processSearchResults(results, searchText);
            },
            e => {
                this.errorHandler.handleError(e);
                this.searchCompleted.next(null);
            }
        );
    }

    selectResult(result: SearchResult) {
        this.resultSelected.next(result);
    }
}