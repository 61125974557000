import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MapLayerValidationType } from 'src/app/models/map-layer-validation.type';
import { ModalService } from 'src/app/services/modal.service';
import { NavigateService } from 'src/app/services/navigate.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-order-unavailable-modal',
  templateUrl: './order-unavailable-modal.component.html',
  styleUrls: ['./order-unavailable-modal.component.less']
})
export class OrderUnavailableModalComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  orderUnavailableModalId = 'order-unavailable-modal';

  constructor(private orderService: OrderService, private modalService: ModalService, private navigateService: NavigateService) { }

  ngOnInit(): void {
    this.subscriptions.push(this.orderService.reorderRequest$.pipe(
        // filter: only when ALL items are invalid (i.e. there is nothing to reorder with current permissions)
        filter(o => o.every(i => i.validationResult !== MapLayerValidationType.Valid)))
          .subscribe(() => {
            this.modalService.open(this.orderUnavailableModalId);
          })
    );

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
     s.unsubscribe();
    });
  }

  close() {
    this.modalService.close();
  }

  navMyAccount(e: Event) {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.navigateService.navigateToAccount();
  }

}
