import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SidebarMenuItemType } from '../models/sidebar-item-type';
import { ISidebarHandlerService } from '../interfaces/ISidebarHandlerService';
import { OrderService } from './order.service';

@Injectable({
  providedIn: 'root'
})

export class SidebarHandlerService implements ISidebarHandlerService {
  private menuItem = new Subject<SidebarMenuItemType>();
  menuItem$ = this.menuItem.asObservable();

  private close = new Subject<void>();
  close$ = this.close.asObservable();

  constructor(orderService: OrderService) {
    orderService.orderSubmitted$.subscribe(result => {
      if (result) {
        this.close.next();
      }
    });
   }

  setMenuItem(menuItem: SidebarMenuItemType) {
    this.menuItem.next(menuItem);
  }

  closeSidebar() {
    this.close.next();
  }
}
