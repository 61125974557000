import { INotificationService } from '../interfaces/INotificationService';
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NotificationModel } from '../models/notification.model';
import { BehaviorSubject, interval } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { ServiceErrorHandler } from '../shared/service-error-handler';

@Injectable({
    providedIn: 'root'
})
export class NotificationService implements INotificationService {
    private httpHeaders = new HttpHeaders({
        Content: 'application/json'
    });

    private apiUrl: string;
    private hubConnection: signalR.HubConnection;
    private hubStatusChange = new BehaviorSubject<boolean>(false);
    private notifications = new BehaviorSubject<NotificationModel[]>([]);
    notifications$ = this.notifications.asObservable();
    hubStatusChange$ = this.hubStatusChange.asObservable();

    constructor(private httpClient: HttpClient, private authService: AuthenticationService, private serviceErrorHandler: ServiceErrorHandler) {
        this.apiUrl = environment.notificationApiUrl;
        // this.startConnection();
        // this.startFakeNotifications();
    }

    private startConnection = () => {
        this.hubConnection = new signalR.HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Information) // TODO change this eventually
        .withUrl(environment.notificationHubUrl, {
            accessTokenFactory: () => {
            return this.authService.currentUserValue.accessToken;
            }
        })
        .withAutomaticReconnect([0, 1500, 2500, 60000, null]) // example of custom reconnect policy
        // merges with default policy which is [0, 2000, 10000, 30000]
        .build();

        this.hubConnection
          .start()
          .then(() => {
            // connection started
            this.updateHubConnectionState();

            this.hubConnection.onreconnecting(() => {
              this.updateHubConnectionState();
            });

            this.hubConnection.onreconnected(() => {
              this.updateHubConnectionState();
            });

            this.addNotificationListener();
          })
          .catch(err => this.serviceErrorHandler.handleError(err));
    };

    private updateHubConnectionState() {
        this.hubStatusChange.next(this.hubConnection.state === signalR.HubConnectionState.Connected);
    }

    private addNotificationListener = () => {
        this.hubConnection.on('Notification', (notification: NotificationModel) => {
            this.addNotification(notification);
        });
    };

    private addNotification(notification: NotificationModel) {
        try {
            const idx = this.notifications.value.findIndex(n => n.notificationId === notification.notificationId);
            if (idx === -1) {
                this.notifications.value.push(notification);
                this.notifications.next(this.notifications.value);
            }
        }
        catch {
            // Determine what should happen for these scenarios
            console.log(notification.notificationId + ' notification not added');
        }
    }

    // Just a bit of looky looky notifiying before the UI catches up
    private startFakeNotifications() {
        let counter = 0;
        const timer = interval(1500);

        timer.pipe(
            take(16),
            map(() => {
            return counter++;
            })).subscribe(() => {
                // re-use Doug's model as no idea what they will look like
                const notify = Object.assign(new NotificationModel(), {
                    notificationId: counter.toString(),
                    message: 'This is test notification ' + counter + '.  I only did this because I was bored.  '
                    + 'I need another job.  Can I be bothered?  Quite possibly.  I just hit my hand on the spare room door handle and now I may cry.',
                    showFromDate: Date.now(),
                    expiryDate: new Date(Date.now()).setHours(24 * 5),
                    dismissed: false,
                    systemNotificationId: counter,
                    isMaintenance: false
                });
                this.notifications.value.push(notify);
                this.notifications.next(this.notifications.value);
        });
    }

    /**
     * Remove notificationfrom notifications collection
     * @param id identifier of notification that has been dismissed
     */
    dismiss(id: string): void {
        const idx = this.notifications.value.findIndex(n => n.notificationId === id);
        if (idx !== -1) {
            this.notifications.value.splice(idx, 1);
            this.notifications.next(this.notifications.value);
        }
    }

    // Old style notifications called by the map component to make the original notification message appear
    getMaintenanceNotification() {
        return this.httpClient.get<NotificationModel>(`${this.apiUrl}/getMaintenanceNotification`,
                { headers: this.httpHeaders });
    }

    dismissNotification(notification: NotificationModel) {
        const formData = new FormData();
        formData.append('notificationId', notification.notificationId);

        this.httpClient.post<any>(`${this.apiUrl}/dismissNotification`, formData, { headers: this.httpHeaders }).subscribe();
    }
    // Old style notifications end
}
