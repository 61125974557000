import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { IdleHandlerService } from 'src/app/services/idle-handler.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-idle-modal',
  templateUrl: './idle-modal.component.html',
  styleUrls: ['./idle-modal.component.less']
})
export class IdleModalComponent implements OnInit, OnDestroy {
  private refreshSub: Subscription;
  idleModalId = 'idle-modal';

  constructor(private idleService: IdleHandlerService, private modalService: ModalService, private authService: AuthenticationService) {
   }
  
  ngOnDestroy(): void {
    if (this.refreshSub) {
      this.refreshSub.unsubscribe();
    };
  }

  ngOnInit(): void {
  }

  getTimeRemaining(): string {
    return this.idleService.getTimeRemaining();
  }

  stay() {
    this.idleService.cancelLogout();
    this.modalService.close(this.idleModalId);
    this.refreshSub = this.authService.refreshToken().subscribe();
  }

  logout() {
    this.idleService.logoutClicked();
  }

}
