import { Injectable } from '@angular/core';
import { AutoResume, DocumentInterruptSource, Idle } from '@ng-idle/core';
import { AuthenticationService } from './authentication.service';
import { IUserModel } from '../interfaces/IUserModel';
import { LogoutReason } from '../models/logout-reason';
import { ModalService } from './modal.service';
import { IIdleHandlerService } from '../interfaces/IIdleHandlerService';
import { CustomModalOptions } from '../models/custom-modal-options';
import { Subscription } from 'rxjs';

@Injectable ({
  providedIn: 'root'
})

export class IdleHandlerService implements IIdleHandlerService {
    private modalId = 'idle-modal';
    private shortIdle = false;
    private shortWarningTime = 10;
    private shortLogoutTime = 10;
    private warningTime: number = 28 * 60;
    private logoutTime: number = 2 * 60;
    private isActive: boolean;
    private logoutCountdown = 0;
    private subscriptions: Subscription[] = [];

    constructor(private idle: Idle, private modalService: ModalService, private authService: AuthenticationService) {
        this.createIdle(this.warningTime, this.logoutTime);

        authService.currentUser$.subscribe((user) => {
            this.onUserChange(user);
        });
    }

    private onUserChange(user: IUserModel) {
        if (!user) {
            this.idle.stop();
        } else {
            this.idle.watch();
        }
    }

    private displayWarning() {
        this.modalService.open(this.modalId, { hideClose: true } as CustomModalOptions);
    }

    private createIdle(warningTime: number, logoutTime: number) {
        this.unsubscribeAll();

        if(this.idle.isRunning() || this.idle.isIdling()) {
            this.idle.stop();
        }

        this.idle.setIdle(warningTime);
        this.idle.setTimeout(logoutTime);
        // idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.setInterrupts([new DocumentInterruptSource('click touch focusin')]);
        this.idle.setAutoResume(AutoResume.notIdle);

        this.subscriptions.push(this.idle.onIdleStart.subscribe(() => {
            this.isActive = true;
            this.displayWarning();
        }));

        this.subscriptions.push(this.idle.onTimeout.subscribe(() => {
            this.idleLogout();
        }));

        this.subscriptions.push(this.idle.onIdleEnd.subscribe(() => {
            // e.g. stay logged in
            this.isActive = false;
        }));

        /*
        idle.onInterrupt.subscribe(e => {
            debugger;
        });
        */

        this.subscriptions.push(this.idle.onTimeoutWarning.subscribe((countdown: number) => this.logoutCountdown = countdown));

        this.idle.watch();
    }

    private unsubscribeAll() {
         this.subscriptions.forEach(s => {
            s.unsubscribe();
         });

         this.subscriptions = [];
    }

    cancelLogout() {
        this.idle.watch();
        this.isActive = false;
    }

    idleLogout() {
        this.modalService.close(this.modalId);
        this.authService.logout(LogoutReason.Idle);
    }

    logoutClicked() {
        this.isActive = false;
        this.idle.stop();
        this.modalService.close(this.modalId);
        this.authService.logout(LogoutReason.Click);
    }

    getTimeRemaining(): string {
        if (!this.isActive) {
            return '0';
        }
        return new Date(0, 0, 0, 0, 0, this.logoutCountdown).toISOString().slice(14, 19);
    }

    toggleShortIdle() {
        this.shortIdle = !this.shortIdle;
        if (this.shortIdle) {
            this.createIdle(this.shortWarningTime, this.shortLogoutTime);
        } else{
            this.createIdle(this.warningTime, this.logoutTime);
        }
    }

}
