import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { IParentChild } from '../interfaces/IParentChild';
import { IPathSwap } from '../interfaces/IPathSwap';
import { ITitleService } from '../interfaces/ITitleService';
import { UrlHelperService } from './url-helper-service';

@Injectable({
  providedIn: 'root'
})
/** Service for defining and setting document titles dynamically */
export class TitleService implements ITitleService {
  private appName = 'digdat Utilities';
  private pathsToIgnore = ['/', 'login', 'home'];
  private pathsToIncludeParentChild: IParentChild[] = [
    { parent: 'order-detail', child: '*' },
    { parent: 'account', child: 'settings' }
  ];
  private pathsToSwapBecauseMaryIsAPain: IPathSwap[] = [
    { path: 'pending', swappedTo: 'Pending Orders' }
  ];
  private pathsToCaseSensitiveBecauseMaryIsAPain: IPathSwap[] = [
    { path: 'faqs', swappedTo: 'FAQs' }
  ];

  constructor(private urlHelperService: UrlHelperService, private title: Title) {
  }

  /**
   * Uppercase a page title value...unless it is in the case-sensitive paths to swap array.
   * You can't make this up...If it's Tuesday and I had fish for lunch then this otherwise blah.
   * @param val value of page title to convert to uppercase
   * @returns 
   */
  private capitaliseMaybe(val: string): string {
    if (val) {
      const swap = this.pathsToCaseSensitiveBecauseMaryIsAPain.find(p => p.path === val.toLowerCase().trim());
      if (swap) {
        return ' ' + swap.swappedTo;
      }

      return val.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
    }
    return val;
  }

  /**
   * Apparently we want parent > child title on some pages.
   * If parent path matches check then return parent and child segments, space delimited.
   * @param segments url segments as string array
   */
  private getSegment(segments: string[]) {
    if (segments.length > 0) {
      if (segments.length > 1) {
        const match = this.pathsToIncludeParentChild.find(p => p.parent === segments[segments.length - 2]);
        if (match) {
          if (match.child === '*' || match.child ===  segments[segments.length - 1]) {
            return segments[segments.length - 2] + ' ' + segments[segments.length - 1]; // e.g.  Order Detail 123
          }
        }
      }
      return segments[segments.length - 1];
    }
    return '';
  }

  private swapPathToTitle(path: string): string {
    if (path) {
      const swap = this.pathsToSwapBecauseMaryIsAPain.find(p => p.path === path.toLowerCase().trim());
      if (swap) {
        return ' ' + swap.swappedTo;
      }
    }

    return path;
  }

  private getTitle(path: string) {
    if (path) {
      path = path.replace(/\-/g, ' ');
      path = this.pathsToIgnore.indexOf(path.toLowerCase()) === -1 ? ' ' + path : '';
      path = this.swapPathToTitle(path);
      path = this.capitaliseMaybe(path);
    }
    return this.appName + path;
  }

  /**
   * Set html document title
   * @param url current page url
   */
  setTitle(url: string) {
    url = this.urlHelperService.removeQueryString(url);
    const segments = this.urlHelperService.getSegments(url);
    const path = this.getSegment(segments);
    const title = this.getTitle(path);
    this.title.setTitle(title);
  }
}
