import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AsyncSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAssetPermissionService } from '../interfaces/IAssetPermissionService';
import { IUserModel } from '../interfaces/IUserModel';
import { PermissionTokenModel } from '../models/permissions-token.model';
import { PolygonRestrictionModel } from '../models/polygon-restrictions.model';
import { ServiceErrorHandler } from '../shared/service-error-handler';
import { AuthenticationService } from './authentication.service';
import { MapMetaService } from './map-meta.service';

@Injectable({
  providedIn: 'root'
})

/** A service to retrieve asset permissions for the current user */
export class AssetPermissionService implements IAssetPermissionService {
  private lastUser: IUserModel | null;
  private userApiUrl: string;
  private permissions: AsyncSubject<PermissionTokenModel>;

  constructor(private http: HttpClient, private errorHandler: ServiceErrorHandler, private mapMetaService: MapMetaService, private authService: AuthenticationService) {
    this.userApiUrl = environment.userApiUrl;
    
    // only call if user has logged in or has refreshed browser
    this.authService.currentUser$.subscribe(user => {
      if (!this.lastUser && user) {
        this.lastUser = user;
        this.getPermissions();
      }
    });

  }

  /**
   * Get PermissionToken from the API
   */
   private getToken(): Observable<PermissionTokenModel> {
    const apiAction = '/GetPermissionsToken/';

    return this.http.get<PermissionTokenModel>(this.userApiUrl + apiAction).pipe(
      catchError(err => {
        this.errorHandler.handleError(err);
        return of(null);
      })
    );
  }

  /**
   * Get PermissionToken
   */
  getPermissions(): Observable<PermissionTokenModel> {
    if (!this.permissions) {
      this.permissions = new AsyncSubject<PermissionTokenModel>();

      this.getToken().pipe(
        map(token => {
          if (token) {
            if (token.allowedLayerIds) {
              token.allowedLayerIds = token.allowedLayerIds.map(l => l.toLowerCase());
            }
  
            if (!token.polygonRestrictions) {
              token.polygonRestrictions = [];
            }
  
            token.polygonRestrictions.forEach(p => {
              const provider = this.mapMetaService.getAssetLayerProvider(p.providerId);
              if (provider) {
                p.providerName = provider.name;
              }
            });
          }
          return token;
        })
      ).subscribe(result => {
        this.permissions.next(result);
        this.permissions.complete();
      });
    }
    
    return this.permissions.asObservable();
  }

  getPolygonRestrictions(): Observable<PolygonRestrictionModel[]> {
    const apiAction = '/GetUserPolygonRestrictions/';
    return this.http.get<PolygonRestrictionModel[]>(this.userApiUrl + apiAction).pipe(
      map(restrictions => {
        if (restrictions) {
          restrictions.forEach(r => {
            r.provider =  this.mapMetaService.getAssetLayerProvider(r.providerId).name;
          });
          return restrictions.sort((a, b) => a.name.localeCompare(b.name)).sort((a, b) => a.provider.localeCompare(b.provider));
        }
        return [];
      }),
      catchError(err => {
        this.errorHandler.handleError(err);
        return of([]);
      })
    );
  }

}
