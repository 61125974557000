<div class="faqs-content">
    
    <div class="container">

        <div class="custom-breadcrumb">
            <a id="faqs-breadcrumb-home" title="home" [routerLink]="['/']">Home</a>
            <span class="crumb-separator">/</span>
            <span id="faqs-breadcrumb-glossary">FAQs</span>
        </div>

        <h1 id="faq-content-title">FAQs</h1>

        <div class="page-subtitle">
        </div>

        <div class="row">
            <div class="col-md-6">
                <a class="faq-item" href="#" appExpando aria-label="show hide FAQ">
                    <span>
                        Who can use the Next Generation digdat Utilities? 
                        <i class="fa-solid fa-plus"></i>
                    </span>

                    <span>
                        The first version of the Next Generation digdat Utilities is intended for users with Statutory Access who will be able to create, order and download plots, view order history, reorder an expired order, regenerate a failed order, view account details and set user preferences. 
                    </span>
                </a>

                <a class="faq-item" href="#" appExpando aria-label="show hide FAQ">
                    <span>
                        How do I check my Statutory Access? 
                        <i class="fa-solid fa-plus"></i>
                    </span>

                    <span>
                        You can view your Statutory Access on the <a id="faq-check-access-account-details" [routerLink]="['/account/settings']" fragment="account-details">Account Details</a> page, under the Statutory Access section.  
                    </span>
                </a>

                <a class="faq-item" href="#" appExpando aria-label="show hide FAQ">
                    <span>
                        How do I apply for a Statutory Access?
                        <i class="fa-solid fa-plus"></i>
                    </span>

                    <span>
                        To apply for a Statutory Access please continue to use <a id="apply-nav-digdat-link" href="#" (click)="navDigdatHome($event)">digdat Utilities</a>.
                    </span>
                </a>

                <a class="faq-item" href="#" appExpando aria-label="show hide FAQ">
                    <span>
                        How can I manage my user details and credentials?
                        <i class="fa-solid fa-plus"></i>
                    </span>

                    <span>
                        You can view some user details on the <a id="faq-manage-details-account-details" [routerLink]="['/account/settings']" fragment="account-details">Account Details</a> page, however, to manage your user details or credentials in full please continue to use <a id="manage-nav-digdat-link" href="#" (click)="navDigdatHome($event)">digdat Utilities</a>. 
                    </span>
                </a>

                <a class="faq-item" href="#" appExpando aria-label="show hide FAQ">
                    <span>
                        When I set my user preferences, why does the next plot I create not use them?
                        <i class="fa-solid fa-plus"></i>
                    </span>

                    <span>
                        User preferences are only applied when you have no existing plots in your basket. If you have already created one or more plots, the criteria of any new plots will be based on the last plot you created.  
                    </span>
                </a>

                <a class="faq-item" href="#" appExpando aria-label="show hide FAQ">
                    <span>
                        Why is the edit icon disabled on a plot?  
                        <i class="fa-solid fa-plus"></i>
                    </span>

                    <span>
                        This happens when another plot is in the process of being created or edited. It is only possible to edit one plot at a time. Save your changes for a plot that is being edited before trying to create or edit another plot. 
                    </span>
                </a>

                <a class="faq-item" href="#" appExpando aria-label="show hide FAQ">
                    <span>
                        Why is the duplicate plot icon disabled?  
                        <i class="fa-solid fa-plus"></i>
                    </span>

                    <span>
                        It is not possible to duplicate a plot whilst it is being created. Once you have placed the plot it can then be duplicated.  
                    </span>
                </a>

                <a class="faq-item" href="#" appExpando aria-label="show hide FAQ">
                    <span>
                        Why is the print preview button and icon disabled?  
                        <i class="fa-solid fa-plus"></i>
                    </span>

                    <span>
                        Print preview, which gives quick access to individual asset plans without creating an order, is only available for paper size A4 and A3.  
                    </span>
                </a>

                <a class="faq-item" href="#" appExpando aria-label="show hide FAQ">
                    <span>
                        Why is the generate order button disabled?  
                        <i class="fa-solid fa-plus"></i>
                    </span>

                    <span>
                        This can happen due to one of the plots being unsaved or invalid (i.e not having any asset layers selected).
                    </span>
                </a>

                <a class="faq-item" href="#" appExpando aria-label="show hide FAQ">
                    <span>
                        How can I change the order of plot areas in an order?  
                        <i class="fa-solid fa-plus"></i>
                    </span>

                    <span>
                        Use the 'Drag to Rearrange Plots' icon, located in the basket side bar.
                    </span>
                </a>
            </div>

            <div class="col-md-6">

                <a class="faq-item" href="#" appExpando aria-label="show hide FAQ">
                    <span>
                        What is the + on the map?
                        <i class="fa-solid fa-plus"></i>
                    </span>

                    <span>
                        This indicates the centre of the map, and shows where a new plot will be positioned.  
                    </span>
                </a>

                <a class="faq-item" href="#" appExpando aria-label="show hide FAQ">
                    <span>
                        Can I use a keyboard to zoom in and out? 
                        <i class="fa-solid fa-plus"></i>
                    </span>

                    <span>
                        You can use Shift and the + or – keys to zoom in or out. 
                    </span>
                </a>

                <a class="faq-item" href="#" appExpando aria-label="show hide FAQ">
                    <span>
                        Why is there a £ sign on an asset layer I want to include?
                        <i class="fa-solid fa-plus"></i>
                    </span>

                    <span>
                        This means that you do not have a Statutory Access to the asset layer and you would need to purchase the asset plan. This is not currently possible on the new platform. If a payment is required for your order, please continue to use <a id="pound-nav-digdat-link" href="#" (click)="navDigdatHome($event)">digdat Utilities</a>.  
                    </span>
                </a>

                <a class="faq-item" href="#" appExpando aria-label="show hide FAQ">
                    <span>
                        How can I purchase Ordnance Survey Maps? 
                        <i class="fa-solid fa-plus"></i>
                    </span>

                    <span>
                        Purchasing Ordnance Survey Maps is not yet available in Next Generation digdat Utilities but we will be adding new functionality in the future. In the meantime, please continue to use <a id="purchase-nav-digdat-link" href="#" (click)="navDigdatHome($event)">digdat Utilities</a>.  
                    </span>
                </a>

                <a class="faq-item" href="#" appExpando aria-label="show hide FAQ">
                    <span>
                        How can I get my maps printed and posted to me? 
                        <i class="fa-solid fa-plus"></i>
                    </span>

                    <span>
                        The Print & Post service is not yet available in Next Generation digdat Utilities but we will be adding new functionality in the future. In the meantime, please continue to use <a id="print-nav-digdat-link" href="#" (click)="navDigdatHome($event)">digdat Utilities</a>.  
                    </span>
                </a>

                <a class="faq-item" href="#" appExpando aria-label="show hide FAQ">
                    <span>
                        Can I see the asset network on the map? 
                        <i class="fa-solid fa-plus"></i>
                    </span>

                    <span>
                        Viewing assets on the map is not yet available in Next Generation digdat Utilities but we will be adding new functionality in the future. If you need to view assets on the map online before generating plots, please continue to use <a id="network-nav-digdat-link" href="#" (click)="navDigdatHome($event)">digdat Utilities</a>.  
                    </span>
                </a>

                <a class="faq-item" href="#" appExpando aria-label="show hide FAQ">
                    <span>
                        Can I view my order history from digdat Utilities on the new platform? 
                        <i class="fa-solid fa-plus"></i>
                    </span>

                    <span>
                        Yes, you can view all of your orders in the <a id="faq-view-order-history" [routerLink]="['/orders/order-history']">order history</a> section on the new platform, regardless of the platform they were created in. 
                    </span>
                </a>

                <a class="faq-item" href="#" appExpando aria-label="show hide FAQ">
                    <span>
                        What are the different order statuses on the new platform? 
                        <i class="fa-solid fa-plus"></i>
                    </span>

                    <span>
                        <ul>
                            <li>
                                <span class="subtitle">Processing</span>
                                - An order will be in processing when it is first created. The time taken to process that order depends on the size of the order and the paper size of individual plots (i.e. A1 and A0 plots may take longer to process). Whilst an order is in processing, any plots which are still being created will show as 'Queued' on the Order Details page, and it is not possible to download these plots yet. Once each plot has been generated, it will be possible to select that plot to download it.
                            </li>
    
                            <li>
                                <span class="subtitle">Completed</span>
                                - All plots have been generated and are ready to download.
                            </li>
    
                            <li>
                                <span class="subtitle">Failed</span>
                                - Something went wrong during order processing. You need to 'Regenerate' the order before you can download any of the plots.
                            </li>
    
                            <li>
                                <span class="subtitle">Expired</span>
                                - Orders expire after 90 days as the data the plots are based on may have changed. In order to download the plots in an expired order, you need to process it again using 'Reorder'.
                            </li>
                        </ul>
                    </span>
                </a>

                <a class="faq-item" href="#" appExpando aria-label="show hide FAQ">
                    <span>
                        What are pending orders? 
                        <i class="fa-solid fa-plus"></i>
                    </span>

                    <span>
                        <a id="faq-pending-orders" [routerLink]="['/orders/pending']">Pending Orders</a> are any orders that have not been processed yet – either orders that are still processing or orders that failed and need to be regenerated. 
                    </span>
                </a>

            </div>
        </div>
    </div>
</div>
