import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { IScriptService } from '../interfaces/IScriptService';

@Injectable({
  providedIn: 'root'
})
export class ScriptService implements IScriptService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
   }

  /**
  * Append a Javascript resource as a child to a HTML element
  * @param scriptSource url to javascript resource
  * @param element HTML element to append script resource to (e.g. document.body)
  */
  appendScript(scriptSource: string, element: HTMLElement, async?: boolean, callback?: () => void): void {
    // (In Component) this.scriptService.addScript('./assets/test.js', document.body);
    const script = this.renderer.createElement('script') as HTMLScriptElement;
    script.type = 'text/javascript';
    if (async) {
      script.async = true;
    }

    if (callback) {
      script.onload = () => {
        callback();
      };
    }

    script.src = scriptSource;
    this.renderer.appendChild(element, script);
  }
}

