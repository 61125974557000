import { IHeaderModel } from '../interfaces/IHeaderModel';

export class HeaderModel implements IHeaderModel {
    title: string;
    userName: string;
    firstName: string;
    lastName: string;
    token: string;
    isAuthenticated = false;
    plotCount: number;
    constructor() {}
}
