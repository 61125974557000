<ng-container *ngIf="plots && initialised">

    <li class="title-item" tabindex="0">
        <span id="basket-header" class="h3">Basket</span>
    
        <ng-container *ngIf="plots.length > 0">
            <button id="basket-edit" (click)="editBasket($event)" type="button" class="btn btn-secondary" aria-label="edit basket" title="Edit Plots">
                <i aria-hidden="true" class="fa-solid fa-pencil"></i>
            </button>
        </ng-container>
        
    </li>
    
    <ng-container *ngIf="plots.length > 0">
        <li class="basket-summary" tabindex="0">
            <span id="basket-item-count">{{plots.length}} plot{{plots.length > 1? 's' : ''}}</span><span id="basket-total">{{ total | currency: 'GBP'}}</span>
        </li>
    </ng-container>
    
    <ng-container *ngIf="plots.length == 0" #empty>
        <li id="basket-no-items" class="no-items" tabindex="0">
            You have no plots in your basket.<br>
            Click <button id="basket-add-plot" (click)="addNewPlot($event)" type="button" class="btn btn-add-plot" aria-label="add plot" title="Add Plot"><i aria-hidden="true" class="fa-solid fa-square-plus"></i></button> to create a plot.
        </li>
    </ng-container>
    
    <li class="basket-command-container" tabindex="0">
        <span>
            <div id="generate-plots-basket-title-wrapper" #generatePlotsTitleElem class="looky-title" (mouseenter)="generateTitle()" >
                <button #generatePlotsElem id="basket-generate-plots" class="btn btn-primary" type="button" (click)="generatePlots($event)" [disabled]="generateDisabled">Generate Plots</button>
            </div>  
        </span>
    </li>

</ng-container>

<ng-container *ngIf="!initialised">
    <li class="title-item">
        <span id="basket-header" class="h3">Basket</span>
    </li>
    <li class="loading-message">
        <h4>Loading <app-loading-dots></app-loading-dots></h4>
    </li>
</ng-container>