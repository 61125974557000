import { AfterViewInit, Component, ContentChildren, ElementRef, Input, OnDestroy, QueryList, ViewChild } from '@angular/core';
import { CarouselItemDirective } from './carousel-item.directive';
import { animate, AnimationBuilder, AnimationFactory, AnimationPlayer, style } from '@angular/animations';
import { WindowResizeService } from 'src/app/services/window-resize.service';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.less']
})
export class CarouselComponent implements AfterViewInit, OnDestroy {
  @ViewChild('carousel') private carousel : ElementRef;
  @ViewChild('carouselInner') private carouselInner : ElementRef;
  @ContentChildren(CarouselItemDirective) items : QueryList<CarouselItemDirective>;
  @Input() slideShow: boolean;
  @Input() buttonsLeft: boolean = false;
  currentSlide = 0;
  private itemWidth: number;
  private player : AnimationPlayer;
  private animTime = 600;
  private timing = this.animTime + 'ms ease-in-out';
  private resizeSub: Subscription;
  private animationElement: ElementRef;
  private slideTime = 5000;
  private slideShowTimer: Subscription;
  private animating = false;
  carouselItemStyle = {};
  carouselInnerStyle = {};

  constructor(private builder : AnimationBuilder, windowResizeService: WindowResizeService) {
    this.resizeSub = windowResizeService.width$.subscribe(() => {
      this.setItemWidth();
      const wait = this.animating? this.animTime : 0;
      setTimeout(() => {
        this.setItemWidth();
        this.next(this.currentSlide, '0s linear');
      }, wait);
    });
   }

  ngOnDestroy(): void {
    if (this.resizeSub) {
      this.resizeSub.unsubscribe();
    }

    if (this.slideShowTimer) {
      this.slideShowTimer.unsubscribe();
    }
  }

  private next(nextSlideIndex: number, timing: string) {
    if (this.animationElement && !this.animating) {
      this.animating = true;
      const offset = this.determineOffset(nextSlideIndex);
      this.currentSlide = nextSlideIndex;
      const slideAnimation : AnimationFactory = this.buildAnimation(offset, timing);
      this.player = slideAnimation.create(this.animationElement);
      this.player.play();
      this.player.onDone(() => {
        this.animating = false;
      });
    }
  }

  private buildAnimation(offset: number, timing: string) {
    return this.builder.build([
      animate(timing, style(this.getTranslateStyle(offset)))
    ]);
  }

  private getTranslateStyle(offset: number): any {
    return  {transform: `translateX(${offset}px)`};
  }

  private determineOffset(nextSlideIndex: number) {
    if (nextSlideIndex === 0) {
      return 0;
    }
    const offset = this.itemWidth * nextSlideIndex;
    return -Math.abs(offset);
  }

  private setItemWidth() {
    if (this.carousel && this.items) {
      const width = this.carousel.nativeElement.clientWidth;
      this.itemWidth = width;
      this.carouselItemStyle = {
        width: `${this.itemWidth}px`
      };

      this.carouselInnerStyle = {
        width: `${this.itemWidth * this.items.length}px`
      };
    }
  }

  private getNextSlideIndex() {
    const nextIndex = this.currentSlide + 1;
    if (this.items && this.items.length > 0) {
      if (nextIndex > this.items.length - 1) {
        return 0;
      }
    }
    return nextIndex;
  }

  private initTimer() {
    if (this.slideShow) {
      if (this.slideShowTimer) {
        this.slideShowTimer.unsubscribe();
      }

      this.slideShowTimer = interval(this.slideTime).subscribe(() => {
        this.setItemWidth();
        this.setSlide(this.getNextSlideIndex());
      });
    }
  }

  ngAfterViewInit(): void {
    this.animationElement = this.carouselInner.nativeElement;
    setTimeout(() => {
     this.setItemWidth();
     this.initTimer();
    }, 200);
  }

  setSlide(slideIndex: number) {
    if (!this.animating) {
      if (slideIndex === this.currentSlide) {
        return;
      }
      this.initTimer();
      this.next(slideIndex, this.timing);
    }
  }

  nextSlide() {
    this.next( this.getNextSlideIndex(), this.timing);
  }
}
