import { Injectable } from '@angular/core';
import { IAssetLayerModel } from '../interfaces/IAssetLayerModel';
import { ILayerModel } from '../interfaces/ILayerModel';
import { IPlotMapperService } from '../interfaces/IPlotMapperService';
import { IPlotModel } from '../interfaces/IPlotModel';
import { AssetLayerModel } from '../models/asset-layer.model';
import { LayerModel } from '../models/layer.model';
import { PlotModel } from '../models/plot.model';

@Injectable({
    providedIn: 'root'
})

/**
 * Utility service for mapping instances of plot models together
 */
export class PlotMapperService implements IPlotMapperService {

    /**
     * Create a new instance of PlotModel with all required properties by merging two plot models
     * representing the same plot
     * @param source plot model to copy properties from
     * @param target plot model to copy properties to
     */
    private clonePlot(source: IPlotModel, target: IPlotModel): PlotModel {
        if (!source.assetLayers) {
            source.assetLayers = [];
        }

        if (!target.assetLayers) {
            target.assetLayers = [];
        }

        return Object.assign(new PlotModel(), source, target);
    }

    /**
     * Create a new array of new AssetLayer instances with all required properties
     * @param assetLayers asset layers to clone
     */
    private cloneAssetLayers(assetLayers: IAssetLayerModel[]): AssetLayerModel[] {
        return assetLayers.map(a => Object.assign(new AssetLayerModel(), ({...a})));
    }

    /**
     * Create a new array of new layer instances with all required properties
     * @param layers layers to clone
     */
    private cloneLayers(layers: ILayerModel[]): LayerModel[] {
        return layers.map(ds => Object.assign(new LayerModel(), ({...ds})));
    }

    /**
     * Merge two instances of a plot model together performing a deep clone of all (complex) properties
     * Ensure that objects returned are initialised with default values.
     * (e.g using returned object from gateway models that don't match 1:1 with front-end PlotModel leads to undefined properties)
     * @param source source plot model (e.g. the gateway returned instance or a fragment of a plot model from the map plot component)
     * @param target target plot model (e.g. the tracked in-memory instance)
     */
    mergeToPlotModel(source: IPlotModel, target: IPlotModel): IPlotModel {
        const plot = this.clonePlot(source, target);
        plot.assetLayers = this.cloneAssetLayers(plot.assetLayers);
        plot.assetLayers.forEach(ass =>
            ass.layers = ass.layers ? this.cloneLayers(ass.layers) : []
        );
        return plot;
    }
}
