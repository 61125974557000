import { Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';

@Component({
  selector: '[appVideoItem]',
  templateUrl: './video-item.component.html',
  styleUrls: ['../help-videos.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class VideoItemComponent implements OnInit {
  @Input() path = '';
  
  @ViewChild('childTemplate', {static: true}) childTemplate: TemplateRef<any>;

  constructor( private view: ViewContainerRef) {}

  ngOnInit(): void {
      this.view.createEmbeddedView(this.childTemplate);
  }

}
