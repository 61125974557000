import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import contactsData from '../../config/contact.json';
import { ContactConfig } from '../models/contact-config.model';
import { ContactModel } from '../models/contact.model';
import { ServiceErrorHandler } from '../shared/service-error-handler';
import { ProviderService } from './provider.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private config: ContactConfig;
  private apiUrl: string;
  private httpJsonHeader = new HttpHeaders({
    'Content-Type':  'application/json'
  });
  private httpOptions = {
    headers: this.httpJsonHeader
  };
  private testAddress: string;

  constructor(private http: HttpClient, private providerService: ProviderService, private errorHandler: ServiceErrorHandler) {
    this.config = contactsData as ContactConfig;
    this.apiUrl = environment.notificationApiUrl;
    this.testAddress = environment.testAddress;
  }

  private isTestAddress(contact: ContactModel): boolean {
    if (this.testAddress && environment.production !== 'true' && contact && contact.email) {
      return contact.email.toLowerCase() === this.testAddress.toLowerCase();
    }
    return false;
  }

  getProviders(): string[] {
    // Concat providers as defined in JSON config and additional contact providers (digdat, etc.)
    const assetProviders = this.providerService.getProviders().map(p => p.name).sort((a, b) =>  a.localeCompare(b));
    const contactProviders = [...this.config.providers];
    return assetProviders.concat(contactProviders);
  }

  getTitles(): string[] {
    return [...this.config.titles];
  }

  getQueries(): string[] {
    return [...this.config.queries];
  }

  send(contact: ContactModel): Observable<boolean> {
    const apiAction = '/SendContact/';
    
    if (this.isTestAddress(contact)) {
      return of(false);
    }
  
    return this.http.post(this.apiUrl + apiAction, JSON.stringify(contact), this.httpOptions ).pipe(
      map(() => {
          return true;
      }),
      catchError(err => {
        this.errorHandler.handleError(err);
        return of(false);
        }
      )
    );
  }
}
