<form appDocumentClick (documentClicked$)="documentClicked()" id="geocoder" (submit)="onSubmit()"  [formGroup]="geocoderSearchForm" [ngClass]="state">
    <div class="geocoder-input-container">
      <input id="geocoder-search-input" (focus)="setState()" (keydown)="userTyped($event)" #geocoderSearch autocomplete="off" type="text" class="form-control" placeholder="Search location" name="geocoderSearch" formControlName="geocoderSearch">
    </div>
    <button id="geocoder-clear" *ngIf="geocoderSearch.value" (click)="clearText()" type="button" class="btn btn-clear-geocode" aria-label="clear search">
        <i aria-hidden="true" class="fa-solid fa-xmark fa-lg"></i>
    </button>

    <button id="geocoder-submit" type="button" (click)="onSubmit()" class="btn btn-submit-geocode" aria-label="submit search">
        <i aria-hidden="true" class="fa-solid fa-magnifying-glass fa-lg"></i>
    </button>

    <div class="results-container" [ngClass]="showResultsContainer? 'in': 'out'">
        <div class="validation-overlay">
            <div *ngIf="resultsCount === 0 || errorMessage; then noResults else multiResults"></div>
        </div>
    </div>
</form>

<ng-template #multiResults>
    <ul id="search-results-list" *ngIf="results.length > 0">
        <li *ngFor="let result of results; let i = index;" id="search-result-item-{{i}}">
            <a id="search-result-btn-{{i}}" (click)="setSearchResult(result, $event)" href="#"><i class="fa-solid fa-location-dot"></i> {{result.displayIdentifier}}</a>
        </li>
    </ul>
    
    <ul id="previous-search-list" *ngIf="previousSearches.length > 0 && results.length === 0">
        <li id="previous-search-item-{{i}}" *ngFor="let result of previousSearches; let i = index;">
            <a id="previous-search-btn-{{i}}" (click)="setSearchResult(result, $event)" href="#"><i class="fa-solid fa-clock-rotate-left"></i> {{result.displayIdentifier}}</a>
        </li>
    </ul>
</ng-template>

<ng-template #noResults>
    <div *ngIf="errorMessage" class="search-error-container">
        <div id="search-error-header" class="validation-header">Search Failed</div>
        <span id="search-error-message">Something went wrong, please try again.</span>
    </div>

    <div *ngIf="!errorMessage" class="no-results-container">
        <div id="no-results-header" class="validation-header">No Results</div>
        <span id="no-results-message">We couldn't find any results based on your search, please amend your search and try again.</span>
    </div>
</ng-template>